

const fnRaiseError = function (title, message) {
    if (title == null || title == '') {
        title = "Error"
    }
    if (message == null || message == '') {
        message = "Oops! Something went wrong, we are looking into it. :)"
    }
    f7App.dialog.alert(message, title);

}

const fnRaiseSuccess = function (title, message) {
    if (title == null || title == '') {
        title = "Success"
    }
    if (message == null || message == '') {
        message = "Action completed successfully"
    }
    f7App.dialog.alert(message, title);

}
const fnRaiseSuccessWithCallback = function (title, message, fnSuccess) {
    if (title == null || title == '') {
        title = "Success"
    }
    if (message == null || message == '') {
        message = "Action completed successfully"
    }
    let options = {
        title: title,
        text: message,
        buttons: [{ text: 'OK', bold: true, close: true, onClick: fnSuccess }],
        destroyOnClose: true
    }
    let dialog = f7App.dialog.create(options);
    dialog.open();

}
const fnRaiseSuccessWithCallbacks = function (title, message, successButtonText, failureButtonText, fnSuccess, fnFailure) {
    if (title == null || title == '') {
        title = "Success"
    }
    if (message == null || message == '') {
        message = "Action completed successfully"
    }
    let options = {
        title: title,
        text: message,
        buttons: [{ text: successButtonText, bold: true, close: true, onClick: fnSuccess },
        { text: failureButtonText, bold: false, close: true, onClick: fnFailure }],
        destroyOnClose: true
    }
    let dialog = f7App.dialog.create(options);
    dialog.open();

}
const fnRaiseErrorWithCallback = function (title, message, fnSuccess) {
    if (title == null || title == '') {
        title = "Error"
    }
    if (message == null || message == '') {
        message = "Oops! Something went wrong, we are looking into it. :)"
    }
    let options = {
        title: title,
        text: message,
        buttons: [{ text: 'OK', bold: true, close: true, onClick: fnSuccess }],
        destroyOnClose: true
    }
    let dialog = f7App.dialog.create(options);
    dialog.open();

}

const fnRaiseConfirmDialogWithCallback = function (title, message, fnSuccess) {
    if (title == null || title == '') {
        title = "Confirm action"
    }
    if (message == null || message == '') {
        message = "Are you sure?"
    }

    let dialog = f7App.dialog.confirm(message, title, fnSuccess);
    dialog.open();
}

export {
    fnRaiseError,
    fnRaiseSuccess,
    fnRaiseSuccessWithCallback,
    fnRaiseErrorWithCallback,
    fnRaiseConfirmDialogWithCallback,
    fnRaiseSuccessWithCallbacks
}