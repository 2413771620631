const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "Chats";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const genericRepository = require('./genericRepository.js')
const fireStoreCollectionName = "chats";
const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnInsertChat = function (item, fnSuccessCallback, fnFailureCallBack, isSync = true) {
    genericRepository.fnInsertItem(item, tableName, fireStore[fireStoreCollectionName](firebase), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isSync);
}

// const fnGetPatientChats = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
//     let sql = "SELECT * FROM " + tableName + " WHERE DoctorId = ? AND PatientId = ?";
//     let params = [doctorId, patientId];
//     genericRepository.fnGetItems(tableName, sql, params, fireStore[fireStoreCollectionName](firebase, doctorId, patientId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
// }
const fnGetPatientChats = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
    return fireStore[fireStoreCollectionName](firebase, doctorId, patientId).onSnapshot(function (items) {
        let _items = [];

        items.forEach(doc => {
            if (!doc.metadata.hasPendingWrites) {
                _items.push(doc.data());

            }
        });
        if (fnSuccessCallback) {
            fnSuccessCallback(_items);
        }
    }, function (error) {
        console.log(error)
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    })
}
const fnUpdateChat = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnUpdateItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.DoctorId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnDeleteChat = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnDeleteItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.DoctorId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

export {
    fnInsertChat,
    fnGetPatientChats,
    fnUpdateChat,
    fnDeleteChat
}