<template>
  <div class="f-column f-grow">
    <f7-block-title
      class="f-row f-space-between f-vertical-center background-secondary"
    >
      <span>Shared Files</span>
      <f7-button
        @click.native="selectFile"
        raised
        small
        color="green"
        class="background-white"
      >
        <span class="f-row f-vertical-center">
          <f7-icon
            size="13"
            class="margin-right-xsmall"
            material="cloud_upload"
          ></f7-icon
          >Upload File
        </span>
      </f7-button>
    </f7-block-title>

    <form ref="form">
      <input
        id="files"
        type="file"
        name="file"
        ref="uploadInput"
        accept="image/*, application/pdf"
        :multiple="false"
        @change="detectFiles($event)"
      />
    </form>
    <div class="f-column f-grow overflow-y-auto-no-height">
      <div v-if="isLoading == true" class="f-row f-center margin-small">
        <f7-preloader></f7-preloader>
      </div>
      <f7-list
        v-if="isLoading == false && sharedFilesUrls.length > 0"
        no-hairlines
        no-hairlines-between
        class="item-media-small"
      >
        <f7-list-item v-for="(file, index) in sharedFilesUrls" :key="index">
          <!-- <div slot="media">
            <f7-icon
              size="36"
              class="text-secondary"
              material="description"
            ></f7-icon>
          </div> -->
          <div class="f-row w-100 f-vertical-center">
            <div class="margin-x-small">
              <f7-icon
                size="30"
                class="text-secondary"
                material="description"
              ></f7-icon>
            </div>
            <div class="f-spread" @click="fnOpenFile(file)">
              <div>
                <span class="text-primary text-underline"
                  >File {{ FilesCount - index }}</span
                >
              </div>
              <div class="text-secondary font-xsmall">
                Type:
                <span v-if="file.file.Type.includes('image/') == true"
                  >Image</span
                >
                <span v-if="file.file.Type.includes('pdf') == true">PDF</span>
              </div>

              <div v-if="file.file.Description" class="text-secondary">
                <span class="font-xsmall">
                  Description:&nbsp;{{ file.file.Description }}
                </span>
              </div>
              <div class="text-secondary font-xsmall">
                Uploaded By:&nbsp;
                <span v-if="file.file.UploadedById == file.file.PatientId"
                  >Patient</span
                >
                <span v-else>Doctor</span>
              </div>
              <div class="text-secondary">
                <span class="font-xsmall">
                  {{ file.file.CreatedDateMs | formatDate }},&nbsp;{{
                    file.file.CreatedDateMs | formatTime
                  }}
                </span>
              </div>
            </div>
            <div>
              <f7-button
                v-if="file.file.UploadedById == file.file.PatientId"
                @click="fnOpenDeletePopup(file, index)"
                small
                class="background-white text-light-red"
                ><f7-icon material="delete"></f7-icon
              ></f7-button>
            </div>
          </div>
        </f7-list-item>
      </f7-list>

      <f7-list
        v-if="isLoading == false && sharedFilesUrls.length == 0"
        no-hairlines
      >
        <f7-list-item>No files found</f7-list-item>
      </f7-list>
    </div>
    <!-- <div v-if="fileName && fileName != ''">
        <f7-button fill dark small color="error" @click="deleteImage()"
          >Delete</f7-button
        >
      </div> -->

    <!-- <f7-button fill color="green" @click.native="selectFile">Upload</f7-button> -->
    <f7-popup
      :opened="isImagePopupOpen"
      @popup:closed="isImagePopupOpen = false"
    >
      <f7-page>
        <f7-navbar title="">
          <f7-nav-right>
            <f7-link @click="fnCloseImageFullScreen()">Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="h-100 w-100">
          <img style="width: 100%" :src="selectedImageUrl" />
        </div>
      </f7-page>
    </f7-popup>
    <div class="dialog dialog-upload-file">
      <div class="dialog-inner">
        <div class="dialog-title">Upload File</div>
        <div class="font-small text-secondary margin-top-small">
          File Name: {{ fileToBeUploadedName }}
        </div>
        <div class="font-small text-secondary margin-top-small">
          Size: {{ fileToBeUploadedSize }} MB
        </div>
        <form id="upload-file-form">
          <f7-list no-hairlines>
            <f7-list-input
              id="fileDescriptionInput"
              class="expanded-item-content"
              label="Please enter a description *"
              type="text"
              outline
              placeholder="File Description"
              :value="fileDescription"
              @input="fileDescription = $event.target.value"
            ></f7-list-input>
          </f7-list>
        </form>
      </div>

      <div class="dialog-buttons">
        <span class="dialog-button" @click="clearFile()">Cancel</span>

        <span
          :class="fileDescription == '' ? 'disabled' : ''"
          class="dialog-button dialog-button-bold"
          @click="upload()"
          >Upload</span
        >
      </div>
    </div>
    <div class="dialog dialog-uploading-file">
      <div class="dialog-inner">
        <div class="dialog-title">Uploading...</div>
        <div class="font-small text-secondary margin-top-small">
          File Name: {{ fileToBeUploadedName }}
        </div>
        <div class="font-small text-secondary margin-top-small">
          Size: {{ fileToBeUploadedSize }} MB
        </div>
        <div id="progressBar" class="padding-small">
          <!-- <f7-progressbar
            color="orange"
            id="progressBar"
            :progress="0"
          ></f7-progressbar> -->
        </div>
        <div class="dialog-buttons">
          <span class="dialog-button" @click="fnCancelFileUpload()"
            >Cancel</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.expanded-item-content .item-content {
  padding-left: 0;
}
</style>
<script>
import EventBus from "../shared/eventBus.js";

import { mapState } from "vuex";
const sharedFilesStore = require("../store/sharedFilesStore.js");
const globalConstants = require("../shared/globalConstants.js");
const fileUtils = require("../shared/fileUtils.js");
const errorHandler = require("../shared/error-handler.js");
const dbUtils = require("../store/dbUtils.js");
const utils = require("../shared/utils.js");
import moment from "moment";
const _ = require("lodash");

export default {
  name: "shared-files",
  components: {},
  props: {},
  data: function () {
    return {
      progressUpload: 0,
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      imagePath: "",
      caller: "",
      fileName: "",
      imageUrls: Array,
      imagePathProp: "",
      sharedFiles: [],
      sharedFilesUrls: [],
      isImagePopupOpen: false,
      selectedImageUrl: "",
      selectedFile: null,
      selectedFileIndex: null,
      FilesCount: 0,
      fileToBeUploaded: null,
      uploadFileDialog: null,
      fileDescription: "",
      fileToBeUploadedName: "",
      fileToBeUploadedSize: "",
      fnUnsubscribeFileListener: null,
      progressBar: null,
      isLoading: true,
    };
  },
  created: function () {},
  mounted: function () {
    let that = this;
    this.imagePathProp = "SharedFiles";
    this.fnGetFiles();
    this.uploadFileDialog = f7App.dialog.create({
      el: ".dialog-upload-file",
    });
    this.uploadingFileDialog = f7App.dialog.create({
      el: ".dialog-uploading-file",
      on: {
        opened: function () {
          that.$nextTick(function () {
            that.progressBar = f7App.progressbar.show("#progressBar", 0);
          });
        },
      },
    });
    this.uploadFileDialog.on("opened", function () {
      that.$nextTick(function () {
        that.fnSetFocus("fileDescriptionInput");
      });
    });
    // dialog.open();
  },

  computed: {
    ...mapState(["__CurrentPatient", "currentUser"]),
  },
  watch: {
    uploadTask: function () {
      console.log(this.uploadTask);
      if (this.uploadTask.on) {
        this.uploadTask.on(
          "state_changed",
          (sp) => {
            this.progressUpload = Math.floor(
              (sp.bytesTransferred / sp.totalBytes) * 100
            );
            if (this.progressBar) {
              f7App.progressbar.set(this.progressBar, this.progressUpload);
            }
          },
          null,
          () => {}
        );
      }
    },
  },
  methods: {
    fnCancelFileUpload() {
      if (this.uploadTask) {
        this.uploadTask.cancel();
      }
      this.clearFile();

      // if (this.uploadingFileDialog) {
      //   this.uploadingFileDialog.close();
      // }
      // this.progressUpload = 0;
      // this.selectedFile = null;
      // this.selectedFileIndex = null;
    },
    fnOpenFile(file) {
      if (file.file.Type.includes("image")) {
        this.fnOpenImageFullScreen(file.url);
      }
      if (file.file.Type.includes("pdf")) {
        window.open(file.url, "_blank");
        // this.fnOpenPdf(file.file);
      }
    },
    fnOpenImageFullScreen(imageUrl) {
      this.selectedImageUrl = imageUrl;
      this.isImagePopupOpen = true;
    },
    fnCloseImageFullScreen() {
      this.isImagePopupOpen = false;
      this.selectedImageUrl = "";
    },
    fnOpenDeletePopup(file, index) {
      let that = this;
      this.selectedFile = file;
      this.selectedFileIndex = index;
      errorHandler.fnRaiseConfirmDialogWithCallback(
        "Delete File",
        "Are you sure you want to delete this file?",
        function () {
          that.fnDeleteFile();
        },
        function () {
          that.selectedFile = null;
          that.selectedFileIndex = null;
        }
      );
    },
    fnDeleteFile() {
      let that = this;
      sharedFilesStore.fnDelete(
        this.selectedFile.file,
        function () {
          that.sharedFilesUrls.splice(that.selectedFileIndex, 1);
          that.$forceUpdate();

          that.selectedFile = null;
          that.selectedFileIndex = null;

          errorHandler.fnRaiseSuccess();
        },
        function () {
          that.selectedFile = null;
          that.selectedFileIndex = null;

          errorHandler.fnRaiseError();
        }
      );
    },
    fnSetFocus(id) {
      // setTimeout(() => {
      // console.log(this.Dom7("#" + id));
      let ele = this.Dom7("#" + id);
      if (ele && ele.length > 0) {
        ele[0].focus();
        // ele[0].click();
      }
      // }, 1000);
      //this.$refs.inputhiddennote.focus();
    },
    fnResetStatusBarForiOS() {
      if (window.isCordova == true && device && device.platform == "iOS") {
        StatusBar.hide();
        StatusBar.show();
      }
    },
    // fnFileUploadDialogOpened() {
    //   this.$nextTick(function () {
    //     this.fnSetFocus("fileDescriptionInput");
    //   });
    // },
    fnOpenPdf(file) {
      let that = this;
      console.log(file.Name);
      console.log(file.Folder);
      let dialog = f7App.dialog.preloader("Opening File");

      fileUtils.fnSaveImageToFileStorageFromServer(
        file.Name,
        file.Folder,
        function (storageFolder) {
          console.log(storageFolder);
          if (window.isCordova == true) {
            cordova.plugins.fileOpener2.showOpenWithDialog(
              storageFolder + file.Name, // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
              "application/pdf",
              {
                error: function (e) {
                  dialog.close();
                  that.fnResetStatusBarForiOS();
                  errorHandler.fnRaiseError();
                  console.log(
                    "Error status: " +
                      e.status +
                      " - Error message: " +
                      e.message
                  );
                },
                success: function () {
                  that.fnResetStatusBarForiOS();
                  console.log("file opened successfully");
                },
                position: [0, 0],
              }
            );
          }
        },
        function () {
          errorHandler.fnRaiseError();
        },
        "App"
      );
    },
    selectFile() {
      this.$refs.uploadInput.click();
    },
    clearFile() {
      this.fileToBeUploaded = null;
      this.$refs.uploadInput.value = null;
      this.fileDescription = "";
      this.fileToBeUploadedName = "";
      this.progressUpload = 0;
      this.selectedFile = null;
      this.selectedFileIndex = null;
      if (this.uploadFileDialog) {
        this.uploadFileDialog.close();
      }
      if (this.uploadingFileDialog) {
        this.uploadingFileDialog.close();
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;

      Array.from(Array(fileList.length).keys()).map((x) => {
        this.fileToBeUploaded = fileList[x];
        this.fileToBeUploadedName = this.fileToBeUploaded.name;
        this.fileToBeUploadedSize = (
          this.fileToBeUploaded.size / 1048576
        ).toFixed(2);
        if (this.uploadFileDialog) {
          this.uploadFileDialog.open();
        }
        // fileList[x]
        //   .slice(0, 1) // only the first byte
        //   .arrayBuffer() // try to read
        //   .then(() => {
        //     this.upload(fileList[x]);
        //   })
        //   .catch((err) => {
        //     // error while reading
        //     console.log("failed to read");
        //     errorHandler.fnRaiseError(
        //       "File upload failed",
        //       "File cannot be read"
        //     );
        //     this.$refs.uploadInput.value = null; // remove invalid file?
        //   });
      });
    },

    fnGetFiles() {
      let that = this;
      that.isLoading = true;
      this.fnUnsubscribeFileListener = sharedFilesStore.fnGetImages(
        this.currentUser.uid,
        this.__CurrentPatient.PatientId,
        async function (files) {
          if (files && files.length > 0) {
            for (let j = 0; j < files.length; j++) {
              let item = files[j];

              let replacedArray = utils.fnReplaceItemInArray(
                that.sharedFiles,
                item,
                "FileId"
              );

              let uniqueArray = utils.fnUniqueUnion(
                replacedArray,
                [item],
                "FileId"
              );
              that.sharedFiles = Array.from(uniqueArray);
            }
            // that.sharedFiles = utils.fnSortArrayDescending(
            //   that.sharedFiles,
            //   "CreatedDateMs"
            // );
            let urlsArray = [];
            // that.sharedFilesUrls = [];
            // console.log("File Urls Before", that.sharedFilesUrls);

            for (let i = 0; i < that.sharedFiles.length; i++) {
              await that
                .fnGetDownloadUrl(that.sharedFiles[i])
                .then(function (url) {
                  // console.log("loop success");
                  urlsArray.push({
                    file: that.sharedFiles[i],
                    url: url,
                    CreatedDateMs: that.sharedFiles[i].CreatedDateMs,
                  });
                });
            }
            // that.sharedFilesUrls = [];
            // for (let i = 0; i < that.sharedFiles.length; i++) {
            //   await that
            //     .fnGetDownloadUrl(that.sharedFiles[i])
            //     .then(function (url) {
            //       // console.log(that.sharedFiles[i]);
            //       that.sharedFilesUrls.push({
            //         file: that.sharedFiles[i],
            //         url: url,
            //       });
            //     });
            // }
            that.sharedFilesUrls = _.orderBy(
              urlsArray,
              ["CreatedDateMs"],
              ["desc"]
            );
            that.FilesCount = that.sharedFilesUrls.length;
            that.isLoading = false;
          } else {
            // that.FilesCount = 0;
            that.isLoading = false;
          }
        },
        function () {
          errorHandler.fnRaiseError();
        }
      );
    },
    fnGetDownloadUrl(file) {
      return new Promise((resolve, reject) => {
        fileUtils.fnGetImageUrlServer(file.Name, file.Folder, function (url) {
          resolve(url);
        });
      });
    },
    timestamp() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "" +
        (today.getMonth() + 1) +
        "" +
        today.getDate();
      let time =
        today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      return date + "" + time;
    },
    upload(file) {
      let that = this;

      if (!file) {
        file = this.fileToBeUploaded;
      }
      this.progressUpload = 0;

      if (this.uploadingFileDialog) {
        this.uploadingFileDialog.open();
      }
      console.log(file);
      if (!(file.type.includes("image/") || file.type.includes("pdf"))) {
        that.clearFile();
        errorHandler.fnRaiseError(
          "Unsupported file type",
          "Only image and pdf file types are supported."
        );
        return;
      }
      if (this.uploadFileDialog) {
        this.uploadFileDialog.close();
      }
      // let dialog = f7App.dialog.preloader("Uploading file to secure server");

      // this.fileName = file.name;
      let folder = this.imagePathProp;
      let _fileName = this.timestamp() + "-" + file.name;
      // this.imagePath = this.path + "/" + this.timestamp() + "-" + this.fileName;
      // console.log(this.imagePath);
      this.uploading = true;
      that.uploadTask = fileUtils.fnSaveImageServer(
        file,
        null,
        folder,
        _fileName,
        function (deviceLocation, serverLocation) {
          console.log("File Save Success");
          // console.log(_fileName);
          let fileObject = {
            FileId: dbUtils.generateUID(),
            DoctorId: that.currentUser.uid,
            PatientId: that.__CurrentPatient.PatientId,
            Name: _fileName,
            Type: file.type,
            Description: that.fileDescription,
            Folder: folder,
            UploadedById: that.__CurrentPatient.PatientId,
          };
          // that.imageUrls.push(downloadURL);
          // dialog.close();
          that.uploadingFileDialog.close();

          let dialog = f7App.dialog.preloader("Creating record in database");

          // console.log("Storage Exact Path: " + logoImage.DeviceUrl);
          sharedFilesStore.fnInsert(
            fileObject,
            function () {
              that.sharedFiles.push(fileObject);
              that.fnGetDownloadUrl(fileObject).then(function (url) {
                that.sharedFilesUrls.unshift({
                  file: fileObject,
                  url: url,
                });
              });
              that.clearFile();

              dialog.close();
              errorHandler.fnRaiseSuccess("", "File successfully uploaded.");
              // that.$emit(that.caller + "Url", null);
            },
            function () {
              that.clearFile();

              dialog.close();
              errorHandler.fnRaiseError();
            }
          );
        },
        function () {
          that.clearFile();

          errorHandler.fnRaiseError();
        }
      );
      // this.uploadTask = fireStore
      //   .fireStorage(firebase)
      //   .ref(this.imagePath)
      //   .put(file);
    },
    deleteImage() {
      console.log(this.imagePathProp);
      console.log(this.fileName);
      let that = this;
      fileUtils.fnDeleteImage(
        this.fileName,
        this.imagePathProp,
        "App",
        function () {
          console.log("Image deleted successfully");
          that.uploading = false;
          that.uploadEnd = false;
          // that.imageUrls.splice(that.imageUrls.indexOf(that.imagePath), 1);
          that.$emit(that.caller + "Removed", null);
        }
      );
      // fireStore
      //   .fireStorage(firebase)
      //   .ref(this.imagePath)
      //   .delete()
      //   .then(() => {
      //     that.uploading = false;
      //     that.uploadEnd = false;
      //     that.imageUrls.splice(that.imageUrls.indexOf(that.imagePath), 1);
      //     this.$emit(this.caller + "Url", {
      //       downloadUrl: "",
      //       imagePath: ""
      //     });
      //   })
      //   .catch(error => {
      //     console.error(`file delete error occured: ${error}`);
      //   });
      this.$refs.form.reset();
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD-MM-YY");
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("HH:mm");
    },
  },
  beforeDestroy: function () {
    if (this.fnUnsubscribeFileListener) {
      this.fnUnsubscribeFileListener();
    }
  },
};
</script>
