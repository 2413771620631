<template>
  <div id="prescription-pdf" class="f-column h-100">
    <div
      class="overflow-hidden-x padding-small margin-a4-print overflow-y-auto"
    >
      <div class="">
        <div class="f-row" v-if="IsCreateHome != 'true'">
          <div class="f-column f-spread">
            <span class="font-bold font-small">{{
              PrescriptionPadDetails.ClinicName
            }}</span>
            <span class="font-xsmall text-secondary">
              {{ PrescriptionPadDetails.ClinicTagline1 }}</span
            >
            <span class="font-xsmall text-secondary">{{
              PrescriptionPadDetails.ClinicTagline2
            }}</span>

            <!-- <span class="font-xsmall text-secondary">{{
            MedicalRecord.DoctorSpeciality
          }}</span> -->
          </div>
          <div>
            <div class="f-row f-end">
              <div v-if="LogoUrl != ''" style="max-width: 100px">
                <!-- <img
                v-if="LogoUrl == ''"
                id="logo-image"
                src="static/images/placeholder-s.jpg"
                height="40"
                style="width:100px;"
              /> -->
                <img
                  crossorigin="anonymous"
                  :src="LogoUrl"
                  style="max-width: 100px"
                />
              </div>
              <!-- <div v-if="IsCreateHome != 'true' && LogoUrl == ''" class="f-row">
                <div
                  class="f-spread f-column text-align-right"
                  style="padding-top: 14px"
                >
                  <span
                    v-if="MedicalRecord.DoctorPhone"
                    class="font-xsmall text-secondary"
                    >P: {{ MedicalRecord.DoctorPhone }}</span
                  >
                  <span
                    v-if="MedicalRecord.DoctorEmail"
                    class="font-xsmall text-secondary"
                    >E: {{ MedicalRecord.DoctorEmail }}</span
                  >
                  <span
                    v-if="MedicalRecord.DoctorWebsite"
                    class="font-xsmall text-secondary"
                    >W: {{ MedicalRecord.DoctorWebsite }}</span
                  >
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <!-- <hr
        v-if="IsCreateHome != 'true'"
        class="hr-line-small margin-y-small stretch-outside-x"
      /> -->
        <div
          v-if="IsCreateHome != 'true'"
          class="hr-line-small margin-y-small stretch-outside-x"
        />
      </div>

      <div class="f-row">
        <div class="f-column f-spread">
          <span v-if="MedicalRecord.PatientName" class="font-small font-bold">
            {{ MedicalRecord.PatientName }},&nbsp;
            <span
              v-if="MedicalRecord.PatientAge && MedicalRecord.PatientAge != ''"
              >{{ MedicalRecord.PatientAge }}/</span
            >
            {{ fnGetFirstLetter(MedicalRecord.PatientGender) }}
          </span>
          <!-- <span class="font-xsmall">
            <span class="text-secondary">Patient Id:</span>
            <span class="font-bold">{{ MedicalRecord.PatientUID }}</span>
          </span> -->
          <span class="font-xsmall">
            <!-- <span class="text-secondary">Address:</span> -->
            {{ MedicalRecord.PatientAddress }}
          </span>
        </div>
        <div class="f-column f-spread text-align-right">
          <span v-if="MedicalRecord.Date" class="font-xsmall font-bold">{{
            MedicalRecord.Date | formatDateWithMonth
          }}</span>
          <span class="font-xsmall" v-if="MedicalRecord.MedicalRecordUID">
            <span class="text-secondary">Record Id:</span>
            <span class="font-bold">{{ MedicalRecord.MedicalRecordUID }}</span>
          </span>
          <span class="font-xsmall">
            <span v-if="MedicalRecord.PatientPhone" class="text-secondary"
              >P:</span
            >
            {{ MedicalRecord.PatientPhone }}
          </span>
        </div>
      </div>
      <div
        v-if="IsCreateHome == 'true'"
        class="hr-line-small margin-y-small stretch-outside-x"
      ></div>

      <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(0)">
          Physical Exam
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
      <div class="medical-record-text">
        <div
          v-if="PreviewType != 2"
          class="f-row font-xsmall margin-y-small f-space-between"
        >
          <span v-if="MedicalRecord.Height">
            <span class="text-secondary">Ht</span>
            <span class="font-bold">{{ MedicalRecord.Height }}</span>
            <span class="text-secondary">cm</span>
          </span>
          <span v-if="MedicalRecord.Weight">
            <span class="text-secondary">Weight</span>
            <span class="font-bold">{{ MedicalRecord.Weight }}</span>
            <span class="text-secondary">Kg</span>
          </span>
          <span v-if="MedicalRecord.PulseRate">
            <span class="text-secondary">PR</span>
            <span class="font-bold">{{ MedicalRecord.PulseRate }}</span>
            <span class="text-secondary">Per Min</span>
          </span>
          <span v-if="MedicalRecord.Temperature">
            <span class="text-secondary">Temp</span>
            <span>{{ MedicalRecord.Temperature }}</span>
            <span class="text-secondary">F</span>
          </span>
        </div>
        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(1)">
          Clinical Notes
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="NotesList.length > 0 && PreviewType != 2 && PreviewType != 4"
          class="f-row font-xsmall"
        >
          <div class="text-secondary col-left">Clinical Notes</div>
          <div class="f-column">
            <span v-for="(note, index) in NotesList" v-bind:key="index">{{
              note
            }}</span>
          </div>
        </div>
        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(2)">
          Diagnosis
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="
            DiagnosisList.length > 0 && PreviewType != 2 && PreviewType != 4
          "
          class="f-row font-xsmall margin-top-xxsmall"
        >
          <span class="text-secondary col-left">Diagnosis</span>
          <div class="f-column">
            <span
              v-for="(diagnosis, index) in DiagnosisList"
              v-bind:key="index"
              >{{ diagnosis }}</span
            >
          </div>
        </div>

        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(3)">
          Prescription
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="
            MedicinesList.length > 0 && PreviewType != 3 && PreviewType != 4
          "
          class="font-large margin-top-small font-bold text-secondary"
        >
          <span>
            R
            <sub style="margin-left: -4px">x</sub>
          </span>
        </div>
        <div
          v-if="
            MedicinesList.length > 0 && PreviewType != 3 && PreviewType != 4
          "
          class="font-xsmall margin-bottom-medium"
          style="margin-left: 4px"
        >
          <div
            class="row margin-top-xxsmall"
            v-for="(Medicine, index) in MedicinesList"
            v-bind:key="index"
          >
            <div class="f-row">
              <span class="text-secondary min-width-16"
                >{{ index + 1 }}.&nbsp;</span
              >
              <div class="f-spread">
                <div class="f-row">
                  <!-- <span class="text-secondary">{{ index + 1 }}.&nbsp;</span> -->
                  <span>
                    <span v-if="Medicine.Formulation">
                      {{ Medicine.Formulation }}
                    </span>
                    <span v-if="Medicine.Generic && Medicine.Generic != ''">{{
                      Medicine.Generic
                    }}</span>
                    <span v-if="Medicine.Brand && Medicine.Brand != ''">{{
                      Medicine.Brand
                    }}</span>
                  </span>
                </div>
                <div class="f-row w-100">
                  <div
                    v-if="Medicine.IsDosageText == false"
                    class="w-100 font-xsmall"
                  >
                    <div>
                      <span v-if="Medicine.Route && Medicine.Route != ''">
                        <span>{{ Medicine.Route }}</span>
                      </span>
                      <span v-if="Medicine.Frequency">
                        {{ Medicine.Frequency }}
                      </span>
                      <span v-if="Medicine.Duration && Medicine.Duration != ''">
                        <span>for&nbsp;{{ Medicine.Duration }}&nbsp;</span
                        ><span>{{
                          _fnGetDurationUnitText(Medicine.DurationUnit)
                        }}</span>
                      </span>
                    </div>
                    <div class="f-row w-100 f-wrap border-left-light">
                      <div
                        class="border-right-light border-top-light border-bottom-light"
                        v-for="(dose, index1) in Medicine.Dosages"
                        :key="index1"
                      >
                        <div
                          class="f-row padding-xsmall f-start f-vertical-center"
                        >
                          {{ dose.SelectedTime }}
                        </div>
                        <div
                          v-if="dose.Dose"
                          class="border-top-light padding-xsmall f-row f-start f-vertical-center"
                        >
                          {{ dose.Dose }}&nbsp;{{ Medicine.DoseUnit }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="w-100 font-xsmall">
                    <div>
                      <span v-if="Medicine.Route && Medicine.Route != ''">
                        <span>{{ Medicine.Route }}</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        {{ Medicine.Dosage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="f-row font-xsmall">
                  <span v-if="Medicine.Remarks && Medicine.Remarks != ''">
                    <!-- <span class="text-secondary">Instructions:&nbsp;</span> -->
                    <span>{{ Medicine.Remarks }}</span>
                  </span>
                </div>
                <div class="f-row font-xsmall">
                  <div>
                    <span v-if="Medicine.Quantity && Medicine.Quantity != ''">
                      <span class="text-secondary">Dispense:&nbsp;</span>
                      <span>{{ Medicine.Quantity }}</span>
                      <span v-if="Medicine.DispenseUnit"
                        >&nbsp;{{ Medicine.DispenseUnit }}</span
                      >
                    </span>
                    <span
                      class="margin-left-small"
                      v-if="Medicine.Refill && Medicine.Refill != ''"
                    >
                      <span class="text-secondary">Refill:&nbsp;</span>
                      <span>{{ Medicine.Refill }}</span>
                    </span>
                    <!-- <span class="text-secondary margin-left-small" v-else>Refill: NA</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(4)">
          Investigations
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="InvestigationsList.length > 0 && PreviewType != 2"
          class="f-row font-xsmall margin-top-xxsmall"
        >
          <div class="text-secondary col-left">Investigation</div>
          <div class="f-column">
            <span
              v-for="(investigation, index) in InvestigationsList"
              v-bind:key="index"
              >{{ investigation }}</span
            >
          </div>
        </div>
        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(5)">
          Advices
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="AdvicesList.length > 0 && PreviewType != 2 && PreviewType != 4"
          class="f-row font-xsmall margin-top-xxsmall"
        >
          <div class="text-secondary col-left">Advice</div>
          <div class="f-column">
            <span v-for="(advice, index) in AdvicesList" v-bind:key="index">{{
              advice
            }}</span>
          </div>
        </div>
        <!-- <div class="margin-top-small" v-if="IsCreateHome == 'true'">
        <f7-button class="w-fit-content" small fill round @click="fnEmitCreateMenuClick(6)">
          Follow-up
          <f7-icon material="create"></f7-icon>
        </f7-button>
      </div>-->
        <div
          v-if="
            !(
              MedicalRecord.FollowUpDate == null ||
              MedicalRecord.FollowUpDate == ''
            ) &&
            PreviewType != 2 &&
            PreviewType != 4
          "
          class="f-row font-xsmall margin-top-xxsmall"
        >
          <div class="text-secondary col-left">Follow-up</div>
          <div class="f-column">
            <span>{{ MedicalRecord.FollowUpDate | formatDateWithDay }}</span>
          </div>
        </div>
        <!-- <div> -->
        <!-- <div data-html2canvas-ignore="true">
          <div class="light-color font-small">Hidden Notes</div>
          <div v-if="HiddenNotesList.length == 0" class="font-small light-color">
            <span>-- NA</span>
          </div>
          <ul v-else class="font-small ul-compact list-style-circle">
            <li v-for="(hiddennote,index) in HiddenNotesList" v-bind:key="index">{{hiddennote}}</li>
          </ul>
      </div>-->
        <!-- <div class="light-color font-small">Notes</div>
        <div v-if="NotesList.length == 0" class="font-small light-color">
          <span>-- NA</span>
        </div>
        <ul v-else class="font-small ul-compact list-style-circle">
          <li v-for="(note,index) in NotesList" v-bind:key="index">{{note}}</li>
      </ul>-->

        <!-- <div class="light-color font-small">Medicines</div>
        <div v-if="MedicinesList.length == 0" class="font-small light-color">
          <span>-- NA</span>
      </div>-->

        <!-- <div class="light-color font-small">Advices</div>
        <div v-if="AdvicesList.length == 0" class="font-small light-color">
          <span>-- NA</span>
        </div>
        <ul v-else class="font-small ul-compact list-style-circle">
          <li v-for="(advice, index) in AdvicesList" v-bind:key="index">{{advice}}</li>
      </ul>-->

        <!-- <div class="light-color font-small">Follow Up:</div>
        <div
          v-if="MedicalRecord.FollowUpDate == null || MedicalRecord.FollowUpDate == ''"
          class="font-small light-color"
        >
          <span>-- NA</span>
        </div>
      <div v-else class="font-small">{{MedicalRecord.FollowUpDate | formatDateWithDay}}</div>-->
        <!-- </div> -->
        <!-- <hr v-if="IsCreatePreview == false" class="hr-line-small stretch-outside-x" /> -->

        <div class="margin-top-small margin-bottom-large doctor-signature">
          <div class="row f-end" v-if="IsCreatePreview == false">
            <div class="f-column f-column-center">
              <img
                crossorigin="anonymous"
                v-if="SignatureUrl != ''"
                :src="SignatureUrl"
                width="100"
                height="40"
              />
              <span class="text-secondary font-xsmall" v-else>Not signed</span>
              <!-- <span class="text-secondary font-xsmall text-align-center"
              >Doctor's Signature</span
            > -->
              <div
                style="
                  border-top: 1px rgba(0, 0, 0, 0.4);
                  padding-top: 4px;
                  border-top-style: dashed;
                "
                class="f-column f-spread"
              >
                <span class="font-bold text-secondary font-xsmall"
                  >Dr.&nbsp;{{ SignatureDetails.DoctorName }}</span
                >
                <span class="font-xsmall text-secondary">
                  {{ SignatureDetails.RegistrationNumber }}</span
                >
                <span class="font-xsmall text-secondary">{{
                  SignatureDetails.Degree
                }}</span>

                <span class="font-xsmall text-secondary">{{
                  SignatureDetails.Speciality
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="f-spread"></div>
    <div v-if="IsCreateHome != 'true'" class="margin-a4-print">
      <div class="hr-line-small margin-y-small stretch-outside-x"></div>
      <div class="f-row">
        <!-- <div class="f-column f-spread">
        <span class="font-xsmall text-secondary">{{
          DoctorProfile.Address
        }}</span>
      </div> -->
        <div class="f-spread f-column padding-x-small padding-bottom-small">
          <span
            v-if="PrescriptionPadDetails.ClinicPhone"
            class="font-xsmall text-secondary"
            >P: {{ PrescriptionPadDetails.ClinicPhone }}</span
          >
          <span
            v-if="PrescriptionPadDetails.ClinicEmail"
            class="font-xsmall text-secondary"
            >E: {{ PrescriptionPadDetails.ClinicEmail }}</span
          >
          <span
            v-if="PrescriptionPadDetails.ClinicWebsite"
            class="font-xsmall text-secondary"
            >W: {{ PrescriptionPadDetails.ClinicWebsite }}</span
          >
          <span
            v-if="PrescriptionPadDetails.Address"
            class="font-xsmall text-secondary"
            >{{ PrescriptionPadDetails.Address }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.col-left {
  min-width: 90px;
  max-width: 90px;
}
.border-left-light {
  border-left: 1px solid rgba(0, 0, 0, 0.04);
}
.border-right-light {
  border-right: 1px solid rgba(0, 0, 0, 0.04);
}
.border-bottom-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.border-top-light {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}

</style>
<script>
import { mapState } from "vuex";

const signatureImagesStore = require("../store/signatureImagesStore.js");
const fileUtils = require("../shared/fileUtils.js");
const doctorLogoStore = require("../store/doctorLogoStore.js");

import moment from "moment";

export default {
  name: "medical-record-preview",
  components: {},
  data: function () {
    return {
      DoctorProfile: {},
      HiddenNotesList: ([] = []),
      NotesList: ([] = []),
      MedicinesList: ([] = []),
      AdvicesList: ([] = []),
      DiagnosisList: ([] = []),
      InvestigationsList: ([] = []),
      // IsCreatePreview: false,
      SignatureUrl: "",
      LogoUrl: "",
      SignatureDetails: {},
      PrescriptionPadDetails: {},
      frequencyUnits: [
        { value: 4, text: "per minute", durationText: "minute(s)" },
        { value: 5, text: "per hour", durationText: "hour(s)" },
        { value: 1, text: "per day", durationText: "day(s)" },
        { value: 2, text: "per week", durationText: "week(s)" },
        { value: 3, text: "per month", durationText: "month(s)" },
      ],
    };
  },
  props: {
    MedicalRecord: null,
    IsCreatePreview: null,
    PreviewType: null,
    IsCreateHome: null,
  },
  computed: {
    ...mapState(["__CurrentPrescription", "userProfile"]),
  },
  mounted: function () {
    // console.log(this.IsCreatePreview);
    // console.log(this.userProfile);
    let that = this;
    this.DoctorProfile = this.userProfile;
    console.log(this.MedicalRecord);
    // if (this.MedicalRecord.Medicines) {
    //   this.MedicinesList = this.MedicalRecord.Medicines.split(";");
    // }
    if (this.MedicalRecord.Medicines) {
      console.log(JSON.parse(this.MedicalRecord.Medicines));
      this.MedicinesList = JSON.parse(this.MedicalRecord.Medicines);
    }
    if (this.MedicalRecord.Advices) {
      this.AdvicesList = this.MedicalRecord.Advices.split(";");
    }
    if (this.MedicalRecord.Investigations) {
      this.InvestigationsList = this.MedicalRecord.Investigations.split(";");
    }
    if (this.MedicalRecord.HiddenNotes) {
      this.HiddenNotesList = this.MedicalRecord.HiddenNotes.split(";");
    }
    if (this.MedicalRecord.VisibleNotes) {
      this.NotesList = this.MedicalRecord.VisibleNotes.split(";");
    }
    if (this.MedicalRecord.Diagnosis) {
      this.DiagnosisList = this.MedicalRecord.Diagnosis.split(";");
    }
    if (this.MedicalRecord.PrescriptionPadDetails) {
      this.PrescriptionPadDetails = JSON.parse(
        this.MedicalRecord.PrescriptionPadDetails
      );
    } else {
      this.PrescriptionPadDetails = {
        ClinicName: this.userProfile.ClinicName,
        ClinicTagline1: this.userProfile.ClinicTagline1,
        ClinicTagline2: this.userProfile.ClinicTagline2,
        Address: this.userProfile.Address,
        ClinicPhone: this.userProfile.ClinicPhone,
        ClinicEmail: this.userProfile.ClinicEmail,
        ClinicWebsite: this.userProfile.ClinicWebsite,
      };
    }
    if (this.MedicalRecord.SignatureDetails) {
      this.SignatureDetails = JSON.parse(this.MedicalRecord.SignatureDetails);
    } else {
      this.SignatureDetails = {
        DoctorName: this.userProfile.Name,
        Degree: this.userProfile.Degree,
        Speciality: this.userProfile.Speciality,
        RegistrationNumber: this.userProfile.RegistrationNumber,
      };
    }
    if (this.IsCreateHome != "true") {
      this.fnGetSignatureImageUrl(function (imageUrl) {
        console.log(imageUrl);
        that.SignatureUrl = imageUrl;
      });
    }
    this.fnGetLogoImageUrl(function (imageUrl) {
      console.log("Applying logo url");
      console.log(imageUrl);
      that.LogoUrl = imageUrl;
    });
  },

  methods: {
    _fnGetDurationUnitText(frequencyUnit) {
      if (frequencyUnit) {
        console.log(frequencyUnit);
        frequencyUnit = Number(frequencyUnit);
        return this.frequencyUnits.find((f) => f.value == frequencyUnit)
          .durationText;
      }
      return "";
    },
    fnEmitCreateMenuClick(menuItem) {
      this.$emit("CreateMenuClicked", menuItem);
    },
    fnGetSignatureImageUrl(fnCallBack) {
      let imageUrl = "";
      let fileName = "";
      console.log(this.MedicalRecord.MedicalRecordId);
      signatureImagesStore.fnGetImage(
        this.MedicalRecord.MedicalRecordId,
        function (items) {
          console.log("Fetched Items " + items.length);
          // console.log("Items " + items.length);
          if (items && items.length > 0) {
            console.log(items[0]);

            fileName = items[0].Name;
            if (
              window.isCordova == true &&
              items[0].StorageLocationDevice != "" &&
              items[0].StorageLocationDevice != null
            ) {
              fileUtils.fnGetImageUrl(
                fileName,
                "DoctorSignatureImages",
                "App",
                fnCallBack
              );
              // fnCallBack(items[0].StorageLocationDevice + fileName);
            } else {
              fileUtils.fnGetImageUrl(
                fileName,
                "DoctorSignatureImages",
                "",
                fnCallBack
              );
            }
          }
        }
      );
    },
    fnGetLogoImageUrl(fnCallBack) {
      let imageUrl = "";
      let fileName = "";

      doctorLogoStore.fnGetImage(
        this.DoctorProfile.ConsultantId,
        function (items) {
          console.log("Fetched Items " + items.length);
          // console.log("Items " + items.length);
          if (items && items.length > 0) {
            console.log(items[0]);

            fileName = items[0].FileName;
            console.log(fileName);
            if (window.isCordova == true) {
              if (items[0].DeviceUrl != "" && items[0].DeviceUrl != null) {
                fileUtils.fnGetImageUrl(
                  fileName,
                  "DoctorLogos",
                  "App",
                  fnCallBack
                );
                // fnCallBack(items[0].StorageLocationDevice + fileName);
              }
            } else {
              fileUtils.fnGetImageUrl(
                fileName,
                "DoctorLogos",
                null,
                fnCallBack
              );
              // fnCallBack(items[0].ServerLocation);
            }
          }
        }
      );
    },
    fnGetFirstLetter(text) {
      if (text && text.length > 0) {
        return text[0];
      }
      return "";
    },
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateWithDay(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("ddd, DD MMM YYYY");
    },
    formatDateWithMonth(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD MMM YYYY");
    },
  },
};
</script>
