import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");

const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "FCMessages";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const syncService = require('../services/syncService.js')

const fnInsert = function (item, fnSuccessCallback, fnFailureCallBack) {
    dbUtils.fnSetGlobalFields(item);
    // console.log(item);
    fireStore
        .fcMessages(firebase)
        .doc(item.FCMessageId)
        .set(item)
        .then(() => {
            if (fnSuccessCallback) {
                fnSuccessCallback();
            }
        })
        .catch(err => {
            console.log("shared files collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });


}
const fnUpdate = function (item, fnSuccessCallback, fnFailureCallBack) {
    dbUtils.fnSetGlobalFields(item);
    let updatableProps = props.filter(t => t.isUpdatable == true);

    // console.log(item);
    fireStore
        .fcMessages(firebase)
        .doc(item.FCMessageId)
        .update(dbUtils.fnGetUpdatableObject(updatableProps, item))
        .then(() => {
            if (fnSuccessCallback) {
                fnSuccessCallback();
            }
        })
        .catch(err => {
            console.log("shared files collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });


}
const fnGet = function (patientId, fnSuccessCallback, fnFailureCallBack) {

    fireStore
        .fcMessages(firebase, patientId)
        .get()
        .then((items) => {
            let _items = [];
            items.forEach(doc => {
                // console.log(doc.data())
                if (doc) {
                    _items.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
                }
            });
            if (fnSuccessCallback) {
                fnSuccessCallback(_items);
            }
        })
        .catch(err => {
            console.log("shared files collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });


}


export {
    fnInsert,
    fnGet,
    fnUpdate
}