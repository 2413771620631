import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");
const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "Doctors";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const dbLocal = require('./dbLocal.js');
const utils = require('../shared/utils.js');

const fnInsertDoctor = function (doctor, fnSuccessCallback, fnFailureCallBack) {

    doctor.DoctorUID = dbUtils.generateUID();
    doctor.CreatedDate = (new Date()).toUTCString();
    dbUtils.fnTrimTextOnObject(doctor, props);
    fireStore
        .doctors(firebase)
        .doc(doctor.ConsultantId)
        .set({
            Name: doctor.Name,
            Speciality: doctor.Speciality,
            Degree: doctor.Degree,
            RegistrationNumber: doctor.RegistrationNumber,
            Address: doctor.Address,
            Phone: doctor.Phone,
            EmailId: doctor.EmailId,
            DoctorUID: doctor.DoctorUID,
            CreatedDate: doctor.CreatedDate
        })
        .then(() => {

            if (window.isCordova == true) {

                /*Insert if not present*/

                // console.log(sql);
                window.localDB.transaction(function (tx) {
                    tx.executeSql("SELECT * FROM " + tableName + " WHERE ConsultantId = ?", [doctor.ConsultantId], function (tx, resultSet) {
                        if (resultSet.rows.length > 0) {
                            console.log("Doctor already present");
                            if (fnSuccessCallback) {
                                let items = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                                if (items.length > 0) {
                                    fnSuccessCallback(items[0]);
                                }
                            }
                        } else {
                            let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
                                + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(doctor, props) + ')';
                            console.log(sql);
                            tx.executeSql(sql, [], function (tx, resultSet) {
                                if (fnSuccessCallback) {
                                    fnSuccessCallback(resultSet);
                                }
                            });


                        }

                    });

                }, function (error) {
                    console.log('Transaction ERROR: ' + error.message);
                    if (fnFailureCallBack) {
                        fnFailureCallBack(error);
                    }
                }, function () {

                });
            } else {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            }
        })
        .catch(err => {
            console.log("user collection error");
            console.log(err);
        });


}
const fnUpdateDoctor = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, item)
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .doctors(firebase)
            .doc(item[primaryKeyName])
            .update(dbUtils.fnGetUpdatableObject(updatableProps, item))
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("doctor collection error");
                console.log(err);
            });
    }

}

const fnGetDoctor = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    console.log("DoctorId = " + doctorId)
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE ConsultantId = ? ", [doctorId], function (tx, resultSet) {
                if (resultSet.rows.length > 0) {
                    console.log("Doctor found in local db");
                    if (fnSuccessCallback) {
                        let items = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                        if (items.length > 0) {
                            fnSuccessCallback(items[0]);
                        }
                    }
                } else {
                    console.log("Doctor not found in local db, fetching from server");

                    fireStore
                        .doctors(firebase)
                        .doc(doctorId)
                        .get()
                        .then(doc => {
                            if (doc) {

                                console.log("Doctor Found");
                                console.log(doc.data());
                                let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);
                                console.log("Storing doctor data in local db");
                                console.log(result);
                                fnInsertDoctor(result);
                                if (fnSuccessCallback) {
                                    fnSuccessCallback(result);
                                }
                            } else {
                                console.log("Doctor Not Found on server as well");

                            }

                        })
                        .catch(function (error) {
                            console.log("Error getting documents: ", error);
                        });
                }

            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .doctors(firebase)
            .doc(doctorId)
            .get()
            .then(doc => {
                // console.log("Doctor Found");
                // console.log(doc.data());
                let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);
                // console.log(result);
                if (fnSuccessCallback) {
                    fnSuccessCallback(result);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}

const fnGetDoctorsForPatient = function (phone, fnSuccessCallback, fnFailureCallBack) {
    console.log("Phone", phone);
    phone = dbUtils.fnHash(phone);
    fireStore
        .patients(firebase, null, null, phone)
        .get()
        .then(function (items) {
            console.log("Patients", items);
            // let doctorId = "";
            let doctorIds = [];
            let doctors = [];
            let doc = null;
            items.forEach(item => {
                doctorIds.push(item.data().DoctorId);
            });
            doctorIds = utils.fnRemoveDuplicates(doctorIds);
            // await Promise.all(items.map(async (item) => {
            //     doctorId = item.data().DoctorId;
            //     doc = await fireStore
            //         .doctors(firebase)
            //         .doc(doctorId)
            //         .get();
            //     doctors.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
            // }));
            // for (const doctorId of doctorIds) {
            //     doc = await fireStore
            //         .doctors(firebase)
            //         .doc(doctorId)
            //         .get();
            //     doctors.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
            //   }
            doctorIds.forEach(async function (doctorId) {

                doc = await fireStore
                    .doctors(firebase)
                    .doc(doctorId)
                    .get();
                doctors.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
            });
            console.log("Doctors", doctors);
            fnSuccessCallback(doctors);

        }, function () {
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });
}
const fnGetDoctorByUniqueName = function (uniqueName, fnSuccessCallback, fnFailureCallBack) {
    console.log("UniqueName = " + uniqueName)

    fireStore
        .doctors(firebase)
        .where("UniqueName", "==", uniqueName)
        .get()
        .then(items => {
            console.log("Firebase Success")
            // console.log(items.docs);
            // console.log(items.docs[0].data());
            // console.log(items.docs[1].data());
            if (items.docs.length == 1) {
                let doc = items.docs[0];
                // console.log("Doctor Found");
                // console.log(doc.data());
                let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);
                // console.log(result);
                if (fnSuccessCallback) {
                    fnSuccessCallback(result);
                }
            } else {
                if (fnSuccessCallback) {
                    fnSuccessCallback(null);
                }
            }

        }, function (error) {
            console.log("Error getting documents: ", error);

            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });


}
const fnCheckDoctorDeviceLogin = function (fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName, [], function (tx, resultSet) {
                if (resultSet.rows.length > 0) {
                    console.log("Doctor found in local db");
                    if (fnSuccessCallback) {
                        let items = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                        if (items.length > 0) {
                            fnSuccessCallback(items[0]);
                        }
                    }
                } else {
                    console.log("Doctor login not found");
                    if (fnSuccessCallback) {

                        fnSuccessCallback(null);

                    }
                }

            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });


    }
}
const fnRemoveDoctorDeviceLogin = function (fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("DELETE FROM " + tableName, [], function (tx, resultSet) {

                console.log("Doctor login removed from device");
                if (fnSuccessCallback) {

                    fnSuccessCallback();

                }


            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });


    } else {
        if (fnSuccessCallback) {
            fnSuccessCallback();
        }
    }
}
const fnUpdateDoctorLogo = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET LogoUrlDevice = ?, LogoUrlServer = ?'
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [item.LogoUrlDevice, item.LogoUrlServer], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .doctors(firebase)
            .doc(item[primaryKeyName])
            .update({
                LogoUrlDevice: item.LogoUrlDevice,
                LogoUrlServer: item.LogoUrlServer
            })
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("doctor collection error");
                console.log(err);
            });
    }

}

export {
    fnInsertDoctor,
    fnGetDoctor,
    fnUpdateDoctor,
    fnCheckDoctorDeviceLogin,
    fnRemoveDoctorDeviceLogin,
    fnUpdateDoctorLogo,
    fnGetDoctorByUniqueName,
    fnGetDoctorsForPatient
}