const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "DoctorCallLogs";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const genericRepository = require('./genericRepository.js')
const fireStoreCollectionName = "doctorCallLogs";
const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnInsertCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnInsertItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.DoctorId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnGetCallLogs = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    let sql = "SELECT * FROM " + tableName + " WHERE DoctorId = ? ";
    let params = [doctorId];
    genericRepository.fnGetItems(tableName, sql, params, fireStore[fireStoreCollectionName](firebase, doctorId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnUpdateCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    return new Promise((resolve, reject) => {
        genericRepository.fnUpdateItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.DoctorId), fireStoreCollectionName, function (data) {
            if (fnSuccessCallback) {
                fnSuccessCallback(data);
            }
            resolve();
        }, function(){
            if(fnFailureCallBack){
                fnFailureCallBack();
            }
            reject();
        });

    })
}

const fnDeleteCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnDeleteItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.DoctorId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);

}

export {
    fnInsertCallLog,
    fnGetCallLogs,
    fnUpdateCallLog,
    fnDeleteCallLog
}