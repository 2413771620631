import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");

const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "SharedFiles";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const syncService = require('../services/syncService.js')

const fnInsert = function (item, fnSuccessCallback, fnFailureCallBack) {
    dbUtils.fnSetGlobalFields(item);
    // console.log(item);
    fireStore
        .sharedFiles(firebase)
        .doc(item.FileId)
        .set(item)
        .then(() => {
            if (fnSuccessCallback) {
                fnSuccessCallback();
            }
        })
        .catch(err => {
            console.log("shared files collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });


}

const fnGetImages = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {

    return fireStore
        .sharedFiles(firebase, doctorId, patientId)
        .onSnapshot((items) => {
            let files = [];
            items.forEach(doc => {
                if (!doc.metadata.hasPendingWrites) {

                    // console.log(doc.data())

                    files.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));

                }
            });
            if (fnSuccessCallback) {
                fnSuccessCallback(files);
            }

        }, function (err) {
            console.log("shared files collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        })



}
const fnDelete = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        let networkState = navigator.connection.type;
        if (networkState === Connection.NONE) {
            errorHandler.fnRaiseError("No Internet", "Item cannot be deleted as your device is not connected to the internet. Please try again when connection is live.")
            return;
        }
    }

    fireStore
        .sharedFiles(firebase)
        .doc(item.FileId)
        .delete()
        .then(() => {
            fireStore
                .fireStorage(firebase)
                .ref("SharedFiles/" + item.Name)
                .delete()
                .then(() => {
                    if (fnSuccessCallback) {
                        fnSuccessCallback();
                    }
                })
                .catch((error) => {
                    if (fnFailureCallBack) {
                        fnFailureCallBack();
                    }
                    console.error(`file delete error occured: ${error}`);
                });


        })
        .catch(err => {
            console.log("collection error");
            console.log(err);
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
        });

}
const fnCreateObject = function () {
    return dbUtils.fnCreateObject(props);
}
export {
    fnInsert,
    fnCreateObject,
    fnGetImages,
    fnDelete
}