<template>
  <div
    class="safe-area-ios"
    v-bind:class="[isVideoExpanded == true ? 'h-100vh f-column' : '']"
  >
    <div class="allow-click" id="remoteAudio"></div>
    <div class="allow-click" id="remoteAudioOutput"></div>
    <div
      v-show="isCallMinimised == false"
      class="f-column f-grow background-tertiary allow-click"
    >
      <div
        id="tracks-container"
        v-show="isVideoExpanded == true"
        class="f-grow f-column"
      >
        <div
          v-bind:class="[
            isVideoExpanded == true && isLocalVideo == true
              ? 'local-video'
              : 'collapsed',
          ]"
          id="localVideo"
        ></div>
        <div
          v-bind:class="[
            isVideoExpanded == true
              ? 'remote-video f-column f-grow'
              : 'collapsed',
          ]"
          id="remoteVideo"
        ></div>
      </div>
      <div class="calling-status w-100">
        <div class="text-white">
          <div class="f-row f-center font-large font-bold">
            {{ callerName }}
          </div>
          <div class="f-row f-center">
            <span v-if="callStatus == 1" class="flashing">Calling...</span>
            <span v-if="callStatus == 2" class="timer">{{ timerTime }}</span>
          </div>
        </div>
      </div>
      <div class="video-call-buttons w-100 background-transparent">
        <!-- <div>
            <div v-if="callStatus == 2" class="f-row margin-top-small">
              
              <span v-if="isRemoteAudio == true" class="text-primary">
                <f7-icon size="16" material="mic"></f7-icon
              ></span>
              <span v-else class="text-secondary">
                <f7-icon size="16" material="mic_off"></f7-icon
              ></span>
              <span v-if="isRemoteVideo == true" class="text-primary"
                ><f7-icon size="16" material="videocam"></f7-icon
              ></span>
              <span v-else class="text-secondary"
                ><f7-icon size="16" material="videocam_off"></f7-icon
              ></span>
            </div>
          </div> -->
        <div class="w-100 margin-bottom-medium">
          <div v-if="callStatus == 2" class="f-row f-center f-vertical-center">
            <f7-button
              v-if="isCallMinimised == false"
              round
              fill
              small
              class="text-primary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="
                isCallMinimised = true;
                isVideoExpanded = false;
              "
            >
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="24"
                  f7="arrow_down_right_arrow_up_left"
                ></f7-icon>
              </span>
            </f7-button>

            <!-- <f7-button
              v-if="isVideoExpanded == true"
              round
              fill
              small
              class="text-primary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="isVideoExpanded = false"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="expand_less"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isVideoExpanded == false && callStatus == 2"
              round
              fill
              small
              class="text-primary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="isVideoExpanded = true"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="expand_more"></f7-icon>
              </span>
            </f7-button> -->
            <f7-button
              v-if="
                isVideoExpanded == true &&
                isLocalVideo == true &&
                videoDevices.length > 1
              "
              round
              fill
              small
              class="text-primary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="fnSwitchVideoCamera()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" f7="camera_rotate"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isVideoExpanded == true && isLocalVideo == false"
              round
              fill
              small
              class="text-secondary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="fnEnableVideo()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="videocam_off"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isVideoExpanded == true && isLocalVideo == true"
              round
              fill
              small
              class="background-secondary call-footer-button f-row f-vertical-center text-primary margin-right-small"
              @click="fnDisableVideo()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="videocam"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              round
              v-if="isAudioSpeaker != true && isCordova == true"
              fill
              small
              class="text-secondary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="fnSwitchAudioOutput()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" f7="speaker_3"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isAudioSpeaker == true && isCordova == true"
              round
              fill
              small
              class="text-primary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="fnSwitchAudioOutput()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" f7="speaker_3"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isVideoExpanded == true && isLocalAudio == false"
              round
              fill
              small
              class="text-secondary call-footer-button f-row f-vertical-center background-secondary margin-right-small"
              @click="fnEnableAudio()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="mic_off"></f7-icon>
              </span>
            </f7-button>
            <f7-button
              v-if="isVideoExpanded == true && isLocalAudio == true"
              round
              fill
              small
              class="background-secondary call-footer-button f-row f-vertical-center text-primary margin-right-small"
              @click="fnDisableAudio()"
            >
              <span class="f-row f-vertical-center">
                <f7-icon size="24" material="mic"></f7-icon>
              </span>
            </f7-button>
          </div>
          <div class="f-row margin-top-large f-center">
            <f7-button
              fill
              class="background-red f-row f-vertical-center text-white"
              @click="disconnect(true)"
              style="min-width: 68px"
            >
              <span class="f-row f-vertical-center">
                <!-- <f7-icon size="30" material="call_end"></f7-icon> -->
                End
              </span>
            </f7-button>
            <f7-button
              v-if="callStatus == 1"
              style="margin-left: 26px; min-width: 68px"
              fill
              class="background-primary f-row f-vertical-center text-white"
              @click="connectRoom()"
            >
              <span class="f-row f-vertical-center">
                <!-- <f7-icon size="30" material="call"></f7-icon> -->
                Accept
              </span>
            </f7-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="f-row f-vertical-center f-end disable-click"
      v-show="isCallMinimised == true"
      style="height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top))"
    >
      <f7-button
        raised
        fill
        small
        style="margin-right: 6px"
        class="background-tertiary text-primary allow-click"
        @click="
          isCallMinimised = false;
          isVideoExpanded = true;
        "
      >
        <span class="f-row f-vertical-center">
          <f7-icon
            size="16"
            class="margin-right-xsmall"
            f7="arrow_up_left_arrow_down_right"
          ></f7-icon>
          <span class="loading" v-if="callStatus == 1">Connecting</span>
          <span v-if="callStatus == 2">{{ timerTime }}</span>
        </span>
      </f7-button>
    </div>
  </div>
</template>
<style>
.collapsed {
  height: 0 !important;
  width: 0 !important;
  max-height: 0 !important;
  max-width: 0 !important;
}
.h-100vh {
  /* min-height: 100vh; */
  min-height: 100%;
}
.h-20vh {
  /* min-height: 100vh; */
  min-height: 20%;
}
.call-footer-button {
  border-radius: 50%;
  height: 44px;
  width: 44px;
}
.timer {
  font-size: medium;
}
.local-video {
  height: 100px;
  width: 85px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  border-radius: 4px;
  margin: 15px;
}
.local-video video {
  height: 100px !important;
  width: 85px !important;
  object-fit: cover !important;
}
.remote-video video {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
  flex-grow: 1;
}
/* loading dots */

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
.call-minimised {
  position: relative;
  right: 0;
  top: 2px;
  margin: 6px;
  perspective: 400px;
  z-index: 12500;
}
.calling-status {
  position: absolute;
  top: 30px;
}
.video-call-buttons {
  min-height: 50px;
  position: absolute;
  bottom: 30px;
}
.call-footer-button {
  border-radius: 50%;
  height: 44px;
  width: 44px;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: var(--b7-text-color-secondary);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 var(--b7-text-color-secondary),
      0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--b7-text-color-secondary),
      0.5em 0 0 var(--b7-text-color-secondary);
  }
}
</style>
<script>
// const { connect, createLocalVideoTrack } = require("twilio-video");
var connect, createLocalVideoTrack;
var token = "";
const fireStore = require("../store/firebaseStore.js");
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";
const doctorCallLogStore = require("../store/doctorCallLogStore.js");
const patientCallLogStore = require("../store/patientCallLogStore.js");
const dbUtils = require("../store/dbUtils.js");
const errorHandler = require("../shared/error-handler.js");
import moment from "moment";
import EventBus from "../shared/eventBus.js";

export default {
  name: "video-audio-call",
  components: {},
  props: {
    _roomId: String,
    callLogId: String,
  },
  computed: {
    ...mapState(["currentUser", "__CurrentPatient"]),
  },
  data: function () {
    return {
      peerConnection: null,
      localStream: null,
      remoteStream: null,
      roomDialog: null,
      roomId: null,
      duration: "",
      timerId: "",
      timerDate: "",
      timerTime: "",
      elLocalVideo: "",
      elRemoteVideo: "",
      elRemoteAudio: "",
      room: null,
      isLocalVideo: false,
      isLocalAudio: false,
      isRemoteAudio: false,
      isRemoteVideo: false,
      callStatus: 1,
      popupOpened: false,
      isCallMinimised: false,
      isVideoExpanded: true,
      callListener: null,
      isCallAnswered: false,
      callerName: "",
      isCordova: false,
      isAudioSpeaker: false,
      videoDevices: [],
      audioOutputDevices: [],
    };
  },

  watch: {
    isCallMinimised(newValue, oldValue) {
      EventBus.$emit("call-minimise-event", newValue);
    },
  },
  created: function () {},
  mounted: function () {
    let that = this;
    this.callerName = this.currentUser.Name;
    if (this._roomId) {
      let twilio = require("twilio-video");
      // console.log(twilio);
      connect = twilio.connect;
      createLocalVideoTrack = twilio.createLocalVideoTrack;
      this.roomId = this._roomId;
      // this.elLocalVideo = document.querySelector("#localVideo");

      // this.elRemoteVideo = document.querySelector("#remoteVideo");

      this.elLocalVideo = document.querySelector("#localVideo");

      this.elRemoteVideo = document.querySelector("#remoteVideo");
      this.elRemoteAudio = document.querySelector("#remoteAudio");
      this.elRemoteAudioOutput = document.querySelector("#remoteAudioOutput");

      patientCallLogStore.fnSubscribeToOngoingCall(
        this.__CurrentPatient.PatientId,
        this.callLogId,
        function (data) {
          if (data.Status == 4) {
            that.disconnect();
          }
        }
      );

      // this.joinRoom();
    }
  },

  methods: {
    fnGetUserMedia() {
      return new Promise((resolve, reject) => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          console.log("getUserMedia supported.");
          navigator.mediaDevices
            .getUserMedia(
              // constraints - only audio needed for this app
              {
                audio: true,
                video: true,
              }
            )

            // Success callback
            .then(
              function (stream) {
                resolve();
              },
              function () {
                reject(-1);
              }
            )

            // Error callback
            .catch(function (err) {
              reject(-1);
              console.log("The following getUserMedia error occurred: " + err);
            });
        } else {
          reject(-2);
          console.log("getUserMedia not supported on your browser!");
        }
      });
    },
    fnGetTwilioToken() {
      return new Promise((resolve, reject) => {
        const Http = new XMLHttpRequest();
        const url =
          "https://asia-east2-scripty-fd554.cloudfunctions.net/getTwilioToken?roomId=" +
          this.roomId +
          "&identity=" +
          this.__CurrentPatient.PatientId;
        Http.open("GET", url);
        Http.send();

        Http.onreadystatechange = (e) => {
          if (Http.readyState == 4 && Http.status == 200) {
            token = Http.responseText;
            console.log(token);
            resolve();
          }
        };
      });
    },
    fnRejectCall() {
      if (window.audioCallFile) {
        window.audioCallFile.pause();
        window.audioCallFile.load();
      }
      this.disconnect(true, 4);
    },
    connectRoom(isReconnecting = false) {
      let that = this;
      //Handle doctor disconnect event when trying to reconnect
      if (isReconnecting == true && this.roomId == null) {
        errorHandler.fnRaiseError("Call Ended", "Call ended by doctor");
        return;
      }
      let fnConnect = function () {
        connect(token, {
          audio: true,
          name: that.roomId,
          sdpSemantics: "plan-b",
          bundlePolicy: "max-compat",
        }).then(
          (room) => {
            navigator.mediaDevices.enumerateDevices().then(that.fnGotDevices);
            if (window.audioCallFile) {
              window.audioCallFile.pause();
              window.audioCallFile.load();
            }
            that.isCallAnswered = true;
            that.callStatus = 2;
            that.isVideoExpanded = true;
            // this.startTimer();
            that.room = room;
            that.isLocalAudio = true;
            //Broadcast Update Call
            doctorCallLogStore.fnUpdateCallLog({
              CallLogId: that.callLogId,
              DoctorId: that.currentUser.uid,
              Status: 2,
            });

            patientCallLogStore.fnUpdateCallLog({
              CallLogId: that.callLogId,
              PatientId: that.__CurrentPatient.PatientId,

              Status: 2,
            });
            // room.localParticipant.videoTracks.forEach((publication) => {
            //   that.fnAttachLocalTrack(publication.track);
            // });

            //Existing Participants
            room.participants.forEach(that.participantConnected);

            //New Participants
            room.on("participantConnected", that.participantConnected);

            //Participant Disconnected
            room.on("participantDisconnected", that.participantDisconnected);

            //Room Disconnected
            // room.once("disconnected", (error) =>
            //   room.participants.forEach(this.participantDisconnected)
            // );
          },
          (error) => {
            that.fnHandleCallEnd(-1);
            console.error(`Unable to connect to Room: ${error.message}`);
          }
        );
      };
      this.fnGetUserMedia().then(
        function () {
          if (token) {
            //Twilio token is available, connect using that
            fnConnect();
          } else {
            that.fnGetTwilioToken().then(fnConnect);
          }
        },
        function (status) {
          that.fnHandleCallEnd(status);
        }
      );
    },
    participantConnected(participant) {
      console.log('Participant "%s" connected', participant.identity);
      console.log(participant);
      let that = this;
      this.startTimer();

      // div.innerText = participant.identity;

      participant.on("trackSubscribed", (track) => {
        if (track.kind === "video") {
          let divRemoteVideo = that.elRemoteVideo;
          divRemoteVideo.id = participant.sid;
          that.trackSubscribed(divRemoteVideo, track);
        } else if (track.kind === "audio") {
          let divRemoteAudio = that.elRemoteAudio;
          divRemoteAudio.id = participant.sid;
          that.trackSubscribed(divRemoteAudio, track);
        }
      });
      participant.on("trackUnsubscribed", that.trackUnsubscribed);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          that.trackSubscribed(div, publication.track);
        }
      });
    },

    participantDisconnected(participant) {
      this.stopTimer();
      console.log('Participant "%s" disconnected', participant.identity);
      document.getElementById(participant.sid).remove();
      this.fnHandleCallEnd(3);
    },
    fnHandleCallEnd(status) {
      let that = this;
      let title = "Call Ended";
      let isLocalDisconnect = false;
      let message = "Call has been disconnected";
      if (status == 3) {
        message = "Call ended by doctor";
      }
      if (status == 4) {
        message = "Call ended by doctor";
      }
      if (status == -1) {
        isLocalDisconnect = true;
        title = "Camera, microphone permissions denied";
        message =
          "Please grant camera, microphone permissions and try connecting again. Check browser url bar for popup or camera icon.";
        errorHandler.fnRaiseSuccessWithCallbacks(
          title,
          message,
          "Connect",
          "Deny",
          function () {
            that.connectRoom(true);
          },
          function () {
            that.disconnect(isLocalDisconnect);
          }
        );
      } else if (status == -2) {
        isLocalDisconnect = true;
        title = "Could not connect";
        message = "Audio/Video call not supported in your browser.";
        errorHandler.fnRaiseSuccessWithCallback(title, message, function () {
          that.disconnect(isLocalDisconnect);
        });
      } else {
        errorHandler.fnRaiseSuccessWithCallback(title, message, function () {
          that.disconnect();
        });
      }
    },

    trackSubscribed(div, track) {
      console.log("remote participant track subscribed");
      let that = this;
      if (track.kind == "video") {
        this.isRemoteVideo = true;
      }
      if (track.kind == "audio") {
        this.isRemoteAudio = true;
      }
      let remoteTrackElement = track.attach();

      //Remote Audio Output
      if (track.kind == "audio") {
        let selectedAudioOutput = this.fnGetSelectedAudioOutputDevice();
        if (selectedAudioOutput && remoteTrackElement.setSinkId) {
          remoteTrackElement
            .setSinkId(selectedAudioOutput.deviceId)
            .then(() => {
              div.appendChild(remoteTrackElement);
            });
        } else {
          div.appendChild(remoteTrackElement);
        }
      } else {
        div.appendChild(remoteTrackElement);
      }

      track.on("disabled", () => {
        if (track.kind == "video") {
          that.isRemoteVideo = false;
        }
        if (track.kind == "audio") {
          that.isRemoteAudio = false;
        }
      });
      track.on("enabled", () => {
        if (track.kind == "video") {
          that.isRemoteVideo = true;
        }
        if (track.kind == "audio") {
          that.isRemoteAudio = true;
        }
      });
    },

    trackUnsubscribed(track) {
      console.log("remote participant track unsubscribed");
      let that = this;
      if (track.kind == "video") {
        that.isRemoteVideo = false;
      }
      if (track.kind == "audio") {
        that.isRemoteAudio = false;
      }

      track.detach().forEach((element) => element.remove());
    },
    async disconnect(isLocalDisconnect = false) {
      this.stopTimer();

      if (this.callListener) {
        this.callListener();
      }
      if (isLocalDisconnect == true && this.roomId) {
        let roomId = this.roomId;
        let status = this.callStatus == 1 ? 4 : 3;
        //Broadcast Call disconnected
        await doctorCallLogStore.fnUpdateCallLog({
          CallLogId: roomId,
          DoctorId: this.currentUser.uid,
          // Duration: this.timerTime,
          Status: status,
        });

        await patientCallLogStore.fnUpdateCallLog({
          CallLogId: roomId,
          PatientId: this.__CurrentPatient.PatientId,
          Duration: this.timerTime,
          Status: status,
        });

        roomId = null;
      }

      if (this.room) {
        // Detach the local media elements
        this.room.localParticipant.tracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
          console.log("Unpublishing");
        });
        this.room.disconnect();
      }

      this.room = null;
      this.roomId = null;
      token = "";
      if (isLocalDisconnect == true) {
        errorHandler.fnRaiseSuccess("Call Ended", "Call has been disconnected");
      }
      console.log("Emitting Disconnect Event");
      EventBus.$emit("disconnect-video-call");
    },
    fnEnableVideo() {
      let that = this;
      let defaultVideoDevice = this.fnGetDefaultVideoDevice();
      if (defaultVideoDevice) {
        createLocalVideoTrack({
          deviceId: { exact: defaultVideoDevice.deviceId },
        })
          .then((localVideoTrack) => {
            that.fnSetVideoDeviceAsSelected(defaultVideoDevice.deviceId);
            return that.room.localParticipant.publishTrack(localVideoTrack);
          })
          .then((publication) => {
            that.fnAttachLocalTrack(publication.track);
            console.log("Successfully unmuted your video:", publication);
          });
      } else {
        errorHandler.fnRaiseError(
          "Video Device Error",
          "No video devices found or insufficient permissions"
        );
      }
    },
    fnDisableVideo() {
      this.room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.stop();
        publication.unpublish();
        publication.track.detach().forEach((element) => element.remove());
      });
      this.isLocalVideo = false;
    },
    fnEnableAudio() {
      this.room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
      this.isLocalAudio = true;
    },
    fnDisableAudio() {
      this.room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
      this.isLocalAudio = false;
    },
    fnAttachLocalTrack(track) {
      if (track.kind == "video") {
        this.elLocalVideo.appendChild(track.attach());

        this.isLocalVideo = true;
      }
    },
    fnGotDevices(mediaDevices) {
      let count = 1;
      mediaDevices.forEach((mediaDevice) => {
        if (mediaDevice.kind === "videoinput") {
          // console.log("Media Device", mediaDevice);
          this.videoDevices.push({
            deviceId: mediaDevice.deviceId,
            name: mediaDevice.label || `Camera ${count}`,
            isSelected: false,
          });
        } else if (mediaDevice.kind === "audiooutput") {
          this.audioOutputDevices.push({
            deviceId: mediaDevice.deviceId,
            name: mediaDevice.label || `Audio Output ${count}`,
            isSelected: false,
          });
        }
        count++;
      });
      console.log("audio output devices", this.audioOutputDevices);
      //Select first audio output device
      if (this.audioOutputDevices.length > 0) {
        this.audioOutputDevices[0].isSelected = true;
      }
    },
    fnSetVideoDeviceAsSelected(deviceId) {
      this.videoDevices.forEach((device) => {
        if (device.deviceId == deviceId) {
          device.isSelected = true;
        } else {
          device.isSelected = false;
        }
      });
    },
    fnGetDefaultVideoDevice() {
      if (this.videoDevices.length > 0) {
        return this.videoDevices[0];
      }
    },
    fnSwitchVideoCamera() {
      console.log("Switching Camera");
      let newVideoDevices = this.videoDevices.filter(
        (device) => device.isSelected == false
      );
      let selectedVideoDevice = newVideoDevices[0];
      this.fnUpdateVideoDevice(selectedVideoDevice.deviceId);
    },
    fnUpdateVideoDevice(deviceId) {
      let that = this;
      createLocalVideoTrack({
        deviceId: { exact: deviceId },
      }).then(function (localVideoTrack) {
        that.room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
          publication.track.detach().forEach((element) => element.remove());
        });
        that.room.localParticipant
          .publishTrack(localVideoTrack)
          .then((publication) => {
            that.fnAttachLocalTrack(publication.track);
            that.fnSetVideoDeviceAsSelected(deviceId);
            console.log("Successfully unmuted your video:", publication);
          });
      });
    },

    fnGetSelectedAudioOutputDevice() {
      let newDevices = this.audioOutputDevices.filter(
        (device) => device.isSelected == true
      );
      if (newDevices.length > 0) {
        return newDevices[0];
      }
    },
    fnSetAudioOutputDeviceAsSelected(deviceId) {
      this.audioOutputDevices.forEach((device) => {
        if (device.deviceId == deviceId) {
          device.isSelected = true;
        } else {
          device.isSelected = false;
        }
      });
    },
    // fnGetDefaultAudioOutputDevice() {
    //   if (this.audioOutputDevices.length > 0) {
    //     return this.audioOutputDevices[0];
    //   }
    // },
    fnSwitchAudioOutput() {
      if (cordova.plugins.audioroute) {
        cordova.plugins.audioroute.currentOutputs(function (outputs) {
          console.log(outputs);
        });
      }
      console.log("Switching Audio Output");
      if (window.isCordova == true) {
        if (this.isAudioSpeaker == false) {
          cordova.plugins.audioroute.overrideOutput("speaker");
          // AudioToggle.setAudioMode(AudioToggle.SPEAKER);
          this.isAudioSpeaker = true;
        } else {
          // AudioToggle.setAudioMode(AudioToggle.EARPIECE);
          cordova.plugins.audioroute.overrideOutput("default");
          this.isAudioSpeaker = false;
        }
      } else {
        let newDevices = this.audioOutputDevices.filter(
          (device) => device.isSelected == false
        );
        let selectedDevice = newDevices[0];
        this.fnUpdateAudioOutputDevice(selectedDevice.deviceId);
      }
    },
    fnUpdateAudioOutputDevice(deviceId) {
      let that = this;

      that.room.participants.forEach((remoteParticipant) => {
        remoteParticipant.audioTracks.forEach((publication) => {
          publication.track.detach().forEach((element) => element.remove());
          let remoteTrackElement = publication.track.attach();

          remoteTrackElement
            .setSinkId(selectedAudioOutput.deviceId)
            .then(() => {
              that.elRemoteAudioOutput.appendChild(remoteTrackElement);
              that.fnSetAudioOutputDeviceAsSelected(deviceId);
              that.isAudioSpeaker = true;
            });
        });
      });
    },

    startTimer() {
      this.stopTimer();
      this.timerDate = new Date("2020-10-10T00:00:00+00:00");
      this.timerId = setInterval(this.updateTime, 1000);
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = "";
      }
    },
    updateTime() {
      this.timerDate = new Date(this.timerDate.getTime() + 1000);
      this.timerTime = moment.utc(this.timerDate).format("HH:mm:ss");
    },
    fnGetTime() {
      return moment(this.timerDate).format("HH:mm:ss");
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    fnPageBeforeOut() {
      this.stopTimer();
    },
  },
  beforeDestroy: function () {
    console.log("Video Call Component : Before Destroy");
    const self = this;
    this.stopTimer();
    if (window.audioCallFile) {
      window.audioCallFile.pause();
      window.audioCallFile.load();
    }

    // Destroy popup when page removed
    // if (self.popup) self.popup.destroy();
  },
};
</script>
