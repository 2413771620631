<template>
  <f7-page>
    <f7-navbar>
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        href="/patient-view/"
      ></f7-link>
      <f7-nav-title>Pay and Confirm</f7-nav-title>
    </f7-navbar>
    <div class="h-100 overflow-y-auto">
      <f7-block-title>
        <span>Payment Account Options</span>
      </f7-block-title>
      <f7-list>
        <f7-list-item
          v-for="(PaymentThirdParty, index) in PaymentThirdParties"
          :key="index"
        >
          <div class="f-column">
            <span class="f-column">
              <span
                v-if="
                  PaymentThirdParty.AccountType &&
                  PaymentThirdParty.AccountType != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">Account Type</span>:&nbsp;{{
                  fnGetAccountTypeName(PaymentThirdParty.AccountType)
                }}</span
              >
              <span
                v-if="
                  PaymentThirdParty.AccountHolderName &&
                  PaymentThirdParty.AccountHolderName != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">Account Holder</span>:&nbsp;{{
                  PaymentThirdParty.AccountHolderName
                }}</span
              >
              <span
                v-if="
                  PaymentThirdParty.ProviderName &&
                  PaymentThirdParty.ProviderName != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">Provider</span>:&nbsp;{{
                  PaymentThirdParty.ProviderName
                }}</span
              >
              <span
                v-if="PaymentThirdParty.UPIId && PaymentThirdParty.UPIId != ''"
                class="margin-right-small"
                ><span class="text-secondary">UPI ID</span>:&nbsp;{{
                  PaymentThirdParty.UPIId
                }}</span
              >
              <span
                v-if="
                  PaymentThirdParty.PhoneNumber &&
                  PaymentThirdParty.PhoneNumber != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">Phone Number</span>:&nbsp;{{
                  PaymentThirdParty.PhoneNumber
                }}</span
              >
              <span
                v-if="
                  PaymentThirdParty.BankAccountNumber &&
                  PaymentThirdParty.BankAccountNumber != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">Bank Account No</span>:&nbsp;{{
                  PaymentThirdParty.BankAccountNumber
                }}</span
              >
              <span
                v-if="
                  PaymentThirdParty.IFSCCode && PaymentThirdParty.IFSCCode != ''
                "
                class="margin-right-small"
                ><span class="text-secondary">IFSC Code</span>:&nbsp;{{
                  PaymentThirdParty.IFSCCode
                }}</span
              >
            </span>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Payment Confirmation Image</f7-block-title>
      <div class="f-column margin-small">
        <img
          crossorigin="anonymous"
          v-if="paymentImageUrl != ''"
          :src="paymentImageUrl"
          style="width: 100%"
        />
        <span class="text-primary font-small" v-else
          >* Please upload confirmation receipt image once you have made payment
          in any of the above doctor accounts. The doctor will be able to view
          this receipt and confirm payment once image is uploaded.</span
        >
      </div>
      <form id="create-payment-form" class="margin-small">
        <image-upload
          @paymentConfirmationUrl="fnUpdatePaymentConfirmationUrl"
          @paymentConfirmationRemoved="fnPaymentConfirmationRemoved"
          v-bind:caller="'paymentConfirmation'"
          v-bind:fileName="paymentImageUrl"
          v-bind:imageUrls="imageUrls"
          v-bind:fileFolder="uploadFolder"
        ></image-upload>
      </form>
    </div>
  </f7-page>
</template>

<script>
const fireStore = require("../store/firebaseStore.js");
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";
import ImageUpload from "../shared/image-upload.vue";
const paymentConfirmationImagesStore = require("../store/paymentConfirmationImagesStore.js");
const appointmentsStore = require("../store/appointmentsStore.js");

const dbUtils = require("../store/dbUtils.js");
const errorHandler = require("../shared/error-handler.js");

export default {
  name: "payment-confirm",
  data: function () {
    return {
      imageUrls: [],
      paymentImageUrl: "",
      uploadFolder: "PaymentConfirmationImages",
      accountTypes: [
        {
          Name: "Bank Account",
          Value: 1,
        },
        {
          Name: "UPI",
          Value: 2,
        },
        {
          Name: "Phone Number",
          Value: 3,
        },
      ],
      PaymentThirdParties: [],
    };
  },
  components: {
    ImageUpload,
  },
  props: {
    appointmentId: null,
  },
  computed: {
    ...mapState(["currentUser", "__CurrentPatient", "__PaymentThirdParties"]),
  },
  created: function () {
    this.PaymentThirdParties = this.__PaymentThirdParties;
  },
  mounted: function () {
    let that = this;
    this.fnGetPaymentConfirmationImageUrl(function (imageUrl) {
      console.log(imageUrl);
      that.paymentImageUrl = imageUrl;
    });
    // console.log(this.IsEdit);
  },
  methods: {
    fnGetAccountTypeName(accountType) {
      return this.accountTypes.find((a) => a.Value == accountType).Name;
    },
    fnValidatePatientForm() {
      console.log("Validate patient form");
      // if (this.elPatientForm && this.elPatientForm[0]) {
      //   console.log(this.elPatientForm[0].checkValidity());
      //   return this.elPatientForm[0].checkValidity();
      // }
    },

    fnUpdatePaymentConfirmationUrl(file) {
      console.log(file);
      let dialog = f7App.dialog.preloader("Saving image");
      let platformType = "Web";
      if (window.isCordova == true) {
        platformType = "App";
      }
      let deviceLocation = "";
      let serverLocation = "";
      if (file.deviceLocation) {
        deviceLocation = file.deviceLocation + folder + "/";
      }
      if (file.serverLocation) {
        serverLocation = file.serverLocation;
      }
      // console.log(url);
      let that = this;
      let image = {
        PaymentConfirmationImageId: dbUtils.generateUID(),
        AppointmentId: this.appointmentId,
        Name: file.fileName,
        StorageLocationDevice: deviceLocation,
        StorageLocationServer: serverLocation,
        PlatformType: platformType,
      };
      // console.log(image);
      // that.ima
      paymentConfirmationImagesStore.fnInsert(
        image,
        function () {
          let appointment = {
            AppointmentId: that.appointmentId,
            PaymentStatus: 1,
            Status: "Awaiting Confirmation",
          };
          console.log(appointment);
          appointmentsStore.fnUpdateAppointment(
            appointment,
            function () {
              that.fnGetPaymentConfirmationImageUrl(function (imageUrl) {
                // console.log(imageUrl);

                that.paymentImageUrl = imageUrl;
                dialog.close();
                errorHandler.fnRaiseSuccess(
                  "",
                  "Image of receipt successfully uplaoded. Doctor can now view this receipt."
                );
              });
            },
            function (error) {
              console.log(error);
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
      // setTimeout(function () {
      //   console.log("Trying to fetch file");
      //   that.fnGetLogoImageUrl(function (imageUrl) {
      //     that.LogoUrl = imageUrl;
      //     console.log(imageUrl);
      //     that.LogoUrls.push(imageUrl);
      //   });
      // }, 2000);
    },
    fnGetPaymentConfirmationImageUrl(fnCallBack) {
      let imageUrl = "";
      let fileName = "";

      paymentConfirmationImagesStore.fnGetImage(this.appointmentId, function (
        items
      ) {
        console.log("Fetched Items " + items.length);
        // console.log("Items " + items.length);
        if (items && items.length > 0) {
          console.log(items[0]);

          fileName = items[0].Name;
          if (
            window.isCordova == true &&
            items[0].StorageLocationDevice != "" &&
            items[0].StorageLocationDevice != null
          ) {
            fileUtils.fnGetImageUrl(
              fileName,
              this.uploadFolder,
              "App",
              fnCallBack
            );
            // fnCallBack(items[0].StorageLocationDevice + fileName);
          } else {
            fnCallBack(items[0].StorageLocationServer);
          }
        }
      });
    },
    fnPaymentConfirmationRemoved(url) {
      console.log(url);
    },
  },
};
</script>
