import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");
// const Prescription = require("../models/Prescription.js");

const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "MedicalRecords";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const syncService = require('../services/syncService.js')
const primaryKeyName = props.find(t => t.primaryKey == true).name;

const fnInsertMedicalRecord = function (medicalRecord, fnSuccessCallback, fnFailureCallBack) {
    medicalRecord.MedicalRecordUID = dbUtils.fnGenerateUniqueStringId();

    medicalRecord.IsSynced = 0;
    dbUtils.fnTrimTextOnObject(medicalRecord, props);

    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(medicalRecord, props) + ')';
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                // syncService.fnInitiateSync("Prescriptions", 'prescriptions')
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .medicalRecords(firebase)
            .doc(medicalRecord.MedicalRecordId)
            .set(medicalRecord)
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("medical record collection error");
                console.log(err);
            });
    }

}

const fnGetMedicalRecords = function (patientId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE PatientId = ? ORDER BY DateMs Desc", [patientId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .medicalRecords(firebase, patientId)
            // .orderBy("DateMs", "desc")
            .onSnapshot(function (items) {
                let _items = [];
                let data = null;
                items.docChanges().forEach(change => {
                    let doc = change.doc;
                    // items.forEach(function (doc) {
                    // data = doc.data();
                    if (!doc.metadata.hasPendingWrites) {

                        if (fnSuccessCallback) {
                            fnSuccessCallback(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
                        }
                    }
                    // _items.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));

                    // });
                    // if (fnSuccessCallback) {
                    //     fnSuccessCallback(_items);
                    // }

                });
            }, function (error) {
                if (fnFailureCallBack) {
                    fnFailureCallBack(error);
                }
            })
        // .then(items => {
        //     let records = [];
        //     items.forEach(doc => {
        //         if (doc) {
        //             records.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
        //         }
        //     });
        //     if (fnSuccessCallback) {
        //         fnSuccessCallback(records);
        //     }
        // })
        // .catch(function (error) {
        //     console.log("Error getting documents: ", error);
        // });
    }

}

export {
    fnInsertMedicalRecord,
    fnGetMedicalRecords
}