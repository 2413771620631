import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");


const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "PatientEmailIds";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const patientProps = dbSchema.tables.find(t => t.name == "Patients").props;
const patientEncryptedProps = patientProps.filter(t => t.isEncrypted == true);
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const encryptedProps = props.filter(t => t.isEncrypted == true);
const syncService = require('../services/syncService.js')
const dbLocal = require('./dbLocal.js');

const fnInsertPatientEmailId = function (patient, fnSuccessCallback, fnFailureCallBack) {
    patient.IsSynced = 0;


    // let serialNumber = "";
    // let fnSerialNoCallback = function (number) {
    //     console.log(number)
    //     serialNumber = number;
    // }

    // let fnSerialNumberSuccessCallback = function (serialNumber) {
    //     console.log(serialNumber);

    patient.PatientEmailId = dbUtils.generateUID();

    dbUtils.fnTrimTextOnObject(patient, props);


    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(patient, props) + ')';
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                // console.log("Initiating Sync Service")
                // syncService.fnInitiateSync(tableName, 'patients')

                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .patientPhones(firebase)
            .doc(patient[primaryKeyName])
            .set(patient)
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }
    // }
    // fnSerialNumberSuccessCallback(dbUtils.generateUID());
    // dbLocal.fnGetNextSerialNumber(primaryKeyName, "Patients", "patients", "PatientUID", "CreatedDate", "desc", props, fnSerialNumberSuccessCallback);


}
const fnUpdatePatientEmailId = function (patient, fnSuccessCallback, fnFailureCallBack) {


    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, patient)
            + ' WHERE ' + primaryKeyName + " = '" + patient[primaryKeyName] + "'";
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .patientPhones(firebase)
            .doc(patient[primaryKeyName])
            .update(dbUtils.fnGetUpdatableObject(updatableProps, patient))
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }

}

const fnSearchPatientsByEmailId = function (patientEmail, fnSuccessCallback, fnFailureCallBack) {
    patientEmail = dbUtils.fnEncrypt(patientEmail);
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM Patients" + " WHERE Email = ? ", [patientEmail], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, patientProps, patientEncryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .patients(firebase)
            .where("Email", "==", patientEmail)
            .get()
            .then(items => {
                // console.log("Patients Found");
                let patients = [];
                items.forEach(doc => {
                    if (doc) {
                        patients.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, patientProps, patientEncryptedProps));
                    }
                });
                if (fnSuccessCallback) {
                    fnSuccessCallback(patients);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}
const fnSearchPatientEmailIds = function (patientEmail, fnSuccessCallback, fnFailureCallBack) {
    patientEmail = patientEmail + "%s";
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE Email LIKE ? ", [patientEmail], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }

}

export {
    fnInsertPatientEmailId,
    fnUpdatePatientEmailId,
    fnSearchPatientsByEmailId,
    fnSearchPatientEmailIds
}