<template>
  <div class="f-column f-grow">
    <!-- <f7-navbar title="Messages">
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        href="/patient-view/"
      ></f7-link>
    </f7-navbar> -->
    <div
      v-if="isLoading == true"
      style="height: 100%; overflow-y: auto"
      class="f-row f-center margin-small"
    >
      <f7-preloader></f7-preloader>
    </div>
    <div
      v-if="isLoading == false && messagesData.length == 0"
      style="height: 100%; overflow-y: auto"
      class="f-row f-center margin-small"
    ></div>
    <div
      v-if="isLoading == false && messagesData.length > 0"
      id="chat-messages-container"
      class="f-column f-grow"
      style="height: 100%; overflow-y: auto"
    >
      <div class="messages-content">
        <div class="messages">
          <div
            class="message"
            v-bind:class="
              message.SentBy == 1 ? 'message-received' : 'message-sent'
            "
            v-for="(message, index) in messagesData"
            :key="index"
          >
            <div class="message-content">
              <div class="message-bubble">
                <div v-if="message.Message" class="message-text">
                  <span v-html="formatHTML(message.Message)"></span>
                </div>
                <!-- <div v-if="message.image" class="message-image">
                  <img
                    :src="message.image"
                    style="width: 200px; height: 260px"
                  />
                </div> -->
              </div>
              <div class="message-footer">
                {{ message.ServerTimestampDate | formatDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="status != 3"
      class="toolbar toolbar-bottom messagebar messagebar-init"
    >
      <div class="toolbar-inner">
        <div class="messagebar-area">
          <textarea
            style="max-height: 75px"
            class="resizable"
            v-model="messageText"
            placeholder="Enter text here"
          ></textarea>
        </div>
        <f7-button
          :disabled="messageText == ''"
          v-if="isSendingMessage == false"
          fill
          :class="
            messageText == ''
              ? 'background-white text-secondary button-small'
              : 'background-tertiary text-primary'
          "
          style="margin-right: 6px"
          @click="fnSendMessage()"
        >
          <span class="f-row f-vertical-center margin-left-xsmall">
            Send<f7-icon
              size="13"
              class="margin-left-xsmall margin-right-small"
              material="send"
            ></f7-icon>
          </span>
        </f7-button>

        <div class="margin-right-small" v-if="isSendingMessage == true">
          <f7-preloader></f7-preloader>
        </div>
      </div>
    </div>
    <!-- <f7-messages>
      <f7-messages-title><b>Sunday, Feb 9,</b> 12:58</f7-messages-title>
      <f7-message
        v-for="(message, index) in messagesData"
        :key="index"
        :type="message.type"
        :image="message.image"
        :name="message.name"
        :avatar="message.avatar"
        :first="isFirstMessage(message, index)"
        :last="isLastMessage(message, index)"
        :tail="isTailMessage(message, index)"
      >
        <template #text>
          <span v-if="message.text" v-html="message.text"></span>
        </template>
      </f7-message>
      <f7-message
        v-if="typingMessage"
        type="received"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="`${typingMessage.name} is typing`"
        :avatar="typingMessage.avatar"
      ></f7-message>
    </f7-messages> -->
  </div>
</template>

<script>
const chatMessagesStore = require("../store/chatMessagesStore.js");
const errorHandler = require("../shared/error-handler.js");
const dbUtils = require("../store/dbUtils.js");
const utils = require("../shared/utils.js");
import moment from "moment";
import { mapState } from "vuex";
const chatsStore = require("../store/chatsStore.js");

export default {
  name: "chat-messages",

  data() {
    return {
      chatId: "",
      status: "",
      attachments: [],
      sheetVisible: false,
      typingMessage: null,
      messageText: "",
      messagesData: [],
      isLoading: true,
      fnUnsubscribeChatMessages: null,
      isSendingMessage: false,
      fnUnsubscribeChats: null,
    };
  },
  computed: {
    ...mapState(["__CurrentPatient", "currentUser"]),
  },
  mounted() {
    // console.log(this.chatId);
    let that = this;
    this.fnGetChats(function () {
      // that.fnGetChatMessages();
    });
    that.fnGetChatMessages();

    // this.$f7ready((f7) => {
    //   self.messagebar = f7.messagebar.get(self.$refs.messagebar.$el);
    // });
  },
  methods: {
    fnScrollChatToBottom() {
      this.$nextTick(function () {
        let container = this.$el.querySelector("#chat-messages-container");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    fnGetChats(fnSuccess) {
      this.Chats = [];
      let that = this;
      // console.log(this.currentUser);
      this.fnUnsubscribeChats = chatsStore.fnGetPatientChats(
        this.currentUser.uid,
        this.__CurrentPatient.PatientId,
        function (items) {
          if (items && items.length > 0) {
            console.log("Chats", items);
            that.chatId = items[0].ChatId;
            that.status = items[0].Status;
            if (fnSuccess) {
              fnSuccess();
            }
            // console.log(that.Chats);
          }
          // else {
          //   that.fnCreateNewChat(null, fnSuccess);
          // }
          that.ChatsCount = that.Chats.length;
        },
        function () {
          errorHandler.fnRaiseError();
        }
      );
      // this.$emit("chatsSubscribed", unsubscribe);
    },
    fnGetChatMessages(isSkipLoader = false) {
      let that = this;
      if (isSkipLoader == false) {
        that.isLoading = true;
      }
      this.fnUnsubscribeChatMessages = chatMessagesStore.fnGetChatMessages(
        this.currentUser.uid,
        this.__CurrentPatient.PatientId,
        function (items) {
          console.log("messages", items);
          that.isLoading = false;
          // console.log(items);
          if (items && items.length > 0) {
            let replacedArray = [];
            for (let i = 0; i < items.length; i++) {
              let item = items[i];
              if (item.ServerTimestamp) {
                item.ServerTimestampMs = item.ServerTimestamp.toMillis();
                item.ServerTimestampDate = item.ServerTimestamp.toDate();
              } else {
                //For data created before servertimestamp was added
                item.ServerTimestampMs = item.CreatedDateMs;
              }
              replacedArray = utils.fnReplaceItemInArray(
                that.messagesData,
                item,
                "ChatMessageId"
              );
            }
            let uniqueArray = utils.fnUniqueUnion(
              replacedArray,
              items,
              "ChatMessageId"
            );
            that.messagesData = Array.from(uniqueArray);
            that.messagesData = utils.fnSortArrayAscending(
              that.messagesData,
              "ServerTimestampMs"
            );
            that.$forceUpdate();
            that.fnScrollChatToBottom();
          }
        },
        function () {
          that.isLoading = false;

          errorHandler.fnRaiseError();
        }
      );
    },

    fnSendMessage() {
      let that = this;
      this.isSendingMessage = true;

      const text = that.messageText.replace(/\n/g, "<br>").trim();

      if (text.length == 0) {
        return;
      }
      let message = {
        ChatMessageId: dbUtils.generateUID(),
        ChatId: this.chatId,
        DoctorId: this.currentUser.uid,
        PatientId: this.__CurrentPatient.PatientId,
        SentBy: 2,
        Message: text,
      };
      chatMessagesStore.fnInsertChatMessage(
        message,
        function () {
          // Reset attachments
          that.attachments = [];
          // Hide sheet
          that.sheetVisible = false;
          // Clear area
          that.messageText = "";
          // Focus area
          // if (text.length) that.messagebar.focus();
          // Send message
          that.messagesData.push(message);
          that.fnScrollChatToBottom();
          that.$forceUpdate();

          that.isSendingMessage = false;
        },
        function () {
          errorHandler.fnRaiseError();
        }
      );
    },
    formatHTML(val) {
      if (!val) {
        return "";
      }
      val = val.replace("<script>", "");
      return val.replace("<//script>", "");
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD-MM-YY HH:mm");
    },
  },
  beforeDestroy() {
    if (this.fnUnsubscribeChatMessages) {
      this.fnUnsubscribeChatMessages();
    }
    if (this.fnUnsubscribeChats) {
      this.fnUnsubscribeChats();
    }
  },
};
</script>