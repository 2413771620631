const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "ChatMessages";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const genericRepository = require('./genericRepository.js')
const fireStoreCollectionName = "chatMessages";
const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnInsertChatMessage = function (item, fnSuccessCallback, fnFailureCallBack, isSync = true) {
    // console.log(item);
    genericRepository.fnInsertItem(item, tableName, fireStore[fireStoreCollectionName](firebase), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isSync);
}

// const fnGetChatMessages = function (chatId, fnSuccessCallback, fnFailureCallBack) {
//     let sql = "SELECT * FROM " + tableName + " WHERE ChatId = ? ";
//     let params = [chatId];
//     genericRepository.fnGetItems(tableName, sql, params, fireStore[fireStoreCollectionName](firebase, chatId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
// }
const fnGetChatMessages = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
    return fireStore[fireStoreCollectionName](firebase, doctorId, patientId).onSnapshot(function (items) {
        let _items = [];

        items.forEach(doc => {
            if (!doc.metadata.hasPendingWrites) {
                _items.push(doc.data());

            }
        });
        if (fnSuccessCallback) {
            fnSuccessCallback(_items);
        }
    }, function (error) {
        console.log(error);
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    })
}
const fnUpdateChatMessage = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnUpdateItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.ChatId, true), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnDeleteChatMessage = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnDeleteItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.ChatId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

export {
    fnInsertChatMessage,
    fnGetChatMessages,
    fnUpdateChatMessage,
    fnDeleteChatMessage
}