const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "MedicalRecordImages";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const fnInsert = function (item, fnSuccessCallback, fnFailureCallBack) {
    let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
        + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
    console.log(sql);
    window.localDB.transaction(function (tx) {
        tx.executeSql(sql, [], function (tx, resultSet) {
            if (fnSuccessCallback) {
                fnSuccessCallback(resultSet);
            }
        });

    }, function (error) {
        console.log('Transaction ERROR: ' + error.message);
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    }, function () {

    });

}

const fnGetImage = function (medicalRecordId, fnSuccessCallback, fnFailureCallBack) {

    window.localDB.transaction(function (tx) {

        tx.executeSql("SELECT * FROM " + tableName + " WHERE MedicalRecordId = ? ", [medicalRecordId], function (tx, resultSet) {
            if (fnSuccessCallback) {
                fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
            }
        });

    }, function (error) {
        console.log('Transaction ERROR: ' + error.message);
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    }, function () {

    });

}

export {
    fnInsert,
    fnGetImage
}