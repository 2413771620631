const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "DoctorLogos";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";
const fnInsert = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {

        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }

}

const fnGetImage = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? ", [doctorId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }else{
        fireStore
        .doctorLogos(firebase, doctorId)
        .get()
        .then((items) => {
            let images = [];
            items.forEach(doc => {
                // console.log(doc.data())
                if (doc) {
                    images.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
                }
            });
            if (fnSuccessCallback) {
                fnSuccessCallback(images);
            }
        })
        .catch(err => {
            console.log("images collection error");
            console.log(err);
        });
    }

}

const fnDeleteAllImages = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {

        window.localDB.transaction(function (tx) {

            tx.executeSql("DELETE FROM " + tableName + " WHERE DoctorId = ? ", [doctorId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }

}

export {
    fnInsert,
    fnGetImage,
    fnDeleteAllImages
}