import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");

const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "SignatureImages";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const fnInsert = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        console.log(item);
        item.StorageLocationDevice = '';
        fireStore
            .signatureImages(firebase)
            .doc(item.SignatureImageId)
            .set(item)
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("prescription signature images collection error");
                console.log(err);
            });
    }

}

const fnGetImage = function (medicalRecordId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE MedicalRecordId = ? ", [medicalRecordId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .signatureImages(firebase, medicalRecordId)
            .get()
            .then((items) => {
                let signatureImages = [];
                items.forEach(doc => {
                    // console.log(doc.data())
                    if (doc) {
                        signatureImages.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
                    }
                });
                if (fnSuccessCallback) {
                    fnSuccessCallback(signatureImages);
                }
            })
            .catch(err => {
                console.log("prescription signature images collection error");
                console.log(err);
            });
    }

}

export {
    fnInsert,
    fnGetImage
}