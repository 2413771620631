const tables = [{
    name: "Doctors", props: [
        { name: "ConsultantId", type: "TEXT", primaryKey: true },
        { name: "RegistrationNumber", type: "TEXT", isUpdatable: true },
        { name: "Name", type: "TEXT", isUpdatable: true },
        { name: "EmailId", type: "TEXT" },
        { name: "Phone", type: "TEXT", isUpdatable: true },
        { name: "Address", type: "TEXT", isUpdatable: true },
        { name: "Degree", type: "TEXT", isUpdatable: true },
        { name: "Speciality", type: "TEXT", isUpdatable: true },
        { name: "UniqueName", type: "TEXT" },
        { name: "MailSignature", type: "TEXT" },
        { name: "LogoUrlDevice", type: "TEXT" },
        { name: "SignatureImageUrl ", type: "TEXT" },
        { name: "LogoUrlServer", type: "TEXT" },
        { name: "LogoServerLocation", type: "TEXT" },
        { name: "ClinicName", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "ClinicTagline1", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "ClinicTagline2", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "ClinicEmail", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "ClinicPhone", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "ClinicWebsite", type: "TEXT", isUpdatable: true, isAlterTable: true, },
        { name: "DoctorUID", type: "TEXT" },
        { name: "CreatedDate", type: "TEXT" }
    ]
}, {
    name: "WorkSchedules", props: [
        { name: "WorkScheduleId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "Name", type: "TEXT", isUpdatable: true },
        { name: "StartTime", type: "TEXT", isUpdatable: true },
        { name: "EndTime", type: "TEXT", isUpdatable: true },

        { name: "CreatedOn", type: "TEXT" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },
    ]
}, {
    name: "DoctorLogos", props: [
        { name: "DoctorLogoId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "FileName", type: "TEXT" },
        { name: "DeviceUrl", type: "TEXT", isUpdatable: true },
        { name: "DownloadUrl", type: "TEXT", isUpdatable: true },
        { name: "ServerLocation", type: "TEXT", isUpdatable: true },
        { name: "CreatedDate", type: "TEXT" }
    ]
}, {
    name: "Patients", props: [
        { name: "PatientId", type: "TEXT", primaryKey: true },
        { name: "PatientFirebaseUID", type: "TEXT", isUpdatable: true },
        { name: "Name", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "DateOfBirth", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "Age", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "Gender", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "Address", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "ExtSystemCode", type: "TEXT", isUpdatable: true },
        { name: "Email", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "Phone", type: "TEXT", isUpdatable: true, isEncrypted: true },
        { name: "PhoneHash", type: "TEXT", isUpdatable: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "IsAccountCreated", type: "NUMBER", isUpdatable: true },
        { name: "IsNewPatient", type: "NUMBER", isUpdatable: true, isAlterTable: true },

        { name: "PatientUID", type: "TEXT" },
        { name: "CreatedDate", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },
        { name: "CreatedPlatform", type: "TEXT" },
        { name: "IsDownloadSyncPendingDoctor", type: "NUMBER", isUpdatable: true },
        { name: "IsDownloadSyncPendingReception", type: "NUMBER", isUpdatable: true },

    ]
}, {
    name: "PatientNames", props: [
        { name: "PatientNameId", type: "TEXT", primaryKey: true },
        { name: "Name", type: "TEXT", isUpdatable: true },
        { name: "FirstName", type: "NUMBER", isUpdatable: true },
        { name: "LastName", type: "TEXT", isUpdatable: true },

    ]
}, {
    name: "PatientPhones", props: [
        { name: "PatientPhoneId", type: "TEXT", primaryKey: true },
        { name: "Phone", type: "TEXT", isUpdatable: true },


    ]
}, {
    name: "PatientEmailIds", props: [
        { name: "PatientEmailId", type: "TEXT", primaryKey: true },
        { name: "Email", type: "TEXT", isUpdatable: true },


    ]
}, {
    name: "MedicalRecords", props: [
        { name: "MedicalRecordId", type: "TEXT", primaryKey: true },
        { name: "PatientId", type: "TEXT" },
        { name: "PatientName", type: "TEXT" },
        { name: "PatientAge", type: "TEXT" },
        { name: "PatientGender", type: "TEXT" },
        { name: "PatientAddress", type: "TEXT" },
        { name: "DoctorId", type: "TEXT" },
        { name: "DoctorName", type: "TEXT" },
        { name: "DoctorRegistrationNumber", type: "TEXT" },
        { name: "DoctorSpeciality", type: "TEXT" },
        { name: "DoctorDegree", type: "TEXT" },
        { name: "DoctorPhone", type: "TEXT" },
        { name: "DoctorEmail", type: "TEXT" },
        { name: "DoctorAddress", type: "TEXT" },
        { name: "Investigations", type: "TEXT" },
        { name: "Diagnosis", type: "TEXT" },
        { name: "VisibleNotes", type: "TEXT" },
        { name: "Medicines", type: "TEXT", isJSONArray: true },
        { name: "PrescriptionPadDetails", type: "TEXT", isJSONArray: true },
        { name: "SignatureDetails", type: "TEXT", isJSONArray: true },
        { name: "Advices", type: "TEXT" },
        { name: "Status", type: "TEXT" },
        { name: "Date", type: "TEXT" },
        { name: "DateMs", type: "TEXT" },
        { name: "FollowUpDate", type: "TEXT" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "MedicalRecordUID", type: "TEXT" },
        { name: "PatientUID", type: "TEXT" },
        { name: "Height", type: "NUMBER" },
        { name: "Weight", type: "NUMBER" },
        { name: "PulseRate", type: "NUMBER" },
        { name: "Temperature", type: "NUMBER" }
    ]
},
{
    name: "Appointments", props: [
        { name: "AppointmentId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "PatientId", type: "TEXT" },
        { name: "PatientName", type: "TEXT" },
        { name: "PatientPhone", type: "TEXT" },
        { name: "PatientEmail", type: "TEXT" },
        { name: "ReasonForAppointment", type: "TEXT" },
        { name: "WorkScheduleId", type: "TEXT" },
        { name: "RequestedWorkScheduleId", type: "TEXT" },
        { name: "AppointmentType", type: "TEXT" },
        { name: "Status", type: "TEXT", isUpdatable: true },
        { name: "PaymentStatus", type: "NUMBER", isUpdatable: true },
        { name: "CompletedOn", type: "NUMBER" },

        { name: "Fee", type: "NUMBER" },
        { name: "Status", type: "TEXT" },
        { name: "RequestedDate", type: "TEXT" },
        { name: "Date", type: "TEXT" },
        { name: "DateMs", type: "NUMBER" },
        { name: "StartTime", type: "TEXT" },
        { name: "StartTimeMs", type: "NUMBER" },
        { name: "EndTime", type: "TEXT" },
        { name: "EndTimeMs", type: "NUMBER" },
        { name: "CreatedDate", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },
        { name: "IsDownloadSyncPendingDoctor", type: "NUMBER", isUpdatable: true },
        { name: "IsDownloadSyncPendingReception", type: "NUMBER", isUpdatable: true },
    ]
}, {
    name: "MstMedicines", props: [
        { name: "MedicineId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "SearchText", type: "TEXT", isUpdatable: true },
        { name: "FreeText", type: "TEXT", isUpdatable: true },
        { name: "Brand", type: "TEXT", isUpdatable: true },
        { name: "Generic", type: "TEXT", isUpdatable: true },
        { name: "Frequency", type: "TEXT", isUpdatable: true },
        { name: "Route", type: "TEXT", isUpdatable: true },
    ]
}, {
    name: "PrescriptionImages", props: [
        { name: "PrescriptionImageId", type: "TEXT" },
        { name: "PrescriptionId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "StorageLocation", type: "TEXT" },
        { name: "PlatformType", type: "TEXT" },
    ]
}, {
    name: "MedicalRecordImages", props: [
        { name: "MedicalRecordImageId", type: "TEXT", primaryKey: true },
        { name: "MedicalRecordId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "StorageLocation", type: "TEXT" },
        { name: "PlatformType", type: "TEXT" },
    ]
}, {
    name: "PrescriptionSignatureImages", props: [
        { name: "PrescriptionSignatureImageId", type: "TEXT", primaryKey: true },
        { name: "PrescriptionId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "StorageLocationDevice", type: "TEXT" },
        { name: "StorageLocationServer", type: "TEXT" },
        { name: "PlatformType", type: "TEXT" },
    ]
}, {
    name: "SignatureImages", props: [
        { name: "SignatureImageId", type: "TEXT", primaryKey: true },
        { name: "MedicalRecordId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "StorageLocationDevice", type: "TEXT" },
        { name: "StorageLocationServer", type: "TEXT" },
        { name: "PlatformType", type: "TEXT" },
    ]
}, {
    name: "PaymentConfirmationImages", props: [
        { name: "PaymentConfirmationImageId", type: "TEXT", primaryKey: true },
        { name: "AppointmentId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "StorageLocationDevice", type: "TEXT" },
        { name: "StorageLocationServer", type: "TEXT" },
        { name: "PlatformType", type: "TEXT" },
        { name: "CreatedOn", type: "TEXT" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsSyncedImage", type: "NUMBER" },

        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },
        { name: "IsDownloadSyncPendingDoctor", type: "NUMBER", isUpdatable: true },
        { name: "IsDownloadSyncPendingReception", type: "NUMBER", isUpdatable: true },
    ]
}, {
    name: "PaymentThirdParties", props: [
        { name: "PaymentThirdPartyId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "AccountType", type: "NUMBER", isUpdatable: true },
        { name: "AccountHolderName", type: "TEXT", isUpdatable: true },
        { name: "ProviderName", type: "TEXT", isUpdatable: true },
        { name: "UPIId", type: "TEXT", isUpdatable: true },
        { name: "PhoneNumber", type: "TEXT", isUpdatable: true },
        { name: "BankAccountNumber", type: "TEXT", isUpdatable: true },
        { name: "IFSCCode", type: "TEXT", isUpdatable: true },


        { name: "CreatedOn", type: "TEXT" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },
    ]
}, {
    name: "PatientCallLogs", props: [
        { name: "CallLogId", type: "TEXT", primaryKey: true },
        { name: "PatientId", type: "TEXT" },
        { name: "CallerId", type: "TEXT" },
        { name: "CalleeId", type: "TEXT" },
        { name: "Status", type: "NUMBER", isUpdatable: true },
        { name: "Duration", type: "TEXT", isUpdatable: true },
        { name: "DateMs", type: "NUMBER" },

    ]
}, {
    name: "DoctorCallLogs", props: [
        { name: "CallLogId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "CallerId", type: "TEXT" },
        { name: "CalleeId", type: "TEXT" },
        { name: "Status", type: "NUMBER", isUpdatable: true },
        { name: "Duration", type: "TEXT", isUpdatable: true },
        { name: "DateMs", type: "NUMBER" },
        { name: "RoomId", type: "TEXT" },

    ]
}, {
    name: "Chats", props: [
        { name: "ChatId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "PatientId", type: "TEXT" },
        { name: "Status", type: "NUMBER", isUpdatable: true },
        { name: "IsNewMessage", type: "NUMBER" },

        { name: "CreatedDate", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },

        { name: "IsDownloadSyncPendingDoctor", type: "NUMBER", isUpdatable: true },
        { name: "ServerTimestamp", type: "TEXT", isUpdatable: true }


    ]
}, {
    name: "ChatMessages", props: [
        { name: "ChatMessageId", type: "TEXT", primaryKey: true },
        { name: "ChatId", type: "TEXT" },
        { name: "DoctorId", type: "TEXT" },
        { name: "PatientId", type: "TEXT" },
        { name: "Message", type: "TEXT" },
        { name: "SentBy", type: "NUMBER" },
        { name: "CreatedDate", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsSynced", type: "NUMBER" },
        { name: "IsUpdateSyncPending", type: "NUMBER", isUpdatable: true },
        { name: "SyncedOn", type: "TEXT" },
        { name: "IsActive", type: "NUMBER" },

        { name: "IsDownloadSyncPendingDoctor", type: "NUMBER", isUpdatable: true },
        { name: "ServerTimestamp", type: "TEXT", isUpdatable: true }


    ]
}, {
    name: "SharedFiles", props: [
        { name: "FileId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "PatientId", type: "TEXT" },
        { name: "Name", type: "TEXT" },
        { name: "Type", type: "TEXT" },
        { name: "Description", type: "TEXT" },
        { name: "Folder", type: "TEXT" },
        { name: "UploadedById", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsActive", type: "NUMBER" },

    ]
}, {
    name: "FCMessages", props: [
        { name: "FCMessageId", type: "TEXT", primaryKey: true },
        { name: "DoctorId", type: "TEXT" },
        { name: "AppInstanceId", type: "TEXT" },
        { name: "Token", type: "TEXT", isUpdatable: true },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "IsActive", type: "NUMBER" },

    ]
}, {
    name: "Orders", props: [
        { name: "OrderId", type: "TEXT", primaryKey: true },
        { name: "ItemId", type: "TEXT" },
        { name: "ItemType", type: "TEXT" },
        { name: "Description", type: "TEXT" },
        { name: "Amount", type: "TEXT" },
        { name: "PaymentStatus", type: "TEXT" },
        { name: "TransactionId", type: "TEXT" },
        { name: "CustomerId", type: "TEXT" },
        { name: "MerchantId", type: "TEXT" },
        { name: "CreatedDate", type: "TEXT" },
        { name: "CreatedDateMs", type: "NUMBER" },
        { name: "CreatedPlatform", type: "TEXT" },
        { name: "ServerTimestamp", type: "TEXT" }

    ]
}]


export {
    tables
}