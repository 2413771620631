<template>
  <f7-page>
    <f7-navbar>
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        href="/patient-view/"
      ></f7-link>
      <f7-nav-title v-if="IsEdit == 'false'">Create New Patient</f7-nav-title>
      <f7-nav-title v-else>Update Patient Data</f7-nav-title>
    </f7-navbar>
    <div class="h-100 overflow-y-auto">
      <f7-block-title>Patient Information</f7-block-title>
      <form id="create-patient-form">
        <f7-list no-hairlines>
          <f7-list-input
            label="Full Name"
            type="text"
            required
            outline
            validate
            :value="PatientForm.Name"
            @input="PatientForm.Name = $event.target.value"
          ></f7-list-input>

          <!-- <f7-list-input
            label="Age"
            type="number"
            required
            outline
            validate
            :value="PatientForm.Age"
            @input="PatientForm.Age = $event.target.value"
          ></f7-list-input> -->
          <f7-list-input
            :value="PatientForm.Gender"
            required
            validate
            outline
            @input="PatientForm.Gender = $event.target.value"
            label="Gender"
            type="select"
          >
            <option value></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </f7-list-input>

          <f7-list-input
            label="Address"
            type="text"
            required
            outline
            validate
            :value="PatientForm.Address"
            @input="PatientForm.Address = $event.target.value"
            v-if="IsAnotherAccount != 'true'"
          ></f7-list-input>
          <li class="item-content date-input margin-bottom-large">
            <div class="item-inner">
              <div
                class="w-100"
                :class="PatientForm.DateOfBirth ? '' : 'item-input-invalid'"
              >
                <label class="f7-form-label item-label"
                  >Date of birth *&nbsp;</label
                >
                <div class="item-input-wrap item-input-outline">
                  <datepicker
                    :initialView="'year'"
                    v-model="PatientForm.DateOfBirth"
                  ></datepicker>
                  <div v-if="PatientForm.DateOfBirth" class="item-input-info">
                    {{ PatientAge }}
                  </div>
                  <div class="item-input-error-message">
                    Please fill in this field.
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- <f7-list-input
            label="Phone"
            type="text"
            required
            outline
            validate
            pattern="^\d{10}$"
            :value="PatientForm.Phone"
            @input="PatientForm.Phone = $event.target.value"
            v-if="IsAnotherAccount != 'true'"
          ></f7-list-input> -->
          <!-- <f7-list-input
            label="Email"
            type="email"
            outline
            :value="PatientForm.Email"
            @input="PatientForm.Email = $event.target.value"
            v-if="IsAnotherAccount != 'true'"
          ></f7-list-input> -->

          <!-- <f7-list-button
          v-if="IsEdit == 'false'"
          :class="fnValidatePatientForm()?'':'disabled'"
          title="Create"
          @click="fnCreatePatient()"
        ></f7-list-button>
        <f7-list-button
          v-else
          :class="fnValidatePatientForm()?'':'disabled'"
          title="Update"
          @click="fnUpdatePatient()"
          ></f7-list-button>-->
        </f7-list>
      </form>
      <f7-button
        :class="fnValidatePatientForm() ? '' : 'disabled'"
        class="margin-x-large margin-y-large background-primary"
        v-if="IsEdit == 'false'"
        fill
        round
        @click="fnCreatePatient()"
        >Submit</f7-button
      >
      <f7-button
        :class="fnValidatePatientForm() ? '' : 'disabled'"
        v-else
        class="margin-x-large margin-y-large background-primary"
        fill
        round
        @click="fnUpdatePatient()"
        >Save</f7-button
      >
    </div>
  </f7-page>
</template>
<style>
.vdp-datepicker input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 0px;
  padding-left: var(--f7-input-outline-padding-horizontal) !important;
  padding-right: var(--f7-input-outline-padding-horizontal) !important;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1); */
  width: 100%;
}
.date-input .item-inner:after {
  background-color: transparent !important;
}
.item-input-invalid .vdp-datepicker input {
  border: 2px solid red !important;
}
/* .grecaptcha-badge {
  display: none !important;
} */
</style>
<script>
const fireStore = require("../store/firebaseStore.js");
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";
const patientStore = require("../store/patientStore.js");
const dbUtils = require("../store/dbUtils.js");
const dateUtils = require("../shared/dateUtils.js");
const errorHandler = require("../shared/error-handler.js");
const chatsStore = require("../store/chatsStore.js");
import Datepicker from "vuejs-datepicker";

export default {
  name: "patient-create",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      PatientForm: {
        Name: "",
        Age: "",
        Gender: "",
        Address: "",
        Email: "",
        Phone: "",
        DoctorId: "",
        DateOfBirth: "",
      },
      PatientAge: "",
      elPatientForm: null,
    };
  },
  props: {
    IsEdit: null,
    IsAnotherAccount: String,
  },
  computed: {
    ...mapState(["currentUser", "__CurrentPatient", "__LoggedInUserData"]),
    patientDateOfBirth: function () {
      return this.PatientForm.DateOfBirth;
    },
  },
  watch: {
    patientDateOfBirth: function (value) {
      if (value) {
        if (typeof value == "string" && !isNaN(value)) {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(
            new Date(Number(value))
          );
        } else {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(value);
        }
      }
    },
  },
  mounted: function () {
    // console.log(this.IsEdit);

    if (this.__CurrentPatient.PatientId && this.IsAnotherAccount != "true") {
      // console.log("Edit");
      this.PatientForm = this.__CurrentPatient;
      if (this.__CurrentPatient.DateOfBirth) {
        this.PatientForm.DateOfBirth = new Date(
          Number(this.__CurrentPatient.DateOfBirth)
        );
      }
    }
    if (this.IsAnotherAccount == "true") {
      this.PatientForm.Phone = this.__LoggedInUserData.Phone;
      this.PatientForm.Address = this.__LoggedInUserData.Address;
      this.PatientForm.Email = this.__LoggedInUserData.Email;
      this.PatientForm.PatientFirebaseUID = this.__LoggedInUserData.PatientFirebaseUID;
      this.PatientForm.IsAccountCreated = 1;
    }
    // this.$f7ready(f7 => {

    this.elPatientForm = this.Dom7("#create-patient-form");
    // this.fnValidatePatientForm();
    // });
  },
  methods: {
    fnValidatePatientForm() {
      // console.log("Validate patient form");
      if (this.elPatientForm && this.elPatientForm[0]) {
        // console.log(this.elPatientForm[0].checkValidity());
        return this.elPatientForm[0].checkValidity() && this.PatientForm.DateOfBirth;
      }
    },

    fnCreatePatient() {
      let that = this;
      this.PatientForm.DoctorId = this.currentUser.uid;
      this.PatientForm.PatientId = dbUtils.generateUID();
      let patientFormCopy = Object.assign({}, this.PatientForm);
      patientFormCopy.DateOfBirth = dateUtils
        .fnGetUTCTimeFromDateObjectRemoveTime(this.PatientForm.DateOfBirth)
        .toString();
      patientStore.fnInsertPatient(
        patientFormCopy,
        function () {
          that.PatientForm.PatientId = patientFormCopy.PatientId;
          that.PatientForm.PatientUID = patientFormCopy.PatientUID;
          that.fnGetPatientByPhone();

          // that.fnCreateNewChat(null, function () {
          //   that.fnGetPatientByPhone();
          // });
          // that.$f7router.navigate("/home/1/");
        },
        function () {}
      );
    },
    fnGetPatientByPhone() {
      let that = this;
      // console.log("Get Patient");
      patientStore.fnGetPatientByPhone(
        this.currentUser.uid,
        this.PatientForm.Phone,
        function (data) {
          // console.log("Patients");
          // console.log(data);
          that.$store.commit("setPatientProfiles", data);

          errorHandler.fnRaiseSuccessWithCallback(
            "",
            "Account Created Successfully",
            function () {
              // that.$store.commit("setPatient", that.PatientForm);
              that.$f7router.navigate("/patient-view/");
            }
          );
        },
        function (err) {
          if (err == 403) {
            //Patient is not registered for the current doctor
            //Open patient registration form
            that.currentTabIndex = 3;
          } else {
            errorHandler.fnRaiseError();
            console.log(err);
          }
        }
      );
    },
    fnUpdatePatient() {
      let that = this;
      let patientFormCopy = Object.assign({}, this.PatientForm);
      patientFormCopy.DateOfBirth = dateUtils
        .fnGetUTCTimeFromDateObjectRemoveTime(this.PatientForm.DateOfBirth)
        .toString();
      patientStore.fnUpdatePatient(
        patientFormCopy,
        function () {
          errorHandler.fnRaiseSuccessWithCallback("", "", function () {
            that.$store.commit("setPatient", that.PatientForm);

            that.$f7router.navigate("/patient-view/");
          });
        },
        function () {}
      );
    },
    // fnCreateNewChat(dialog, fnSuccess) {
    //   let that = this;
    //   let chat = {
    //     ChatId: dbUtils.generateUID(),
    //     DoctorId: this.currentUser.uid,
    //     PatientId: this.PatientForm.PatientId,
    //     Status: 2,
    //   };
    //   chatsStore.fnInsertChat(
    //     chat,
    //     function () {
    //       if (dialog) {
    //         dialog.close();
    //       }
    //       if (fnSuccess) {
    //         fnSuccess();
    //       }
    //       // that.fnGoToChatMessages(chat);
    //     },
    //     function () {
    //       if (dialog) {
    //         dialog.close();
    //       }

    //       errorHandler.fnRaiseError();
    //     }
    //   );
    // },
  },
};
</script>
