<template>
  <f7-page class="f-column f-grow" name="patient-view">
    <f7-navbar>
      <f7-nav-left>
        <img
          class="margin-left-small"
          style="height: 40px; width: 40px"
          src="static/icons/favicon.png"
        />

        <span>SCRIPTY</span>
      </f7-nav-left>
      <!-- <div slot="right"></div> -->
      <f7-button
        fill
        small
        color="green"
        class="margin-right-small"
        panel-open="#panel-account"
        slot="right"
      >
        <span class="f-row f-vertical-center" style="color: white">
          <f7-icon
            size="18"
            class="margin-right-xsmall"
            material="settings"
          ></f7-icon>
        </span>
      </f7-button>
    </f7-navbar>
    <f7-panel
      :opened="isAccountsPanelOpen"
      @panel:closed="fnAccountsPanelClosed()"
      @panel:opened="isAccountsPanelOpen = true"
      id="panel-account"
      right
      cover
      container-el="#panel-page"
    >
      <f7-page>
        <f7-navbar title="Manage Account"></f7-navbar>
        <div>
          <f7-list no-hairlines>
            <f7-list-item link="#" @click="fnSwitchAccount()">
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="18"
                  class="margin-right-xsmall"
                  material="account_circle"
                ></f7-icon
                >Switch Profile
              </span>
            </f7-list-item>
            <f7-list-item link="#" @click="fnLogout()">
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="18"
                  class="margin-right-xsmall"
                  material="power_settings_new"
                ></f7-icon
                >Logout
              </span>
            </f7-list-item>
          </f7-list>
        </div>
      </f7-page>
    </f7-panel>
    <div v-if="pageLoaded == true">
      <div
        class="f-row toolbar-height w-100 background-secondary border-bottom-light-gray"
      >
        <f7-button
          @click="currentTabIndex = 1"
          class="f-spread text-secondary h-100 f7-block-title-font-size f-row f-vertical-center border-radius-0 border-right-light-gray"
          v-bind:class="[
            currentTabIndex == 1
              ? 'button-active button-raised background-tertiary bottom-border-button'
              : '',
          ]"
        >
          Summary
        </f7-button>
        <f7-button
          @click="currentTabIndex = 2"
          class="f-spread text-secondary h-100 f7-block-title-font-size f-row f-vertical-center border-radius-0 border-right-light-gray"
          v-bind:class="[
            currentTabIndex == 2
              ? 'button-active button-raised background-tertiary bottom-border-button'
              : '',
          ]"
        >
          Messages
        </f7-button>

        <f7-button
          @click="currentTabIndex = 3"
          class="f-spread text-secondary h-100 f7-block-title-font-size f-row f-vertical-center border-radius-0 border-right-light-gray"
          color="white"
          v-bind:class="[
            currentTabIndex == 3
              ? 'button-active  button-raised background-tertiary bottom-border-button'
              : '',
          ]"
        >
          Prescriptions
        </f7-button>
        <f7-button
          @click="currentTabIndex = 4"
          class="f-spread text-secondary h-100 f7-block-title-font-size f-row f-vertical-center border-radius-0"
          color="white"
          v-bind:class="[
            currentTabIndex == 4
              ? 'button-active  button-raised background-tertiary bottom-border-button'
              : '',
          ]"
        >
          Files
        </f7-button>
      </div>
    </div>
    <div class="f-column f-grow">
      <!-- <f7-toolbar class="f-row f-vertical-center f-end">
        <div class="f-row" slot="after-inner">
          <f7-button
            href="/patient-create/true/"
            raised
            fill
            small
            color="white"
            class="margin-right-small"
          >
            <span class="f-row f-vertical-center">
              <f7-icon
                size="18"
                class="margin-right-xsmall"
                material="edit"
              ></f7-icon
              >Edit Profile
            </span>
          </f7-button>
        </div>
      </f7-toolbar> -->
      <div v-if="currentTabIndex == 1">
        <f7-block-title class="background-secondary">
          <div class="f-row f-space-between f-vertical-center">
            <span>Doctor Profile</span>
            <f7-button
              @click="fnOpenPaymentAccountsPopup()"
              raised
              fill
              small
              color="white"
              class="text-primary"
            >
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="13"
                  class="margin-right-xsmall"
                  material="account_balance"
                ></f7-icon
                >View Payment Accounts
              </span>
            </f7-button>
          </div>
        </f7-block-title>
        <div class="margin-small" v-if="pageLoaded == true">
          <div class="f-row font-small f-space-between">
            <div class="f-column" style="max-width: 50%">
              <span class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" f7="person_alt"></f7-icon
                  >&nbsp;</span
                >

                {{ currentUser.Name }}
              </span>
              <span v-if="currentUser.Degree" class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon
                    class="list-icon"
                    material="card_membership"
                  ></f7-icon
                  >&nbsp;</span
                >

                {{ currentUser.Degree }}
              </span>
              <span
                v-if="currentUser.Speciality"
                class="f-row f-vertical-center"
              >
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="school"></f7-icon
                  >&nbsp;</span
                >

                {{ currentUser.Speciality }}
              </span>
            </div>
            <div class="f-column">
              <span class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon
                    class="list-icon"
                    material="confirmation_number"
                  ></f7-icon
                  >&nbsp;</span
                >
                {{ currentUser.RegistrationNumber }}
              </span>
              <span
                v-if="currentUser.ClinicPhone"
                class="f-row f-vertical-center"
              >
                <span class="text-secondary">
                  <f7-icon class="list-icon" f7="phone"></f7-icon>&nbsp;</span
                >
                {{ currentUser.ClinicPhone }}
              </span>
              <span
                v-if="currentUser.ClinicEmail"
                class="f-row f-vertical-center"
              >
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="email"></f7-icon
                  >&nbsp;</span
                >

                {{ currentUser.ClinicEmail }}
              </span>
            </div>
          </div>
        </div>
        <f7-block-title class="background-secondary">
          <div class="f-row f-space-between f-vertical-center">
            <span>Current Patient Profile</span>
            <f7-button
              href="/patient-create/true/"
              raised
              fill
              small
              color="white"
              class="text-primary"
            >
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="13"
                  class="margin-right-xsmall"
                  material="edit"
                ></f7-icon
                >Edit Profile
              </span>
            </f7-button>
          </div>
        </f7-block-title>
        <div class="margin-small" v-if="pageLoaded == true">
          <div class="f-row font-small f-space-between">
            <div class="f-column" style="max-width: 50%">
              <span class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" f7="person_alt"></f7-icon
                  >&nbsp;</span
                >

                {{ Patient.Name }}
              </span>
              <span v-if="Patient.Address" class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="home"></f7-icon
                  >&nbsp;</span
                >

                {{ Patient.Address }}
              </span>
              <span v-if="Patient.Phone" class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" f7="phone"></f7-icon>&nbsp;</span
                >

                {{ Patient.Phone }}
              </span>
            </div>
            <div class="f-column">
              <span class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="schedule"></f7-icon
                  >&nbsp;</span
                >

                {{ PatientAge }}
              </span>
              <span class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="wc"></f7-icon
                  >&nbsp;</span
                >
                {{ Patient.Gender }}
              </span>
              <span v-if="Patient.Email" class="f-row f-vertical-center">
                <span class="text-secondary">
                  <f7-icon class="list-icon" material="email"></f7-icon
                  >&nbsp;</span
                >

                {{ Patient.Email }}
              </span>
            </div>
          </div>
        </div>
        <div class="margin-small f-row f-space-between"></div>
        <f7-block-title class="background-secondary">
          <div class="f-row f-space-between f-vertical-center">
            <div class="f-row f-vertical-center">
              Next Appointment
              <f7-button
                small
                popover-open=".popover-appointments"
                class="f-row f-vertical-center text-cadetblue popover-open"
              >
                <span class="f-row f-vertical-center">
                  <f7-icon size="20" f7="info_circle"></f7-icon>
                </span>
              </f7-button>
            </div>
            <f7-button
              v-if="nextAppointments.length == 0"
              raised
              fill
              small
              color="white"
              class="text-primary"
              @click="fnGoToView('/appointment-request/')"
            >
              <span class="f-row f-vertical-center">
                <f7-icon
                  size="13"
                  class="margin-right-xsmall"
                  material="sms"
                ></f7-icon
                >Request Appointment
              </span>
            </f7-button>
          </div>
        </f7-block-title>
        <div v-if="nextAppointments.length>0">
          <div>
            <f7-list no-hairlines>
              <f7-list-item v-for="(nextAppointment,index) in nextAppointments" :key="index">
                <div class="w-100">
                  <div class="f-row f-space-between f-vertical-center w-100">
                    <div class="f-column">
                      <div class="f-row">
                        <span class="text-secondary">Date:&nbsp;</span>
                        <span>{{
                          fnFormatDate(nextAppointment.StartTimeMs)
                        }}</span>
                      </div>

                      <div
                        class="f-row"
                        v-if="nextAppointment.Status != 'Request'"
                      >
                        <span class="text-secondary">Time:&nbsp;</span>

                        <span>{{
                          fnFormatStartTimeMs(nextAppointment.StartTimeMs)
                        }}</span
                        >-
                        <span>{{
                          fnFormatEndTimeMs(nextAppointment.EndTimeMs)
                        }}</span>
                      </div>
                    </div>
                    <div class="f-column">
                      <div class="f-row">
                        <span class="text-secondary">Status:&nbsp;</span>
                        <span>{{ nextAppointment.Status }}</span>
                      </div>
                      <div
                        class="f-row"
                        v-if="nextAppointment.Status != 'Request'"
                      >
                        <span class="text-secondary">Type:&nbsp;</span>
                        <span v-if="nextAppointment.AppointmentType == 1"
                          >Clinic</span
                        >
                        <span v-if="nextAppointment.AppointmentType == 2"
                          >Audio/Video Call</span
                        >
                        <span v-if="nextAppointment.AppointmentType == 3"
                          >Chat</span
                        >
                      </div>
                      <!-- <div
                        class="f-row"
                        v-if="nextAppointment.Status != 'Request'"
                      >
                        <span class="text-secondary">Fee:&nbsp;</span>
                        <span>{{ nextAppointment.Fee }}&nbsp;INR</span>
                      </div>
                      <div
                        class="f-row"
                        v-if="nextAppointment.Status != 'Request'"
                      >
                        <span class="text-secondary"
                          >Payment Status:&nbsp;</span
                        >
                        <span v-if="nextAppointment.PaymentStatus == 0"
                          >Pending</span
                        >
                        <span v-if="nextAppointment.PaymentStatus == 1"
                          >Paid</span
                        >
                        <span v-if="nextAppointment.PaymentStatus == 2"
                          >Confirmed</span
                        >
                        <span v-if="nextAppointment.PaymentStatus == 3"
                          >NA</span
                        >
                      </div> -->
                    </div>
                  </div>
                  <div
                    class="f-row w-100"
                    v-if="nextAppointment.ReasonForAppointment"
                  >
                    <span class="text-secondary">Reason:&nbsp;</span>
                    <span>{{ nextAppointment.ReasonForAppointment }}</span>
                  </div>
                  <div
                    class="w-100 f-row"
                    v-if="nextAppointment.Status == 'Request'"
                  >
                    <span class="text-primary font-small"
                      >* Please wait for doctor to confirm your appointment.
                      Appointment Status, Fees and Time will be updated once
                      doctor confirms your appointment</span
                    >
                  </div>
                  <div class="f-row f-end margin-top-xsmall">
                    <div>
                      <f7-button
                        v-if="
                          nextAppointment.PaymentStatus == 0 &&
                          nextAppointment.Status != 'Request' &&
                          nextAppointment.Status != 'Cancelled'
                        "
                        @click="fnGoToPaymentConfirmation"
                        raised
                        fill
                        small
                        color="white"
                        class="btn-primary"
                      >
                        Pay and confirm
                      </f7-button>
                      <f7-button
                        v-if="
                          nextAppointment.PaymentStatus == 1 &&
                          nextAppointment.Status != 'Request' &&
                          nextAppointment.Status != 'Cancelled'
                        "
                        @click="fnGoToPaymentConfirmation"
                        raised
                        fill
                        small
                        color="white"
                        class="btn-primary"
                      >
                        View Payment Confirmation Image
                      </f7-button>
                      <f7-button
                        v-if="nextAppointment.Status == 'Request'"
                        class="btn-primary margin-left-small"
                        small
                        raised
                        fill
                        color="white"
                        @click="fnOpenAppointmentCancelPrompt(nextAppointment)"
                      >
                        Cancel
                      </f7-button>
                    </div>
                  </div>
                </div>
              </f7-list-item>

              <!-- <f7-list-item
              v-if="
                nextAppointment.PaymentStatus == 0 &&
                nextAppointment.Status != 'Request'
              "
            >
              <div class="w-100 f-row">
                <div class="f-spread f-center">
                  <f7-button
                    @click="fnGoToPaymentConfirmation"
                    raised
                    fill
                    color="white"
                    class="btn-primary"
                  >
                    Pay and confirm
                  </f7-button>
                </div>
              </div>
            </f7-list-item> -->
            </f7-list>
          </div>
        </div>
        <div v-else>
          <f7-list no-hairlines>
            <f7-list-item>No appointments found</f7-list-item>
            <!-- <f7-list-item
            ><div class="w-100 f-row">
              <div class="f-spread f-end">
                <f7-button
                  @click="fnGoToView('/appointment-request/')"
                  raised
                  fill
                  color="white"
                  class="btn-primary"
                >
                  Request Appointment
                </f7-button>
              </div>
            </div></f7-list-item
          > -->
          </f7-list>
        </div>
      </div>
      <!-- <div v-if="pageLoaded == true"> -->
      <chat-messages v-if="currentTabIndex == 2"></chat-messages>
      <medical-record-list v-if="currentTabIndex == 3"></medical-record-list>
      <shared-files v-if="currentTabIndex == 4"></shared-files>
      <!-- </div> -->
    </div>
    <div class="dialog dialog-notifications">
      <div class="dialog-inner">
        <div class="dialog-title">Stay Connected with your doctor</div>
        <div class="font-small margin-top-small" style="color: black">
          <div v-if="isEmailDialog == true">
            <div class="font-bold">Via Email</div>
            <div class="text-secondary">
              When asked, you can provide your email id and stay connected with
              your doctor by receiving updates for appointments, medical
              records, chats and more.
            </div>
          </div>
          <div class="margin-top-small" v-if="isNotificationDialog == true">
            <div class="font-bold">Via Browser Notifications</div>
            <div class="text-secondary">
              In supported browsers, a permission message will appear near the
              browser url bar. Allowing will enable you to receive updates for
              appointments, medical records, chats and more, directly on your
              device.
            </div>
          </div>
        </div>
        <div class="font-bold font-small margin-top-small">
          Please grant the permissions and provide an email id on the next
          screen if asked for.
        </div>
        <div class="dialog-buttons f-row f-center">
          <span class="dialog-button" @click="fnProceedToRequestPermissions()"
            >Proceed</span
          >
        </div>
      </div>
    </div>
    <div class="dialog update-email-dialog">
      <div class="dialog-inner">
        <div class="dialog-title">Doctor Updates</div>
        <div class="font-small text-secondary margin-top-small">
          Please provide an email id to receive updates from doctor
        </div>
        <form id="update-email-form">
          <f7-list no-hairlines>
            <f7-list-input
              class="expanded-item-content"
              label="Email id"
              type="email"
              outline
              validate
              placeholder=""
              :value="patientEmail"
              @input="patientEmail = $event.target.value"
            ></f7-list-input>
          </f7-list>
        </form>
      </div>

      <div class="dialog-buttons">
        <span class="dialog-button" @click="fnCloseEmailDialog()">Cancel</span>

        <span
          :class="
            fnValidatePatientForm() == false || patientEmail == ''
              ? 'disabled'
              : ''
          "
          class="dialog-button dialog-button-bold"
          @click="fnUpdatePatientEmail()"
          >Submit</span
        >
      </div>
    </div>

    <f7-popover class="popover-appointments">
      <div class="block overflow-y-auto">
        <div class="f-row f-end">
          <span class="f-row text-cadetblue f-vertical-center">
            <f7-icon size="20" f7="info_circle"></f7-icon>
          </span>
        </div>
        <div>Appointments & Payments</div>
        <p>
          <span class="text-primary">Appointment Creation</span><br />
          Doctor can create an appointment and patient can request an
          appointment. If doctor creates the appointment the status is
          <span class="text-primary">Pending Payment</span> in case the
          appointment has fees or <span class="text-primary">Booked</span> in
          case the appointment has zero fees. If patient requests an appointment
          the status is <span class="text-primary">Request</span>.<br />
          The payment status is <span class="text-primary">Pending</span> in
          case of appointment with fees and
          <span class="text-primary">NA</span> in case of appointment with zero
          fees.
        </p>
        <p>
          <span class="text-primary">Patient Payment</span><br />
          In case of paid appointment the patient can transfer money directly to
          doctor's account and upload the receipt. This action changes the
          appointment status to <span class="text-primary">Booked</span> and
          payment status to <span class="text-primary">Paid</span>.
        </p>
        <p>
          <span class="text-primary">Payment Confirmation</span><br />
          Doctor can view the receipt uploaded by patient and change the payment
          status to <span class="text-primary">Confirmed</span>.
        </p>
        <p>
          <span class="text-primary">Closing Appointment</span><br />
          Appointments can be closed by changing the status to
          <span class="text-primary">Done</span> or by changing the status to
          <span class="text-primary">Cancelled</span>.
        </p>
      </div>
    </f7-popover>
    <!-- <f7-actions ref="confirmAppointmentCancelActions">
      <f7-actions-group>
        <f7-actions-label>
          <div class="f-column" v-if="nextAppointment">
            <span> Mark appointment as cancelled?</span>
            <div class="f-row f-wrap font-small">
              {{ nextAppointment.PatientName }}
              <span class="text-secondary">,&nbsp;Time:&nbsp;</span>

              <span>{{ fnFormatStartTimeMs(nextAppointment.StartTimeMs) }}</span
              >-
              <span>{{ fnFormatEndTimeMs(nextAppointment.EndTimeMs) }}</span>
            </div>
          </div>
        </f7-actions-label>
        <f7-actions-button @click="fnUpdateAppointmentAsCancelled()"
          >Yes</f7-actions-button
        >
        <f7-actions-button @click="fnCloseAppointmentCancelPrompt()"
          >Cancel</f7-actions-button
        >
      </f7-actions-group>
    </f7-actions> -->
    <f7-popup :opened="isPatientProfilePopup" :close-by-backdrop-click="false" :close-on-escape="false">
      <f7-page>
        <f7-navbar title="Choose profile"> </f7-navbar>
        <f7-list no-hairlines>
          <f7-list-item
            v-for="PatientProfile in __PatientProfiles"
            :key="PatientProfile.PatientId"
            link="#"
            @click="fnSelectPatientProfile(PatientProfile)"
          >
            <div slot="media" class="avatar-circle">
              <span class="initials">{{
                fnGetFirstLetter(PatientProfile.Name)
              }}</span>
            </div>
            <div class="w-100 f-row f-vertical-center f-space-between">
              <div>
                <div>{{ PatientProfile.Name }}</div>
                <div class="font-xsmall text-secondary">
                  {{ fnGetPatientAge(PatientProfile.DateOfBirth) }}/{{
                    PatientProfile.Gender
                  }}
                </div>
                <div class="font-xsmall text-secondary">
                  {{ PatientProfile.Phone }}
                </div>
              </div>
            </div>
          </f7-list-item>
        </f7-list>
        <div class="f-row f-center">
          <f7-button
            @click="fnGoToPatientRegisterView()"
            raised
            fill
            color="white"
          >
            <span class="f-row f-vertical-center text-primary">
              <f7-icon
                size="18"
                class="margin-right-xsmall"
                material="add"
              ></f7-icon
              >Add Another Profile
            </span>
          </f7-button>
        </div>
      </f7-page>
    </f7-popup>
    <f7-popup :opened="isDoctorPaymentsAccount">
      <f7-page>
        <f7-navbar title="Doctor Accounts">
          <f7-link
            slot="right"
            :icon-only="true"
            icon-f7="multiply"
            @click="isDoctorPaymentsAccount = false"
          ></f7-link>
        </f7-navbar>
        <doctor-payment-accounts></doctor-payment-accounts>
      </f7-page>
    </f7-popup>
    <f7-popup :opened="isUpdateDOBPopup">
      <f7-page>
        <f7-navbar title="Date of birth"> </f7-navbar>
        <div>
          <div
            class="font-small f-row f-center text-secondary margin-top-small"
          >
            Please provide date of birth for the profile - {{ Patient.Name }}
          </div>
          <form id="update-dob-form">
            <f7-list no-hairlines>
              <li class="item-content date-input margin-bottom-large">
                <div class="item-inner">
                  <div
                    class="w-100"
                    :class="Patient.DateOfBirth ? '' : 'item-input-invalid'"
                  >
                    <label class="f7-form-label item-label"
                      >Date of birth *&nbsp;</label
                    >
                    <div class="item-input-wrap item-input-outline">
                      <datepicker
                        :initialView="'year'"
                        v-model="Patient.DateOfBirth"
                      ></datepicker>
                      <div v-if="Patient.DateOfBirth" class="item-input-info">
                        {{ PatientAge }}
                      </div>
                      <div class="item-input-error-message">
                        Please fill in this field.
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </f7-list>
          </form>
          <div class="f-row f-end margin-right-small">
            <f7-button
              raised
              :class="
                Patient.DateOfBirth
                  ? 'text-white background-primary'
                  : 'disabled text-primary background-white'
              "
              @click="fnUpdatePatientDOB()"
            >
              Submit
            </f7-button>
          </div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style>
.vdp-datepicker input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 0px;
  padding-left: var(--f7-input-outline-padding-horizontal) !important;
  padding-right: var(--f7-input-outline-padding-horizontal) !important;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1); */
  width: 100%;
}
.date-input .item-inner:after {
  background-color: transparent !important;
}
.item-input-invalid .vdp-datepicker input {
  border: 2px solid red !important;
}
.offset-calendar {
  position: relative;
  right: 0;
  left: -50px;
}

.offset-height {
  max-height: calc(
    100% - var(--f7-page-toolbar-bottom-offset, 0px) -
      var(--f7-safe-area-bottom) +
      var(--f7-page-content-extra-padding-bottom, 0px) - 24px
  );
}
.offset-height-search {
  max-height: calc(
    100% - var(--f7-page-toolbar-bottom-offset, 0px) -
      var(--f7-safe-area-bottom) +
      var(--f7-page-content-extra-padding-bottom, 0px) - 124px
  );
}
.block-height {
  min-height: 16px;
}
.bottom-border-button {
  border-bottom: 1px solid var(--b7-text-color-primary);
  border-radius: inherit;
}
.border-radius-0 {
  border-radius: 0;
}
.border-bottom-light-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.border-right-light-gray {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
<script>
import MedicalRecordList from "../components/medical-record-list.vue";
import VideoAudioCall from "../components/video-audio-call.vue";
import { mapState } from "vuex";
const dateUtils = require("../shared/dateUtils.js");
const dbUtils = require("../store/dbUtils.js");
const patientStore = require("../store/patientStore.js");
const appointmentsStore = require("../store/appointmentsStore.js");
const patientCallLogStore = require("../store/patientCallLogStore.js");
const errorHandler = require("../shared/error-handler.js");
import EventBus from "../shared/eventBus.js";
import ChatMessages from "../components/chat-messages.vue";
import SharedFiles from "../components/shared-files.vue";
import Datepicker from "vuejs-datepicker";

import firebase from "firebase/app";

import "firebase/messaging";

import moment from "moment";
import DoctorPaymentAccounts from "./doctor-payment-accounts.vue";
const fcmStore = require("../store/fcmStore.js");

export default {
  name: "patient-view",
  components: {
    MedicalRecordList,
    VideoAudioCall,
    ChatMessages,
    SharedFiles,
    DoctorPaymentAccounts,
    Datepicker,
  },
  props: {
    viewTab: String,
    prevPage: "",
    patientId: "",
  },
  data: function () {
    return {
      patientEmail: "",
      updateEmailDialog: null,
      pageLoaded: false,
      Patient: {},
      pageTitle: "",
      // nextAppointment: null,
      nextAppointments: [],
      roomId: "",
      callLogId: "",
      callUnsubscribe: null,
      currentTabIndex: 1,
      isPatientProfilePopup: false,
      isAccountsPanelOpen: false,
      isLogout: false,
      permissionsDialog: null,
      isEmailDialog: false,
      updateDOBDialog: null,
      isUpdateDOBPopup: false,
      isNotificationDialog: false,
      isDoctorPaymentsAccount: false,
      PatientAge: "",
    };
  },
  computed: {
    ...mapState([
      "__CurrentPatient",
      "currentUser",
      "__PatientProfiles",
      "__LoggedInUserData",
    ]),
    patientDateOfBirth: function () {
      return this.Patient.DateOfBirth;
    },
  },
  watch: {
    patientDateOfBirth: function (value) {
      if (value) {
        if (typeof value == "string" && !isNaN(value)) {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(
            new Date(Number(value))
          );
        } else {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(value);
        }
      }
    },
  },
  mounted: function () {
    this.permissionsDialog = f7App.dialog.create({
      el: ".dialog-notifications",
    });
    this.elUpdateEmailForm = this.Dom7("#update-email-form");

    this.updateEmailDialog = f7App.dialog.create({
      el: ".update-email-dialog",
    });

    this.updateDOBDialog = f7App.dialog.create({
      el: ".update-dob-dialog",
    });
    if (this.viewTab) {
      this.currentTabIndex = this.viewTab;
    }
    //If current patient is selected, already user has logged in, so don't check permissions
    if (this.__CurrentPatient && this.__CurrentPatient.PatientId) {
      this.fnInitData();
    } else {
      this.fnDeterminePermissionsDialog();
    }
  },

  methods: {
    fnGetPatientAge(value) {
      if (value) {
        if (typeof value == "string" && !isNaN(value)) {
          return dateUtils.fnGetAgeFromDateOfBirth(new Date(Number(value)));
        } else {
          return dateUtils.fnGetAgeFromDateOfBirth(value);
        }
      }
    },
    fnOpenPaymentAccountsPopup() {
      console.log("Payments account");
      this.isDoctorPaymentsAccount = true;
    },
    fnDeterminePermissionsDialog() {
      if (
        firebase.messaging.isSupported() &&
        Notification.permission == "default"
      ) {
        this.isNotificationDialog = true;
      }
      if (
        this.__LoggedInUserData.Email == null ||
        this.__LoggedInUserData.Email == ""
      ) {
        this.isEmailDialog = true;
      }
      if (this.isNotificationDialog == true || this.isEmailDialog == true) {
        this.permissionsDialog.open();
      } else {
        this.fnInitData();
      }
    },
    fnProceedToRequestPermissions() {
      this.permissionsDialog.close();

      this.fnInitData();
    },
    fnInitData() {
      // console.log("Current Patient", this.__CurrentPatient);

      if (this.__CurrentPatient && this.__CurrentPatient.PatientId) {
        this.fnInitPatientProfile();
      } else {
        if (
          this.__LoggedInUserData.Email == null ||
          this.__LoggedInUserData.Email == ""
        ) {
          this.fnOpenEmailPopup();
        }
        this.isPatientProfilePopup = true;
      }
    },
    fnValidatePatientForm() {
      // console.log("Validate patient form");
      if (this.elUpdateEmailForm && this.elUpdateEmailForm[0]) {
        // console.log(this.elPatientForm[0].checkValidity());
        return this.elUpdateEmailForm[0].checkValidity();
      }
    },
    fnAccountsPanelClosed() {
      this.isAccountsPanelOpen = false;
      if (this.isLogout == true) {
        this.$f7router.navigate("/" + this.currentUser.UniqueName + "/");
      }
    },
    fnUpdatePatientEmail() {
      this.__PatientProfiles.forEach((patient) => {
        this._fnUpdatePatientEmail(patient);
      });
      this.fnCloseEmailDialog();
    },
    fnUpdatePatientDOB() {
      let that = this;

      patientStore.fnUpdatePatient(
        {
          PatientId: this.Patient.PatientId,
          DateOfBirth: dateUtils
            .fnGetUTCTimeFromDateObjectRemoveTime(this.Patient.DateOfBirth)
            .toString(),
        },
        function () {
          that.$store.commit("setPatient", that.Patient);

          that.fnCloseDateOfBirthDialog();
          errorHandler.fnRaiseSuccess();
        },
        function () {
          errorHandler.fnRaiseError();
        }
      );
    },
    async _fnUpdatePatientEmail(patient) {
      let that = this;
      await new Promise((resolve, reject) => {
        patientStore.fnUpdatePatient(
          {
            PatientId: patient.PatientId,
            Email: that.patientEmail,
          },
          function () {
            patient.Email = that.patientEmail;
            resolve();
          },
          function () {
            reject();
          }
        );
      });
    },
    fnCloseEmailDialog() {
      if (this.updateEmailDialog) {
        this.updateEmailDialog.close();
      }
      // this.patientEmail = "";
    },
    fnOpenEmailPopup() {
      if (this.updateEmailDialog) {
        this.updateEmailDialog.open();
      }
    },
    fnCloseDateOfBirthDialog() {
      this.isUpdateDOBPopup = false;

      // if (this.updateDOBDialog) {
      //   this.updateDOBDialog.close();
      // }
      // this.patientEmail = "";
    },
    fnOpenDateOfBirthPopup() {
      this.isUpdateDOBPopup = true;
      // if (this.updateDOBDialog) {
      //   this.updateDOBDialog.open();
      // }
    },
    fnGoToPatientRegisterView() {
      this.isPatientProfilePopup = false;
      this.$f7router.navigate("/patient-create/false/true/");
    },
    fnSwitchAccount() {
      this.isAccountsPanelOpen = false;
      this.isPatientProfilePopup = true;
      this.$store.commit("setPatient", {});
    },
    fnGetFirstLetter(text) {
      if (text && text.length > 0) {
        return text[0];
      }
      return "";
    },
    fnSelectPatientProfile(profile) {
      let PatientForm = profile;

      // this.isPatientAccountCreated = utils.fnConvertNumberToBool(
      //   PatientForm.IsAccountCreated
      // );

      this.$store.commit("setPatient", PatientForm);
      this.currentTabIndex = 1;
      this.fnInitPatientProfile();
    },
    fnInitPatientProfile() {
      let that = this;
      if (this.prevPage != "appointment-request") {
        console.log("Init Firebase Messaging");
        that.fnInitFirebaseMessaging();
      }
      // console.log("this.prevPage");
      // console.log(this.prevPage);
      // console.log(this.currentUser);
      // console.log(this.__CurrentPatient);
      this.pageTitle = "Welcome " + this.__CurrentPatient.Name;
      this.Patient = this.__CurrentPatient;
      if (!this.__CurrentPatient.DateOfBirth) {
        this.fnOpenDateOfBirthPopup();
      }
      // console.log("Patient", this.Patient);
      this.isPatientProfilePopup = false;

      this.pageLoaded = true;
      this.fnGetNextAppointment();
      if (this.callUnsubscribe) {
        this.callUnsubscribe();
      }

      this.callUnsubscribe = patientCallLogStore.fnSubscribeToIncomingCalls(
        this.Patient.PatientId,
        function (items) {
          if (items && items.length > 0) {
            console.log(items);
            // alert("Incoming Call");
            if (!that.roomId) {
              window.audioCallFile.play();

              // console.log("ROom", items[0]);
              console.log("Call Received");

              EventBus.$emit("place-video-call", {
                roomId: items[0].RoomId,
                callLogId: items[0].CallLogId,
              });
              // that.callLogId = items[0].CallLogId;

              // that.roomId = items[0].RoomId;
            }
          }
        }
      );
    },
    fnFormatDate(dateMs) {
      return moment(new Date(dateMs)).format("DD-MMM-YYYY");
    },
    fnFormatStartTimeMs(timeMs) {
      return moment(new Date(timeMs)).format("h:mm");
    },
    fnFormatEndTimeMs(timeMs) {
      return moment(new Date(timeMs)).format("h:mm a");
    },
    fnGetNextAppointment() {
      // this.nextAppointment = null;
      this.nextAppointments = [];
      let that = this;
      let dateMs = dateUtils.fnGetUTCTimeFromDateObjectRemoveTime(new Date());
      // console.log("App date Ms", dateMs);
      appointmentsStore.fnGetNextAppointment(
        this.currentUser.uid,
        this.__CurrentPatient.PatientId,
        dateMs,
        function (items) {
          let nextAppointments = [];
          // console.log("Appointments", items);
          if (items && items.length > 0) {
            items.forEach((i) => {
              nextAppointments.push(i);
            });
          }
          that.nextAppointments = nextAppointments;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    fnOpenAppointmentCancelPrompt() {
      this.$refs.confirmAppointmentCancelActions.open();
    },
    fnCloseAppointmentCancelPrompt() {
      this.$refs.confirmAppointmentCancelActions.close();
    },
    // fnUpdateAppointmentAsCancelled() {
    //   let that = this;
    //   let item = {
    //     AppointmentId: this.nextAppointment.AppointmentId,
    //     Status: "Cancelled",
    //   };
    //   appointmentsStore.fnUpdateAppointment(
    //     item,
    //     function () {
    //       that.nextAppointment.Status = "Cancelled";
    //       that.fnCloseAppointmentCancelPrompt();
    //     },
    //     function (error) {
    //       console.log(error);
    //     }
    //   );
    // },
    // fnAttachAppointmentListener(item) {
    //   let that = this;
    //   appointmentsStore.fnAttachAppointmentListener(item, function (newItem) {
    //     // alert(newItem);
    //     that.nextAppointment = newItem;
    //   });
    // },
    // fnGetQueueStatus() {
    //   let that = this;
    //   appointmentsStore.fnGetNextAppointment(
    //     this.currentUser.uid,
    //     this.__CurrentPatient.PatientId,
    //     function (items) {
    //       if (items && items.length > 0) {
    //         that.nextAppointment = items[0];
    //       }
    //     },
    //     function (error) {
    //       console.log(error);
    //     }
    //   );
    // },
    // fnGoToPaymentConfirmation() {
    //   this.$f7router.navigate(
    //     "/payment-confirm/" + this.nextAppointment.AppointmentId + "/"
    //   );
    // },
    fnCallCompleted() {
      this.roomId = "";
      this.callLogId = "";
    },
    fnLogout() {
      let that = this;
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            that.isLogout = true;
            that.isAccountsPanelOpen = false;
          },
          function () {
            that.isLogout = true;
            that.isAccountsPanelOpen = false;
          }
        );

      // doctorStore.fnRemoveDoctorDeviceLogin(function () {
      // that.fnGoToView("/");
      // });
    },
    fnGoToView(url) {
      this.$f7router.navigate(url);
    },
    fnInitFirebaseMessaging() {
      if (firebase.messaging.isSupported()) {
        let that = this;
        const messaging = firebase.messaging();
        // const messaging = this.$firebaseMessaging;
        Notification.requestPermission().then(function (permission) {
          if (permission != "denied") {
            if ("serviceWorker" in navigator) {
              navigator.serviceWorker
                .register("./static/js/firebase-messaging-sw-v8.js")
                .then((reg) => {
                  try {
                    messaging
                      .getToken({
                        serviceWorkerRegistration: reg,
                        vapidKey:
                          "BO2rDJG65kjGJ0bjfyuiqgduYgq5ayjQReXYRKF52bQ68Bj9qazwFWwvZUkuT--aAMMPxpBDwCf8baNcg4RE9fA",
                      })
                      .then(
                        function (fcmToken) {
                          // console.log(fcmToken);
                          let isInsertToken = true;
                          fcmStore.fnGet(
                            that.__CurrentPatient.PatientId,
                            function (tokens) {
                              if (tokens && tokens.length > 0) {
                                tokens.forEach((token) => {
                                  if (token.Token == fcmToken) {
                                    isInsertToken = false;
                                  }
                                });
                              }

                              if (isInsertToken == true) {
                                console.log("inserting token");
                                fcmStore.fnInsert(
                                  {
                                    FCMessageId: dbUtils.generateUID(),
                                    PatientId: that.__CurrentPatient.PatientId,
                                    // AppInstanceId: appInstanceId,
                                    Token: fcmToken,
                                  },
                                  function () {
                                    console.log("Token Insert Success");
                                    // resolve();
                                  },
                                  function (error) {
                                    // errorHandler.fnRaiseError();

                                    console.error(error);
                                    // reject();
                                  }
                                );
                              }
                            }
                          );
                        },
                        function (error) {
                          // errorHandler.fnRaiseError();

                          console.log(error);
                          // reject();
                        }
                      );
                  } catch (err) {
                    if (err.code === "messaging/token-unsubscribe-failed")
                      fnInitFirebaseMessaging();
                  }
                })
                .catch((error) => {
                  const msg = `Service Worker Error (${error})`;
                  console.log(msg);
                });
            } else {
              // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
              console.log("Service Worker not available");
            }
          }
        });
      }
    },
  },
  beforeDestroy() {
    if (this.callUnsubscribe) {
      this.callUnsubscribe();
    }
  },
};
</script>
