const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "MstMedicines";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);

const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnInsertMstMedicine = function (medicine, fnSuccessCallback, fnFailureCallBack) {
    dbUtils.fnTrimTextOnObject(medicine, props);

    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(medicine, props) + ')';
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .mstMedicines(firebase, medicine.DoctorId)
            .doc(medicine.MedicineId)
            .set(medicine).then(function () {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
    }

}
const fnInsertMstMedicines = function (medicines, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') ';
        medicines.forEach((medicine, index) => {
            if (index == 0) {
                sql = sql + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(medicine, props) + ')';

            } else {
                sql = sql + ',(' + dbUtils.fnBuildValuesStringFromProps(medicine, props) + ')';
            }
        })
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    console.log("Medicine Success")
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        let batch = fireStore.fnGetBatch(firebase);
        let newMedicine = {};
        medicines.forEach(medicine => {
            newMedicine = fireStore
                .mstMedicines(firebase, medicine.DoctorId)
                .doc(medicine.MedicineId);
            batch.set(newMedicine, medicine);

        });

        batch.commit().then(function () {
            if (fnSuccessCallback) {
                console.log("Medicine Success")
                fnSuccessCallback();
            }
            // console.log("that.masterMeds");
            //console.log(that.masterMeds);
            // this.MstMedicines.push(medicine.model);


        });
        // that.$f7router.navigate("/patient-view/");


    }

}
const fnGetMstMedicines = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? ", [doctorId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        // console.log(doctorId)
        fireStore.mstMedicines(firebase, doctorId).get().then(function (items) {
            let medicines = [];
            items.forEach(function (doc) {
                medicines.push(doc.data());
            });
            if (fnSuccessCallback) {
                fnSuccessCallback(medicines);
            }

        }, function (error) {
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
    }

}

const fnUpdateMedicine = function (item, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, patient)
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .mstMedicines(firebase, item.DoctorId)
            .doc(item[primaryKeyName])
            .update(dbUtils.fnGetUpdatableObject(updatableProps, item))
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }

}

const fnDeleteMedicine = function (item, fnSuccessCallback, fnFailureCallBack) {
    item.SearchText = fnBuildMedicineSearchText(item);
    if (window.isCordova == true) {
        let sql = 'DELETE FROM ' + tableName
            + 'WHERE ' + primaryKeyName + ' = ' + item[primaryKeyName];
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .mstMedicines(firebase, item.DoctorId)
            .doc(item[primaryKeyName])
            .delete()
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }

}
const fnBuildMedicineSearchText = function (item) {
    let searchText = "";
    if (item.Brand != "") {
        searchText = item.Brand;
    }
    if (item.Generic != "") {
        searchText = searchText + " " + item.Generic;
    }
    return searchText;
}
export {
    fnInsertMstMedicine,
    fnGetMstMedicines,
    fnInsertMstMedicines,
    fnUpdateMedicine,
    fnDeleteMedicine
}