
var CryptoJS = require("crypto-js");

const fnEncode = function (message) {
    return CryptoJS.AES.encrypt(message, 'Scripty-007').toString();
}

const fnDecode = function (ciphertext) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, 'Scripty-007');
    return bytes.toString(CryptoJS.enc.Utf8);

}

const fnHash = function (message) {
    return CryptoJS.SHA3(message).toString();

}
const fnBase64Encode = function (message) {

    const encodedWord = CryptoJS.enc.Utf8.parse(message);
    const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
    return encoded;
}

const fnBase64Decode = function (encoded) {

    const encodedWord = CryptoJS.enc.Base64.parse(encoded);
    const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
    return decoded;
}
export {
    fnEncode,
    fnDecode,
    fnHash,
    fnBase64Encode,
    fnBase64Decode
}