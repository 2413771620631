<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <!-- <f7-navbar :sliding="false">
      <div slot="left">
        <img style="height: 30px;width: 30px;margin-left: 8px;" src="static/icons/favicon.png" />
      </div>
      <f7-nav-title>SCRIPTY</f7-nav-title>

      <f7-nav-right slot="right">
        <f7-button icon-material="home"></f7-button>
      </f7-nav-right>
      
    </f7-navbar>-->
    <f7-navbar>
      <f7-nav-left>
        <img
          class="margin-left-small"
          style="height: 40px;width: 40px;"
          src="static/icons/favicon.png"
        />

        <span>SCRIPTY</span>
      </f7-nav-left>
      <!-- <f7-nav-title>SCRIPTY</f7-nav-title> -->
      <!-- <f7-nav-right>
        <f7-button href="/home/1/" style="color:white" icon-material="home"></f7-button>
      </f7-nav-right>-->
    </f7-navbar>
    <!-- <f7-view name="home" url="/patient-list/">
    </f7-view>-->
    <div class="overflow-hidden-y h-100" v-if="HomeTab == '1'">
      <patient-list></patient-list>
    </div>
    <div v-if="HomeTab == '2'">
      <div class="animate-slide-in-right">
        <!-- <h2>Coming Soon</h2>

        <h4>A place where you can quickly check your scheduled follow-ups :)</h4>-->
        <follow-ups></follow-ups>
      </div>
    </div>
    <div class="animate-slide-in-right" v-if="HomeTab == '3'">
      <f7-toolbar class="f-row f-vertical-center f-end">
        <div slot="after-inner">
          <f7-button raised fill small color="white" class="margin-right-small" @click="fnLogout()">
            <span class="f-row f-vertical-center">
              <f7-icon size="18" class="margin-right-xsmall" material="power_settings_new"></f7-icon>Logout
            </span>
          </f7-button>
        </div>
      </f7-toolbar>

      <f7-block-title>
        <span>Your Settings</span>
      </f7-block-title>
      <f7-list>
        <f7-list-item title="Manage Profile" link="#" @click="fnGoToView('/doctor-profile/')"></f7-list-item>
        <f7-list-item
          title="Manage Medicines"
          link="#"
          @click="fnGoToView('/master-medicine-list/')"
        ></f7-list-item>
      </f7-list>
    </div>
    <f7-toolbar tabbar position="bottom">
      <f7-link
        class="font-xsmall"
        icon-md="material:person"
        @click="fnSetHomeTab('1');"
        :tab-link-active="HomeTab == '1'"
      >Patients</f7-link>
      <f7-link
        class="font-xsmall"
        icon-md="material:date_range"
        @click="fnSetHomeTab('2');"
        :tab-link-active="HomeTab == '2'"
      >Follow-ups</f7-link>
      <f7-link
        class="font-xsmall"
        icon-md="material:settings"
        @click="fnSetHomeTab('3');"
        :tab-link-active="HomeTab == '3'"
      >Settings</f7-link>
    </f7-toolbar>
    <!-- <f7-panel right cover>
      <f7-page>
        <f7-navbar title="Actions"></f7-navbar>
        <f7-block>
          <f7-list no-hairlines>
            <f7-list-item>
              <f7-link href="/doctor-profile/" panel-close>Your Profile</f7-link>
            </f7-list-item>
            <f7-list-item>
              <f7-link href="/" panel-close>Logout</f7-link>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-page>
    </f7-panel>-->
    <!-- 
    <f7-toolbar tabbar position="bottom">
      <f7-link tab-link="#tab-1" tab-link-active>Patients</f7-link>
      <f7-link tab-link="#tab-2">Follow Ups</f7-link>
      <f7-link tab-link="#tab-3">Settings</f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="tab-1" class="page-content" tab-active>
        <f7-block>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-2" class="page-content">
        <f7-block>
          <p>Tab 2 content</p>...
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-3" class="page-content">
        <f7-block>
          <p>Tab 3 content</p>...
        </f7-block>
      </f7-tab>
    </f7-tabs>-->
  </f7-page>
</template>
<script>
import PatientList from "../components/patient-list";
import FollowUps from "../components/follow-ups";
const doctorStore = require("../store/doctorStore.js");
const errorHandler = require("../shared/error-handler.js");
export default {
  name: "home",
  components: {
    PatientList,
    FollowUps,
  },
  mounted: function () {
    this.HomeTab = this.routeTab;
    console.log("Home Mounted");
    this.fnRequestPermissions();
    // errorHandler.fnRaiseError();
  },
  data: function () {
    return {
      HomeTab: 1,
  
    };
  },
  props: {
    routeTab: "",
  },
  methods: {
    fnRequestPermissions() {
      let that = this;
      if (window.isCordova == true) {
        // console.log(cordova.plugins.permissions);
        let permissionsPlugin = cordova.plugins.permissions;
        let permissions = [permissionsPlugin.WRITE_EXTERNAL_STORAGE];
        let successCallback = function (status) {
          if (!status.hasPermission) {
            permissionsPlugin.requestPermissions(
              permissions,
              function (status) {
                if (!status.hasPermission) errorCallback();
              },
              errorCallback
            );
          }
        };
        let errorCallback = function () {
          errorHandler.fnRaiseErrorWithCallback(
            "Permissions Denied!",
            "We are sorry but the app cannot proceed without necessary permissions",
            function () {
              console.warn("Permissions denied, logging out");
              that.fnLogout();
            }
          );
        };
        permissionsPlugin.requestPermissions(
          permissions,
          successCallback,
          errorCallback
        );
      }
    },
    fnSetHomeTab(tab) {
      this.HomeTab = tab;
    },
    fnGoToView(path) {
      this.$f7router.navigate(path);
    },
    
    fnLogout() {
      let that = this;
      doctorStore.fnRemoveDoctorDeviceLogin(function () {
        that.fnGoToView("/");
      });
    },
  },
};
</script>