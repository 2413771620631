const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "Appointments";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);

const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnRequestAppointment = function (item, fnSuccessCallback, fnFailureCallBack) {
    item.IsActive = 1;
    item.IsSynced = 0;
    // item.Status = "Request";
    item.IsUpdateSyncPending = 0;

    item.CreatedDate = (new Date()).toUTCString();
    item.CreatedDateMs = (new Date()).getTime();
    item.Status = "Request";
    // item.IsDownloadSyncPendingDoctor = 1;
    // item.IsDownloadSyncPendingReception = 1;
    item.ServerTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    dbUtils.fnTrimTextOnObject(item, props);
    // let fireStoreItem = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);

    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .appointments(firebase)
            .doc(item[primaryKeyName])
            .set(item).then(function () {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
    }

}

const fnGetNextAppointment = function (doctorId, patientId, dateMs, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? AND PatientId = ? AND DateMs = ? AND Status != 'Done' ORDER BY DateMs, StartTimeMs LIMIT 1",
                [doctorId, patientId, dateMs], function (tx, resultSet) {
                    if (fnSuccessCallback) {
                        fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                    }
                });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {


        fireStore.appointments(firebase, doctorId, patientId, dateMs).onSnapshot(function (items) {

            let _items = [];
            let data = null;
            items.docChanges().forEach(change => {
                let doc = change.doc;
                if (!doc.metadata.hasPendingWrites) {

                    // items.forEach(function (doc) {
                    data = doc.data();
                    if (data && data.Status != 'Done' && data.Status != 'Cancelled') {
                        _items.push(doc.data());
                    }
                    // });
                    if (fnSuccessCallback) {
                        fnSuccessCallback(_items);
                    }
                }
            });
        }, function (error) {
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        })
    }

}




const fnUpdateAppointment = function (item, fnSuccessCallback, fnFailureCallBack) {
    item.IsDownloadSyncPendingDoctor = 1;
    item.IsDownloadSyncPendingReception = 1;
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, item)
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .appointments(firebase)
            .doc(item[primaryKeyName])
            .update(dbUtils.fnGetUpdatableObject(updatableProps, item))
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }

}

const fnDeleteAppointment = function (item, fnSuccessCallback, fnFailureCallBack) {

    if (window.isCordova == true) {
        let sql = 'DELETE FROM ' + tableName
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .appointments(firebase, item.DoctorId)
            .doc(item[primaryKeyName])
            .delete()
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }

}
const fnAttachAppointmentListener = function (item, fnChangeCallback) {
    fireStore.appointments(firebase, item[primaryKeyName], function (doc) {
        fnChangeCallback(doc.data());
    });
}


export {
    fnRequestAppointment,
    fnGetNextAppointment,
    fnUpdateAppointment,
    fnDeleteAppointment,
    fnAttachAppointmentListener
}