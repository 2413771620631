const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "PaymentThirdParties";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);

const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";



const fnGetPaymentThirdParties = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? ", [doctorId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        // console.log(doctorId)
        fireStore.paymentThirdParties(firebase, doctorId).get().then(function (items) {
            let _items = [];
            console.log("Appointment Types")
            items.forEach(function (doc) {
                _items.push(doc.data());
            });
            if (fnSuccessCallback) {
                console.log(_items)

                fnSuccessCallback(_items);
            }

        }, function (error) {
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
    }

}





export {
    
    fnGetPaymentThirdParties,
  
}