<template>
  <f7-page name="about-us">
    <f7-navbar>
      <f7-nav-left>
        <f7-button
          href="/"
          style="color: white"
          icon-f7="arrow_left"
        ></f7-button>
      </f7-nav-left>
      <f7-nav-title>About Us</f7-nav-title>
    </f7-navbar>

    <div class="h-100 overflow-y-auto">
      <div class="col-md-12">
        <h2 class="section-title aligncenter">About the Company</h2>
        <div style="margin:20px" class="sub-title-section aligncenter sidepadding">
          Brightfish Infotech is a startup created with the insight of enabling
          enterprises to utilise the power of information technology to create
          better solutions. Technical Expertise, Innovative Ideas and the
          Passion for Quality make us the people you want to work with. Located
          in one of the fastest growing IT cities in India, Brightfish takes
          innovation and technology to a newer high for a better tomorrow. We
          are focused on several industry verticals and domains. There is no
          innovation without creativity. We live by this phrase. The Key to our
          Quality Service is Innovation, Quality and the Certified Capabilities
          of our Team Members, which enables us to Craft a Solution that
          Perfectly Fits the Business Needs of our Clients.
        </div>
      </div>
    </div>
  </f7-page>
</template>
<style>
</style>
<script>
export default {
  name: "about-us",
  components: {},
  mounted: function () {},
  data: function () {
    return {};
  },
  props: {},
  methods: {},
};
</script>