<template>
  <f7-page>
    <f7-navbar>
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        :href="callbackUrl"
      ></f7-link>
      <f7-nav-title>Payment Page</f7-nav-title>
    </f7-navbar>
    <div id="paytm-checkout-container">
      <div id="paytm-checkoutjs"></div>
    </div>
  </f7-page>
</template>
<script>
const errorHandler = require("../shared/error-handler.js");
const dbUtils = require("../store/dbUtils.js");
const axios = require("axios").default;

import { mapState } from "vuex";

const { paymentGatewayConfig } = require("../services/backendConfig.js");
export default {
  name: "payment-gateway",
  components: {},

  props: {
    itemId: String,
    itemType: String,
    amountToBePaid: null,
    callbackUrl: null,
    customerId: String,
    merchantId: String,
  },

  data: function () {
    return {};
  },
  computed: {
    ...mapState(["__CurrentPatient", "currentUser", "__LoggedInUserData"]),
  },

  mounted: function () {
    this.fnInitGateway();
  },
  methods: {
    fnInitGateway() {
      this.fnPayNow();
      // let orderId = dbUtils.generateUID();
      // let amount = this.amountToBePaid;
      // let customerId = this.__CurrentPatient.PatientId;
      // let data = `orderId=${orderId}&amount=${amount}&customerId=${customerId}`;

      // let xhrPayNow = new XMLHttpRequest();
      // xhrPayNow.withCredentials = false;

      // xhrPayNow.addEventListener("readystatechange", function () {
      //   if (this.readyState === 4) {
      //     console.log(this.responseText);
      //     let token = this.responseText;
      //     var config = {
      //       root: "",
      //       flow: "DEFAULT",
      //       data: {
      //         orderId: orderId /* update order id */,
      //         token: token /* update token value */,
      //         tokenType: "TXN_TOKEN",
      //         amount: amount /* update amount */,
      //       },
      //       merchant: {
      //         redirect: false,
      //       },
      //       handler: {
      //         notifyMerchant: function (eventName, data) {
      //           console.log("notifyMerchant handler function called");
      //           console.log("eventName => ", eventName);
      //           console.log("data => ", data);
      //         },
      //         transactionStatus: function (data) {
      //           console.log("payment status ", data);

      //           // let _data = encodeURIComponent(JSON.stringify(data));
      //           let xhrTransactionStatus = new XMLHttpRequest();
      //           xhrTransactionStatus.withCredentials = false;

      //           xhrTransactionStatus.addEventListener(
      //             "readystatechange",
      //             function () {
      //               if (this.readyState === 4) {
      //                 alert("Payment Success");
      //                 console.log(this.responseText);
      //               }
      //             }
      //           );

      //           xhrTransactionStatus.open(
      //             "POST",
      //             paymentGatewayConfig.transactionStatusEndpoint
      //           );
      //           // set `Content-Type` header
      //           xhrTransactionStatus.setRequestHeader(
      //             "Content-Type",
      //             "application/json"
      //           );
      //           xhrTransactionStatus.send(JSON.stringify(data));
      //         },
      //       },
      //     };
      //     console.log(window.Paytm);
      //     let ele = document.getElementById("paytm-checkoutjs");
      //     ele.style.visibility = "visible";
      //     if (window.Paytm && window.Paytm.CheckoutJS) {
      //       // console.log("1");
      //       // // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
      //       // console.log("2");

      //       // initialze configuration using init method
      //       window.Paytm.CheckoutJS.init(config)
      //         .then(function onSuccess() {
      //           // console.log("3");

      //           // after successfully updating configuration, invoke JS Checkout
      //           window.Paytm.CheckoutJS.invoke();
      //         })
      //         .catch(function onError(error) {
      //           console.log("error => ", error);
      //         });
      //       // });
      //     }
      //   }
      // });

      // xhrPayNow.open("POST", paymentGatewayConfig.payNowEndpoint);
      // xhrPayNow.setRequestHeader(
      //   "Content-Type",
      //   "application/x-www-form-urlencoded"
      // );

      // xhrPayNow.send(data);
    },
    fnGoToView(url) {
      this.$f7router.navigate(url);
    },
    fnPayNow() {
      let dialog = f7App.dialog.preloader("Initialising gateway");

      let orderId = dbUtils.generateUID();
      let amount = this.amountToBePaid;
      let customerId = this.__CurrentPatient.PatientId;
      let data = `orderId=${orderId}&amount=${amount}&customerId=${customerId}`;
      let that = this;
      axios
        .post(paymentGatewayConfig.payNowEndpoint, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          console.log("Transaction Response", response.data);
          let token = response.data;
          var config = {
            root: "#paytm-checkout-container",
            flow: "DEFAULT",
            data: {
              orderId: orderId /* update order id */,
              token: token /* update token value */,
              tokenType: "TXN_TOKEN",
              amount: amount /* update amount */,
            },
            merchant: {
              redirect: false,
            },
            handler: {
              notifyMerchant: function (eventName, data) {
                console.log("notifyMerchant handler function called");
                console.log("eventName => ", eventName);
                console.log("data => ", data);
              },
              transactionStatus: function (data) {
                console.log("Transaction Status");
                let dialog1 = f7App.dialog.preloader(
                  "Verifying transaction status"
                );

                that
                  .fnTransactionStatus({
                    customerId: that.customerId,
                    merchantId: that.orderId,
                    orderId: orderId,
                    itemId: that.itemId,
                    itemType: that.itemType,
                    description: "Consultation Fees",
                    paytmData: data,
                  })
                  .then(function (response) {
                    dialog1.close();
                    errorHandler.fnRaiseSuccessWithCallback(
                      "Payment Success",
                      "Your transaction was processed successfully",
                      function () {
                        that.$f7router.navigate(that.callbackUrl);
                      }
                    );
                  })
                  .catch(function (error) {
                    dialog1.close();

                    errorHandler.fnRaiseErrorWithCallback("", "", function () {
                      that.$f7router.navigate(that.callbackUrl);
                    });
                    // console.log(error);
                  });
              },
            },
          };
          // console.log(window.Paytm);
          // let ele = document.getElementById("paytm-checkoutjs");
          // ele.style.visibility = "visible";
          if (window.Paytm && window.Paytm.CheckoutJS) {
            // console.log("1");
            // // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // console.log("2");

            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config)
              .then(function onSuccess() {
                // console.log("3");
                // after successfully updating configuration, invoke JS Checkout
                window.Paytm.CheckoutJS.invoke();
                dialog.close();
              })
              .catch(function onError(error) {
                dialog.close();
                errorHandler.fnRaiseError();
                console.log("error => ", error);
              });
            // });
          }
        })
        .catch(function (error) {
          dialog.close();
          errorHandler.fnRaiseError();

          console.log(error);
        });

      // let xhrPayNow = new XMLHttpRequest();
      // xhrPayNow.withCredentials = false;

      // xhrPayNow.addEventListener("readystatechange", function () {
      //   if (this.readyState === 4) {
      //   }
      // });

      // xhrPayNow.open("POST", paymentGatewayConfig.payNowEndpoint);
      // xhrPayNow.setRequestHeader(
      //   "Content-Type",
      //   "application/x-www-form-urlencoded"
      // );

      // xhrPayNow.send(data);
    },
    fnTransactionStatus(data) {
      console.log("payment status data", data);

      return axios.post(paymentGatewayConfig.transactionStatusEndpoint, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
  },
};
</script>