<template>
  <f7-page name="contact-us">
    <f7-navbar>
      <f7-nav-left>
        <f7-button
          href="/"
          style="color: white"
          icon-f7="arrow_left"
        ></f7-button>
      </f7-nav-left>
      <f7-nav-title>Contact Us</f7-nav-title>
    </f7-navbar>

    <div class="h-100 overflow-y-auto">
      <div class="col-sm-3">
        <section class="widget">
          <!-- <div class="widget-heading">
                                    <h4>Contact Us</h4>
                                </div> -->
          <div class="footer-contact-info">
            <ul style="list-style-type: none">
              <li>
                <p><i class="fa fa-building"></i>Brightfish Infotech</p>
              </li>
              <li>
                <p>
                  <i class="fa fa-map-marker"></i>Plot No. 56 , Manas , Pachgaon
                  Road , Shanta Durga Colony Karvir Kolhapur, Maharashtra,
                  416013 India
                </p>
              </li>
              <li>
                <p>
                  <i class="fa fa-envelope"></i><strong>Email:</strong>
                  <a href="mailto:saurabh.shelgaonkar@brightfish.co.in"
                    >saurabh.shelgaonkar@brightfish.co.in</a
                  >
                </p>
              </li>
              <li>
                <p>
                  <i class="fa fa-phone"></i
                  ><strong>Phone:</strong> +91-9890325553
                </p>
              </li>
            </ul>
            <br />
          </div>
        </section>
      </div>
    </div>
  </f7-page>
</template>
<style>
</style>
<script>
export default {
  name: "contact-us",
  components: {},
  mounted: function () {},
  data: function () {
    return {};
  },
  props: {},
  methods: {},
};
</script>