<template>
  <f7-page>
    <f7-navbar>
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        @click="fnGoToPatientView()"
      ></f7-link>
      <f7-nav-title>Request New Appointment</f7-nav-title>
    </f7-navbar>
    <div class="f-column h-100">
      <div>
        <f7-block-title>
          <span>Doctor Name : {{ currentUser.Name }}</span>
        </f7-block-title>
      </div>
      <div>
        <div class="f-column f-spread">
          <div class="f-column margin-small">
            <label>Date:&nbsp;</label>

            <datepicker
              :disabled-dates="disabledDates"
              v-model="appointmentDate"
            ></datepicker>
          </div>
          <div class="f-column compact-list margin-small">
            <label>Preferred Session:&nbsp;</label>

            <f7-list no-hairlines-md class="w-100">
              <f7-list-input
                :value="selectedWorkScheduleIndex"
                outline
                @input="selectedWorkScheduleIndex = $event.target.value"
                type="select"
                class="small-dropdown"
              >
                <option value></option>
                <option
                  v-for="(workSchedule, index) in workSchedules"
                  :key="index"
                  :value="index"
                >
                  {{ workSchedule.Name }}:&nbsp;{{
                    fnFormatTime(workSchedule.StartTime)
                  }}-{{ fnFormatTime(workSchedule.EndTime) }}
                </option>
              </f7-list-input>
            </f7-list>
          </div>
          <div class="f-column compact-list margin-small">
            <label>Reason For Appointment:&nbsp;</label>

            <f7-list class="w-100" no-hairlines>
              <f7-list-input
                class="small-dropdown"
                type="textarea"
                required
                outline
                validate
                :value="appointmentForm.ReasonForAppointment"
                @input="
                  appointmentForm.ReasonForAppointment = $event.target.value
                "
              ></f7-list-input>
            </f7-list>
          </div>
        </div>
        <f7-button
          class="margin-x-small margin-bottom-small"
          raised
          fill
          color="green"
          :class="fnIsRequestButtonDisabled() ? 'disabled' : ''"
          @click="fnOpenAppointmentBookingPrompt"
          >Submit Request</f7-button
        >
      </div>
    </div>
    <f7-actions ref="confirmAppointmentBookingActions">
      <f7-actions-group>
        <f7-actions-label> Confirm Appointment Request </f7-actions-label>
        <f7-actions-button @click="fnBookAppointment()">Yes</f7-actions-button>
        <f7-actions-button @click="fnCloseAppointmentBookingPrompt()"
          >Cancel</f7-actions-button
        >
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>

<script>
const fireStore = require("../store/firebaseStore.js");
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";
const appointmentsStore = require("../store/appointmentsStore.js");
const dbUtils = require("../store/dbUtils.js");
const dateUtils = require("../shared/dateUtils.js");
const errorHandler = require("../shared/error-handler.js");
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "appointment-request",
  components: {
    Datepicker,
  },
  props: {},
  computed: {
    ...mapState(["currentUser", "__CurrentPatient", "__WorkSchedules"]),
  },
  data: function () {
    return {
      Patient: {},
      disabledDates: {},
      appointmentDate: null,
      selectedWorkScheduleIndex: null,
      selectedWorkSchedule: "",
      appointmentForm: {
        ReasonForAppointment: "",
      },
      workSchedules: [],
    };
  },
  created: function () {
    this.Patient = this.__CurrentPatient;
    let yesterday = new Date().setDate(new Date().getDate() - 1);
    this.disabledDates.to = new Date(yesterday);
  },
  mounted: function () {
    // this.appointmentTypes = this.__AppointmentTypes;
    this.workSchedules = this.__WorkSchedules;
  },
  methods: {
    fnGoToPatientView() {
      this.$f7router.navigate("/patient-view/", {
        props: {
          prevPage: "appointment-request",
        },
      });
    },
    fnIsRequestButtonDisabled() {
      if (
        this.appointmentDate &&
        this.appointmentForm.ReasonForAppointment &&
        this.selectedWorkScheduleIndex
      ) {
        return false;
      }
      return true;
    },
    fnOpenAppointmentBookingPrompt() {
      this.appointmentForm.RequestedWorkScheduleId = this.workSchedules[
        this.selectedWorkScheduleIndex
      ].WorkScheduleId;

      this.$refs.confirmAppointmentBookingActions.open();
    },
    fnCloseAppointmentBookingPrompt() {
      this.selectedWorkScheduleIndex = "";
      this.$refs.confirmAppointmentBookingActions.close();
    },

    fnFormatTime(time) {
      let _date = moment(new Date()).format("YYYY-MM-DD");

      return moment
        .utc(new Date(_date + "T" + time + ":00+00:00"))
        .format("HH:mm a");
    },
    fnBookAppointment() {
      let that = this;
      this.appointmentForm.DoctorId = this.currentUser.uid;
      this.appointmentForm.PatientId = this.Patient.PatientId;
      this.appointmentForm.PatientName = this.Patient.Name;
      this.appointmentForm.PatientEmail = this.Patient.Email;
      this.appointmentForm.PatientPhone = this.Patient.Phone;
      this.appointmentForm.PaymentStatus = 0;
      this.appointmentForm.AppointmentId = dbUtils.generateUID();
      this.appointmentForm.RequestedDate = moment(this.appointmentDate).format(
        "YYYY-MM-DD"
      );
      this.appointmentForm.Date = moment(this.appointmentDate).format(
        "YYYY-MM-DD"
      );
      this.appointmentForm.DateMs = dateUtils.fnGetUTCTime(
        this.appointmentForm.Date,
        "00:00"
      );
      this.appointmentForm.StartTime = "00:00";

      this.appointmentForm.StartTimeMs = dateUtils.fnGetUTCTime(
        this.appointmentForm.Date,
        "00:00"
      );

      // console.log(this.appointmentForm);
      appointmentsStore.fnRequestAppointment(
        this.appointmentForm,
        function () {
          console.log("Success");
          that.$f7router.navigate("/patient-view/");
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
};
</script>
