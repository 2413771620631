const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "PatientCallLogs";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const genericRepository = require('./genericRepository.js')
const fireStoreCollectionName = "patientCallLogs";
const fireStore = require('./firebaseStore.js')
import firebase from "firebase/app";
import "firebase/firestore";

const fnInsertCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnInsertItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.PatientId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnGetCallLogs = function (patientId, fnSuccessCallback, fnFailureCallBack) {
    let sql = "SELECT * FROM " + tableName + " WHERE PatientId = ? ";
    let params = [patientId];
    genericRepository.fnGetItems(tableName, sql, params, fireStore[fireStoreCollectionName](firebase, patientId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);
}

const fnUpdateCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    return new Promise((resolve, reject) => {
        genericRepository.fnUpdateItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.PatientId), fireStoreCollectionName, function (data) {
            if (fnSuccessCallback) {
                fnSuccessCallback(data);
            }
            resolve();
        }, function () {
            if (fnFailureCallBack) {
                fnFailureCallBack();
            }
            reject();
        });

    })
}

const fnDeleteCallLog = function (item, fnSuccessCallback, fnFailureCallBack) {
    genericRepository.fnDeleteItem(item, tableName, fireStore[fireStoreCollectionName](firebase, item.PatientId), fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack);

}
const fnSubscribeToIncomingCalls = function (patientId, fnSuccessCallback, fnFailureCallBack) {
    let unsubscribe = fireStore[fireStoreCollectionName](firebase, patientId, 1).onSnapshot(function (items) {

        let _items = [];
        let data = null;
        items.forEach(doc => {
            console.log("Snapshot")
            var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            console.log(source, " data: ", doc.data());
            // let doc = change.doc;
            // items.forEach(function (doc) {
            if (!doc.metadata.hasPendingWrites) {

                data = doc.data();

                _items.push(doc.data());

                // });
                if (fnSuccessCallback) {
                    fnSuccessCallback(_items);
                }
            }

        });
    }, function (error) {
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    })

    return unsubscribe;
}
const fnSubscribeToOngoingCall = function (patientId, callLogId, fnSuccessCallback, fnFailureCallBack) {
    let unsubscribe = fireStore[fireStoreCollectionName](firebase, patientId).doc(callLogId).onSnapshot(function (doc) {

        if (!doc.metadata.hasPendingWrites) {

            if (fnSuccessCallback) {
                fnSuccessCallback(doc.data());
            }
        }

    }, function (error) {
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    })

    return unsubscribe;
}
export {
    fnInsertCallLog,
    fnGetCallLogs,
    fnUpdateCallLog,
    fnDeleteCallLog,
    fnSubscribeToIncomingCalls,
    fnSubscribeToOngoingCall
}