import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");
const dbSchema = require('../store/dbSchema.js')
const dbUtils = require('../store/dbUtils.js')
const fileUtils = require('../shared/fileUtils.js')

const fnInitiateSync = function (tableName, fireStoreCollectionObjectName, fileObject = null, fireStoreCollectionObject = null, isPassCollectionKeyToFireStore = false, collectionKey) {
    // let intervalId = setInterval(() => sync(), 1000);
    sync();
    function sync() {
        console.log("Sync Starting")
        let props = dbSchema.tables.find(t => t.name == tableName).props;
        let primaryKeyName = props.find(t => t.primaryKey == true).name;
        console.log(window.isCordova);

        if (window.isCordova == true) {
            window.localDB.transaction(function (tx) {
                console.log("Starting transaction")
                let sql = "SELECT * FROM " + tableName + " WHERE IsSynced = 0";
                // if (fileObject != null) {
                //     sql = "SELECT * FROM " + tableName + " WHERE IsSyncedImage = 0";
                // }
                // console.log(sql);
                tx.executeSql(sql, [], function (tx, resultSet) {
                    console.log("Select done")
                    let items = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                    if (items.length > 0) {


                        items.forEach(item => {
                            console.log("Syncing Item",item);
                            console.log("primaryKeyName", primaryKeyName);
                            // console.log(item[primaryKeyName]);

                            // console.log("Pruinting Sync statys")
                            // console.log(item.IsSynced)
                            // console.log(item.IsSyncedImage)
                            console.log("Trying sync")
                            item.IsSynced = 1;
                            item.SyncedOn = (new Date()).toUTCString();
                            let networkState = navigator.connection.type;
                            if (networkState !== Connection.NONE) {
                                console.log("Connection live")
                                if (fileObject == null || (fileObject && item.IsSyncedImage == "1")) {
                                    let promise = null;
                                    if (fireStoreCollectionObject) {
                                        promise = fireStoreCollectionObject.doc(item[primaryKeyName]).set(item);
                                    } else {
                                        if (isPassCollectionKeyToFireStore == false) {
                                            promise = fireStore[fireStoreCollectionObjectName](firebase).doc(item[primaryKeyName]).set(item);

                                        } else {
                                            promise = fireStore[fireStoreCollectionObjectName](firebase, item[collectionKey]).doc(item[primaryKeyName]).set(item);

                                        }

                                    }
                                    // console.log("fireStoreCollectionObject", fireStoreCollectionObject);
                                    // console.log("fireStoreCollectionObjectName", fireStoreCollectionObjectName);
                                    promise.then(function () {
                                        console.log("Sync success")

                                        console.log("firestore success");
                                        let updateSql = "UPDATE " + tableName + " SET IsSynced = 1, SyncedOn = ? WHERE " + primaryKeyName + "= ?";
                                        window.localDB.transaction(function (tx1) {
                                            tx1.executeSql(updateSql, [item["SyncedOn"], item[primaryKeyName]], function () {
                                                console.log("Update success")

                                            });
                                        }, function (error) {
                                            console.log('Transaction ERROR: ' + error.message);
                                        });

                                        // sync();

                                    }, function (error) {
                                        console.log("firestore error");
                                        console.log(error);


                                    });
                                } else {
                                    console.log("File type");
                                    // console.log(item[fileObject.fileNameColumn]);
                                    // console.log(fileObject.folder);
                                    // console.log(fileObject.preferredStorage);
                                    // console.log(item.IsSyncedImage);
                                    if (item.IsSyncedImage === 0 || item.IsSyncedImage == "0") {

                                        fileUtils.fnGetImageUrl(item[fileObject.fileNameColumn], fileObject.folder, fileObject.preferredStorage, function (base64Img) {
                                            base64Img = base64Img.replace("data:image/png;base64,", "");
                                            // console.log(base64Img)
                                            fireStore
                                                .fireStorage(firebase)
                                                .ref(fileObject.folder + '/' + item[fileObject.fileNameColumn])
                                                .putString(base64Img, "base64").then(function (snapshot) {
                                                    snapshot.ref.getDownloadURL().then(downloadURL => {

                                                        console.log("Success")
                                                        // console.log(downloadURL);
                                                        console.log("firestore success");
                                                        let updateSql = "UPDATE " + tableName + " SET IsSyncedImage = 1, SyncedOn = ?, " + fileObject.serverUrlColumn + " = ? WHERE " + primaryKeyName + "= ?";
                                                        window.localDB.transaction(function (tx1) {
                                                            tx1.executeSql(updateSql, [item["SyncedOn"], downloadURL, item[primaryKeyName]], function () {
                                                                console.log("Update success")

                                                            });
                                                        }, function (error) {
                                                            console.log('Transaction ERROR: ' + error.message);
                                                        });



                                                    });
                                                })
                                        }, function (error) {
                                            console.log("File Error");
                                        })
                                    }
                                }
                            } else {
                                console.log("Disconnected")

                            }
                        });
                        setTimeout(function () {
                            console.log("Resync started")
                            sync();
                        }, 2000);
                    } else {
                        console.log("All changes synced")
                        // clearInterval(intervalId);
                    }

                }, function (error) {
                    console.log('SQL ERROR: ');
                    console.log(JSON.stringify(error));
                    // if (error.txlock == true) {
                    //     setTimeout(function () { sync(); }, 2000);

                    // }
                });

            }, function (error) {
                console.log('Transaction ERROR: ' + error.message);

            }, function () {

            });
        }
    }

}

const fnInitiateUpdateSync = function (tableName, fireStoreCollectionObjectName, fileObject = null, fireStoreCollectionObject = null) {
    // let intervalId = setInterval(() => sync(), 1000);
    sync();
    function sync() {
        console.log("Sync Starting")
        let props = dbSchema.tables.find(t => t.name == tableName).props;
        let primaryKeyName = props.find(t => t.primaryKey == true).name;
        console.log(window.isCordova);

        if (window.isCordova == true) {
            window.localDB.transaction(function (tx) {
                console.log("Starting transaction")
                let sql = "SELECT * FROM " + tableName + " WHERE IsUpdateSyncPending = 1";
                // if (fileObject != null) {
                //     sql = "SELECT * FROM " + tableName + " WHERE IsSyncedImage = 0";
                // }
                // console.log(sql);
                tx.executeSql(sql, [], function (tx, resultSet) {
                    console.log("Select done")
                    let items = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                    console.log("Update Items", items);
                    if (items.length > 0) {


                        items.forEach(item => {
                            console.log("Update Sync pending")
                            console.log(item.IsUpdateSyncPending == 1);
                            //Is Update Sync Pending
                            if (item.IsUpdateSyncPending == 1) {
                                console.log("Trying update sync")
                                item.IsUpdateSyncPending = 0;
                                item.SyncedOn = (new Date()).toUTCString();
                                let networkState = navigator.connection.type;
                                if (networkState !== Connection.NONE) {
                                    console.log("Connection live")
                                    if (fileObject == null || (fileObject && item.IsSyncedImage == "1")) {
                                        let promise = null;
                                        if (fireStoreCollectionObject) {
                                            promise = fireStoreCollectionObject.doc(item[primaryKeyName]).update(item);
                                        } else {
                                            promise = fireStore[fireStoreCollectionObjectName](firebase).doc(item[primaryKeyName]).update(item);
                                        }

                                        promise.then(function () {
                                            console.log("Update Sync success")

                                            console.log("firestore success");
                                            let updateSql = "UPDATE " + tableName + " SET IsUpdateSyncPending = 0, SyncedOn = ? WHERE " + primaryKeyName + "= ?";
                                            window.localDB.transaction(function (tx1) {
                                                tx1.executeSql(updateSql, [item["SyncedOn"], item[primaryKeyName]], function () {
                                                    console.log("Update success")

                                                });
                                            }, function (error) {
                                                console.log('Transaction ERROR: ' + error.message);
                                            });

                                            // sync();

                                        }, function (error) {
                                            console.log("firestore error");
                                            console.log(error);


                                        });
                                    }

                                } else {
                                    console.log("Disconnected")

                                }
                            }
                        });
                        setTimeout(function () {
                            console.log("Update Resync started")
                            sync();
                        }, 2000);
                    } else {
                        console.log("All changes synced")
                        // clearInterval(intervalId);
                    }

                }, function (error) {
                    console.log('SQL ERROR: ');
                    console.log(JSON.stringify(error));
                    // if (error.txlock == true) {
                    //     setTimeout(function () { sync(); }, 2000);

                    // }
                });

            }, function (error) {
                console.log('Transaction ERROR: ' + error.message);

            }, function () {

            });
        }
    }

}


export {
    fnInitiateSync,
    fnInitiateUpdateSync
}