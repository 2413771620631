import { Store } from "vuex";
import firebase from "firebase/app";
import "firebase/storage";
const fireStore = require("../store/firebaseStore.js");
// import { storage } from "firebase";

// const fnSaveImage = function (blob, base64, folder, filename, fnSuccessCallback, fnErrorCallBack, preferredStorage) {
//     if (base64) {
//         blob = b64toBlob(base64, 'image/png');
//     }
//     console.log("Trying to open file system")
//     if (window.isCordova == true) {
//         window.requestFileSystem(
//             LocalFileSystem.PERSISTENT,
//             0,
//             function (fs) {
//                 console.log("file system open: " + fs.name);

//                 let storageLocation = "";
//                 let platform = "";
//                 switch (device.platform) {
//                     case "Android":
//                         storageLocation = "file:///storage/emulated/0/";
//                         if (preferredStorage == "App") {
//                             storageLocation = cordova.file.dataDirectory;
//                         }
//                         platform = "Android";
//                         break;
//                     case "iOS":
//                         storageLocation = cordova.file.documentsDirectory;
//                         if (preferredStorage == "App") {
//                             storageLocation = cordova.file.dataDirectory;
//                         }
//                         platform = "iOS";
//                         break;
//                 }
//                 let folderpath = storageLocation + folder;
//                 console.log("File storage location: " + storageLocation);
//                 console.log("Folder Path: " + folderpath);


//                 fs.root.getDirectory(folderpath, { create: true, exclusive: false }, function () {
//                     // console.log("File storage location: " + storageLocation);

//                     window.resolveLocalFileSystemURL(folderpath, function (dir) {
//                         dir.getFile(filename, { create: true }, function (file) {
//                             file.createWriter(
//                                 function (fileWriter) {
//                                     fileWriter.write(blob);

//                                     if (fnSuccessCallback) {
//                                         console.log("Success")
//                                         console.log(storageLocation);
//                                         fnSuccessCallback(storageLocation, '');
//                                     }
//                                 },
//                                 function (err) {
//                                     console.log(err);
//                                     if (fnErrorCallBack) {
//                                         fnErrorCallBack();
//                                     }
//                                 }
//                             );
//                         });
//                     });
//                 }, function (err) {
//                     console.log("Directory failed");
//                     console.log(err);
//                 });

//             },
//             function (err) {
//                 console.log("File system opening failed");
//                 console.log(err);
//                 if (fnErrorCallBack) {
//                     fnErrorCallBack();
//                 }
//             }
//         );
//     } else {
//         fireStore
//             .fireStorage(firebase)
//             .ref(folder + '/' + filename)
//             .put(blob).then(function (snapshot) {
//                 snapshot.ref.getDownloadURL().then(downloadURL => {
//                     if (fnSuccessCallback) {
//                         console.log("Success")
//                         console.log(downloadURL);
//                         fnSuccessCallback('', downloadURL);
//                     }
//                 });
//             })
//     }
// }
const fnSaveImage = function (blob, base64, folder, filename, fnSuccessCallback, fnErrorCallBack, preferredStorage) {
    if (base64) {
        blob = b64toBlob(base64, 'image/png');
    }
    if (window.isCordova == true) {

        let storageLocation = "";
        let platform = "";
        switch (device.platform) {
            case "Android":
                storageLocation = "file:///storage/emulated/0/";
                if (preferredStorage == "App") {
                    storageLocation = cordova.file.dataDirectory;
                }
                platform = "Android";
                break;
            case "iOS":
                // storageLocation = cordova.file.documentsDirectory;
                storageLocation = cordova.file.dataDirectory;
                if (preferredStorage == "App") {
                    storageLocation = cordova.file.dataDirectory;
                }
                platform = "iOS";
                break;
        }
        console.log("Trying to open file system")
        window.resolveLocalFileSystemURL(
            storageLocation,
            function (fs) {
                console.log("file system open");

                // storageLocation = fs.toInternalURL();
                let folderpath = storageLocation + folder;
                console.log("File storage location: " + storageLocation);
                console.log("Folder Path: " + folderpath);


                fs.getDirectory(folder, { create: true }, function (dir) {
                    // console.log("File storage location: " + storageLocation);


                    dir.getFile(filename, { create: true }, function (file) {
                        file.createWriter(
                            function (fileWriter) {
                                fileWriter.write(blob);

                                if (fnSuccessCallback) {
                                    console.log("Success")
                                    console.log(storageLocation);
                                    fnSuccessCallback(storageLocation, '');
                                }
                            },
                            function (err) {
                                console.log(err);
                                if (fnErrorCallBack) {
                                    fnErrorCallBack();
                                }
                            }
                        );
                    });

                }, function (err) {
                    console.log("Directory failed");
                    console.log(err);
                });

            },
            function (err) {
                console.log("File system opening failed");
                console.log(err);
                if (fnErrorCallBack) {
                    fnErrorCallBack();
                }
            }
        );
    } else {
        fireStore
            .fireStorage(firebase)
            .ref(folder + '/' + filename)
            .put(blob).then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    if (fnSuccessCallback) {
                        console.log("Success")
                        console.log(downloadURL);
                        fnSuccessCallback('', downloadURL);
                    }
                });
            })
    }
}
const saveFile = function (dirEntry, fileData, fileName) {
    let that = this;
    dirEntry.getFile(
        fileName,
        { create: true, exclusive: false },
        function (fileEntry) {
            that.writeFile(fileEntry, fileData);
        },
        function (err) {
            console.log(err);
        }
    );
}

const writeFile = function (fileEntry, dataObj, isAppend) {
    // Create a FileWriter object for our FileEntry (log.txt).
    fileEntry.createWriter(function (fileWriter) {
        fileWriter.onwriteend = function () {
            console.log("Successful file write...");
            console.log(fileEntry.fullPath);
            // if (dataObj.type == "image/png") {
            //   readBinaryFile(fileEntry);
            // } else {
            //   readFile(fileEntry);
            // }
        };

        fileWriter.onerror = function (e) {
            console.log("Failed file write: " + e.toString());
        };

        fileWriter.write(dataObj);
    });
}

const b64toBlob = function (b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const fnGetImageUrl = function (filename, folder, preferredStorage, fnSuccessCallback) {
    if (window.isCordova == true) {
        window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            0,
            function (fs) {
                console.log("file system open: " + fs.name);

                let storageLocation = "";
                let platform = "";
                switch (device.platform) {
                    case "Android":
                        storageLocation = "file:///storage/emulated/0/";
                        if (preferredStorage == "App") {
                            storageLocation = cordova.file.dataDirectory;
                        }
                        platform = "Android";
                        break;
                    case "iOS":
                        // storageLocation = cordova.file.documentsDirectory;
                        storageLocation = cordova.file.dataDirectory;
                        if (preferredStorage == "App") {
                            storageLocation = cordova.file.dataDirectory;
                        }
                        platform = "iOS";
                        break;
                }
                let folderpath = storageLocation + folder;
                console.log("File storage location: " + storageLocation);
                window.resolveLocalFileSystemURL(folderpath, function (dir) {
                    dir.getFile(filename, { create: false }, function (fileEntry) {
                        fileEntry.file(function (file) {
                            console.log("File Reader Opened")
                            var reader = new FileReader();
                            reader.onloadend = function (e) {
                                var content = this.result;
                                if (fnSuccessCallback) {
                                    // console.log(content);
                                    fnSuccessCallback(content);
                                    // console.log(file)
                                    // console.log(file.toURL());
                                    // fnSuccessCallback(file.toURL());
                                    // console.log("Printing file url")
                                    // console.log(file.toInternalURL());
                                    // fnSuccessCallback(file.toInternalURL());
                                }
                            };
                            // The most important point, use the readAsDatURL Method from the file plugin
                            reader.readAsDataURL(file);
                        })


                    });
                });

            },
            function (err) {
                console.log(err);
                if (fnErrorCallBack) {
                    fnErrorCallBack();
                }
            }
        );
    } else {
        fireStore
            .fireStorage(firebase)
            .ref(folder + '/' + filename)
            .getDownloadURL().then(function (url) {
                fnSuccessCallback(url);
            })
    }
}

const fnGetBase64Img = function (storageLocation, filename, fnSuccessCallback) {
    if (window.isCordova == true) {
        window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            0,
            function (fs) {
                // console.log("file system open: " + fs.name);

                // let storageLocation = "";
                // let platform = "";
                // switch (device.platform) {
                //     case "Android":
                //         storageLocation = "file:///storage/emulated/0/";
                //         if (preferredStorage == "App") {
                //             storageLocation = cordova.file.dataDirectory;
                //         }
                //         platform = "Android";
                //         break;
                //     case "iOS":
                //         storageLocation = cordova.file.documentsDirectory;
                //         if (preferredStorage == "App") {
                //             storageLocation = cordova.file.dataDirectory;
                //         }
                //         platform = "iOS";
                //         break;
                // }
                let folderpath = storageLocation;
                console.log("File storage location: " + storageLocation);
                window.resolveLocalFileSystemURL(folderpath, function (dir) {
                    dir.getFile(filename, { create: false }, function (fileEntry) {
                        console.log("Getting file")
                        fileEntry.file(function (file) {
                            var reader = new FileReader();
                            reader.onloadend = function (e) {
                                var content = this.result;
                                if (fnSuccessCallback) {
                                    // console.log(content);
                                    fnSuccessCallback(content);
                                    // console.log(file)
                                    // console.log(file.toURL());
                                    // fnSuccessCallback(file.toURL());
                                    // console.log("Printing file url")
                                    // console.log(file.toInternalURL());
                                    // fnSuccessCallback(file.toInternalURL());
                                }
                            };
                            // The most important point, use the readAsDatURL Method from the file plugin
                            reader.readAsDataURL(file);
                        })


                    });
                });

            },
            function (err) {
                console.log(err);
                if (fnErrorCallBack) {
                    fnErrorCallBack();
                }
            }
        );
    }
}

const fnDeleteImage = function (filename, folder, preferredStorage, fnSuccessCallback) {
    if (window.isCordova == true) {
        window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            0,
            function (fs) {
                console.log("file system open: " + fs.name);

                let storageLocation = "";
                let platform = "";
                switch (device.platform) {
                    case "Android":
                        storageLocation = "file:///storage/emulated/0/";
                        if (preferredStorage == "App") {
                            storageLocation = cordova.file.dataDirectory;
                        }
                        platform = "Android";
                        break;
                    case "iOS":
                        // storageLocation = cordova.file.documentsDirectory;
                        storageLocation = cordova.file.dataDirectory;
                        if (preferredStorage == "App") {
                            storageLocation = cordova.file.dataDirectory;
                        }
                        platform = "iOS";
                        break;
                }
                let folderpath = storageLocation + folder;
                console.log("File storage location: " + storageLocation);
                window.resolveLocalFileSystemURL(folderpath, function (dir) {
                    dir.getFile(filename, { create: false }, function (fileEntry) {
                        fileEntry.remove(function () {
                            console.log("file removed");

                            if (fnSuccessCallback) {

                                fnSuccessCallback();

                            }
                        }, function (error) {
                            console.log(error)
                        }, function () {
                            console.log("file not found");
                            if (fnSuccessCallback) {

                                fnSuccessCallback();

                            }
                        })




                    });
                });

            },
            function (err) {
                console.log(err);
                if (fnErrorCallBack) {
                    fnErrorCallBack();
                }
            }
        );
    } else {
        fireStore
            .fireStorage(firebase)
            .ref(this.imagePath)
            .delete()
            .then(() => {
                if (fnSuccessCallback) {

                    fnSuccessCallback();

                }
            })
            .catch(error => {
                console.error(`file delete error occured: ${error}`);
            });
    }
}
const fnGetImageUrlServer = function (filename, folder, fnSuccessCallback) {
    fireStore
        .fireStorage(firebase)
        .ref(folder + '/' + filename)
        .getDownloadURL().then(function (url) {
            fnSuccessCallback(url);
        })
}

const fnSaveImageServer = function (blob, base64, folder, filename, fnSuccessCallback, fnErrorCallBack) {
    if (base64) {
        blob = b64toBlob(base64, 'image/png');
    }
    let uploadTask = fireStore
        .fireStorage(firebase)
        .ref(folder + '/' + filename)
        .put(blob);

    uploadTask.then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(downloadURL => {
            if (fnSuccessCallback) {
                console.log("Success")
                console.log(downloadURL);
                fnSuccessCallback('', downloadURL);
            }
        }, function (error) {
            console.log("Firebase File upload error", error);
            if (fnErrorCallBack) {
                fnErrorCallBack();
            }

        });
    })
    return uploadTask;
}
export {
    fnSaveImage,
    fnGetImageUrl,
    fnGetBase64Img,
    fnDeleteImage,
    fnGetImageUrlServer,
    fnSaveImageServer
}