
let auth = function (_firebase) {

    return _firebase.auth();
}
let currentUser = function (_firebase) {
    return auth(_firebase).currentUser;
}

let fireStorage = function (_firebase) {
    return _firebase.storage();


}

// firebase collections
let db = function (_firebase) {
    return _firebase.firestore();
}
let doctors = function (_firebase) {
    return db(_firebase).collection("doctors");
}
let doctorLogos = function (_firebase, doctorId) {
    if (doctorId) {
        return db(_firebase).collection("doctorLogos").where("DoctorId", "==", doctorId);
    }
    return db(_firebase).collection("doctorLogos");
}
let patients = function (_firebase, doctorId, firebaseUID, phone) {
    if (doctorId && firebaseUID) {
        console.log("FirebaseUID", firebaseUID)

        return db(_firebase).collection("patients")
            .where("DoctorId", "==", doctorId)
            .where("PatientFirebaseUID", "==", firebaseUID);

    }
    if (doctorId && phone) {
        console.log("Phone")
        return db(_firebase).collection("patients")
            .where("DoctorId", "==", doctorId)
            .where("PhoneHash", "==", phone);

    }
    if (doctorId) {
        return db(_firebase).collection("patients").where("DoctorId", "==", doctorId);
    }
    if (phone) {
        return db(_firebase).collection("patients")
            .where("PhoneHash", "==", phone);

    }
    return db(_firebase).collection("patients");
}
let workSchedules = function (_firebase, doctorId) {
    return doctors(_firebase).doc(doctorId).collection("workSchedules");
}
let patientNames = function (_firebase) {

    return db(_firebase).collection("patientNames");
}
let patientPhones = function (_firebase) {

    return db(_firebase).collection("patientPhones");
}
let patientEmailIds = function (_firebase) {

    return db(_firebase).collection("patientEmailIds");
}
let prescriptions = function (_firebase, patientId) {
    if (patientId) {
        return db(_firebase).collection("prescriptions").where("PatientId", "==", patientId)
    }
    return db(_firebase).collection("prescriptions");
}
let appointments = function (_firebase, doctorId, patientId, dateMs) {
    //Next Patient Appointment
    if (doctorId && dateMs && patientId) {
        return db(_firebase).collection("appointments").where("DoctorId", "==", doctorId)
            .where("PatientId", "==", patientId)
            .where("StartTimeMs", ">=", dateMs)
            .orderBy("StartTimeMs")
    }
    // if (doctorId && patientId && dateMs) {

    //     return db(_firebase).collection("appointments").where("DoctorId", "==", doctorId)
    //         .where("PatientId", "==", patientId)
    //         .where("DateMs", ">=", dateMs)
    //         .orderBy("DateMs")
    //         .orderBy("StartTimeMs")
    //     // .limit(1)
    // }
    // if (doctorId && patientId) {

    //     return db(_firebase).collection("appointments").where("DoctorId", "==", doctorId)
    //         .where("PatientId", "==", patientId)
    //         .orderBy("DateMs")
    //         .orderBy("StartTimeMs")
    //     // .limit(1)
    // }
    return db(_firebase).collection("appointments")
}

let fnAttachAppointmentListener = function (_firebase, docId, fnChangeCallback) {
    db(_firebase).collection("appointments")
        .doc(docId)
        .onSnapshot(function (doc) {
            if (!doc.metadata.hasPendingWrites) {

                fnChangeCallback(doc);
            }
        });

}
let medicalRecords = function (_firebase, patientId) {
    if (patientId) {
        return db(_firebase).collection("medicalrecords").where("PatientId", "==", patientId)
    }
    return db(_firebase).collection("medicalrecords");
}
let prescriptionSignatureImages = function (_firebase, prescriptionId) {
    if (prescriptionId) {
        return db(_firebase).collection("prescriptionSignatureImages").where("PrescriptionId", "==", prescriptionId)
    }
    return db(_firebase).collection("prescriptionSignatureImages");
}
let signatureImages = function (_firebase, medicalRecordId) {
    if (medicalRecordId) {
        return db(_firebase).collection("signatureImages").where("MedicalRecordId", "==", medicalRecordId)
    }
    return db(_firebase).collection("signatureImages");
}
let paymentConfirmationImages = function (_firebase, appointmentId) {
    if (appointmentId) {
        return db(_firebase).collection("paymentConfirmationImages").where("AppointmentId", "==", appointmentId)
    }
    return db(_firebase).collection("paymentConfirmationImages");
}
// const prescriptions = function (patientId) {
//     return patients.doc(patientId).collection("prescriptions");
// }

let mstMedicines = function (_firebase, doctorId) {
    return doctors(_firebase).doc(doctorId).collection("mstMedicines");
}

let fnGetBatch = function (_firebase) {
    return db(_firebase).batch();
}

let generateUID = function () {
    var uuidValue = "", k, randomValue;
    for (k = 0; k < 32; k++) {
        randomValue = Math.random() * 16 | 0;

        if (k == 8 || k == 12 || k == 16 || k == 20) {
            uuidValue += "-"
        }
        uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
    }
    return uuidValue;
}

let paymentThirdParties = function (_firebase, doctorId) {
    return doctors(_firebase).doc(doctorId).collection("paymentThirdParties");
}
let patientCallLogs = function (_firebase, patientId, status) {
    if (patientId && status) {
        return patients(_firebase).doc(patientId).collection("callLogs").where("Status", "==", status);

    }

    if (patientId) {
        return patients(_firebase).doc(patientId).collection("callLogs");
    }
    // return doctors(_firebase).collection("mstMedicines");
}
let doctorCallLogs = function (_firebase, doctorId) {
    if (doctorId) {
        return doctors(_firebase).doc(doctorId).collection("callLogs");
    }
    // return doctors(_firebase).collection("mstMedicines");
}
let sharedFiles = function (_firebase, doctorId, patientId) {
    if (doctorId && patientId) {
        return db(_firebase).collection("sharedFiles").where("DoctorId", "==", doctorId)
            .where("PatientId", "==", patientId)
            .orderBy("CreatedDateMs", "desc")
    }
    return db(_firebase).collection("sharedFiles");
}
let chats = function (_firebase, doctorId, patientId) {
    if (doctorId && patientId) {
        return db(_firebase).collection("chats")
            .where("DoctorId", "==", doctorId)
            .where("PatientId", "==", patientId)
            .orderBy("CreatedDateMs", "desc");
    }
    // if (patientId) {
    //     return db(_firebase).collection("chats").where("PatientId", "==", doctorId);
    // }

    return db(_firebase).collection("chats");
}
let chatMessages = function (_firebase, doctorId, patientId) {
    // console.log(chatId)
    // if (isInsert == true) {
    //     return chats(_firebase).doc(chatId).collection("chatMessages")
    // }
    if (doctorId && patientId) {
        return db(_firebase).collection("chatMessages").where("DoctorId", "==", doctorId).where("PatientId", "==", patientId);
    }
    return db(_firebase).collection("chatMessages");

}
let fcMessages = function (_firebase, patientId) {

    if (patientId) {
        return db(_firebase).collection('fcMessages').where("PatientId", "==", patientId);
    }
    return db(_firebase).collection('fcMessages');

}
let orders = function (_firebase, patientId) {

    if (patientId) {
        return db(_firebase).collection('orders').where("CustomerId", "==", patientId);
    }
    return db(_firebase).collection('orders');

}
export {
    db,
    fireStorage,
    auth,
    generateUID,
    currentUser,
    doctors,
    prescriptions,
    patients,
    mstMedicines,
    fnGetBatch,
    prescriptionSignatureImages,
    medicalRecords,
    signatureImages,
    patientNames,
    patientPhones,
    patientEmailIds,
    appointments,
    paymentConfirmationImages,
    paymentThirdParties,
    workSchedules,
    fnAttachAppointmentListener,
    doctorLogos,
    patientCallLogs,
    doctorCallLogs,
    sharedFiles,
    chats,
    chatMessages,
    fcMessages,
    orders
}