<template>
  <div class="h-100 overflow-y-auto">
    <!-- <f7-page name="patient-list"> -->
    <!-- <f7-subnavbar>
      <div class="f-row f-vertical-center f-end w-100">
        <f7-button color="blue" href="/patient-create/">Add Patient</f7-button>
      </div>
    </f7-subnavbar>-->
    <f7-toolbar class="f-row f-vertical-center f-end">
      <div slot="after-inner">
        <f7-button
          raised
          fill
          small
          color="white"
          class="margin-right-small"
          href="/patient-create/false/"
        >
          <span class="f-row f-vertical-center">
            <f7-icon size="18" class="margin-right-xsmall" material="person_add"></f7-icon>New Patient
          </span>
        </f7-button>
      </div>
    </f7-toolbar>

    <f7-block-title>
      <span>Your Patients</span>
    </f7-block-title>
    <div class>
      <f7-list>
        <f7-list-item
          v-if="Patients.length==0"
        >No patients found. Click 'New Patient' button to add a new patient.</f7-list-item>
        <f7-list-item
          v-for="Patient in Patients"
          :key="Patient.PatientId"
          :title="Patient.Name"
          link="#"
          @click="fnGoToPatientView(Patient)"
        >
          <div slot="media" class="avatar-circle">
            <span class="initials">{{fnGetFirstLetter(Patient.Name)}}</span>
          </div>
        </f7-list-item>
      </f7-list>
    </div>
  </div>
  <!-- </f7-page> -->
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";

const fireStore = require("../store/firebaseStore.js");

const patientStore = require("../store/patientStore.js");

export default {
  name: "patient-list",
  mounted: function () {
    this.$store.commit("setPatient", {});

    this.fnGetPatients();
  },
  computed: {
    ...mapState(["currentUser"]),
  },
  data: function () {
    return {
      Patients: ([] = []),
    };
  },
  methods: {
    fnGetFirstLetter(text) {
      if (text && text.length > 0) {
        return text[0];
      }
      return "";
    },
    fnGetPatients() {
      this.Patients = [];
      let that = this;

      patientStore.fnGetPatients(
        this.currentUser.uid,
        function (data) {
          if (data && data.length > 0) {
            that.Patients = data;
          }
        },
        function () {}
      );
      // fireStore.patients

      // fireStore
      //   .patients(firebase, this.currentUser.uid)
      //   .get()
      //   .then(items => {
      //     console.log("Patients Found");
      //     items.forEach(doc => {
      //       if (doc) {
      //         that.Patients.push(Patient.fnMapToPatient(doc.id, doc.data()));
      //       }
      //     });
      //   })
      //   .catch(function(error) {
      //     console.log("Error getting documents: ", error);
      //   });
    },
    fnGoToPatientView(patient) {
      this.$store.commit("setPatient", patient);
      this.$f7router.navigate("/patient-view/");
    },
  },
};
</script>
