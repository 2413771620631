import moment from "moment";
const fnGetUTCTime = function (dateYYYYMMDD, timeHHmm) {

    return new Date(
        moment.utc(
            new Date(
                dateYYYYMMDD + "T" + timeHHmm
            )
        )
    ).getTime();
}

const fnGetUTCTimeFromDateObject = function (date) {
    return fnGetUTCTime(moment(date).format("YYYY-MM-DD"), moment(date).format("HH:mm"));
    // moment(date).format("HH:mm")
}
const fnGetUTCTimeFromDateObjectRemoveTime = function (date) {
    return fnGetUTCTime(moment(date).format("YYYY-MM-DD"), "00:00");

}

const fnGetNextDayDate = function (date) {
    return new Date(new Date().setDate(date.getDate() + 1));
}
// const fnGetDayEndDate = function(date){
//     let dateYYYYMMDD = moment(date).format("YYYY-MM-DD");
//     return new Date(dateYYYYMMDD + "T11:59:59");
//     return new Date(new Date().setDate(date.getDate() + 1));
// }

const fnGetAgeFromDateOfBirth = function (dob) {
    // console.log(dob)
    let now = new Date();

    let yearNow = now.getYear();
    let monthNow = now.getMonth();
    let dateNow = now.getDate();

    let yearDob = dob.getYear();
    let monthDob = dob.getMonth();
    let dateDob = dob.getDate();
    let age = {};
    let monthAge, dateAge;
    let yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge =
            fnGetMonthOffsetFactor(monthNow, yearNow) + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
    };
    if (age.years > 0) {
        return age.years +
            "y," +
            age.months +
            "m";
    } else {
        return age.months +
            "m," +
            age.days +
            "d"
    }
    // return (
    //     age.years +
    //     " year(s), " +
    //     age.months +
    //     " month(s) " +
    //     age.days +
    //     " day(s)"
    // );
}

const fnGetMonthOffsetFactor = function (month, year) {
    switch (month) {
        case (1, 3, 5, 7, 8, 10, 12):
            return 31;
        case 2:
            if (fnIsLeapYear(year)) {
                return 29;
            }
            return 28;
        default:
            return 30;
    }
}
const fnIsLeapYear = function (year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
}
export {
    fnGetUTCTime,
    fnGetUTCTimeFromDateObject,
    fnGetNextDayDate,
    fnGetUTCTimeFromDateObjectRemoveTime,
    fnGetAgeFromDateOfBirth
}