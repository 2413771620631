var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',[_c('f7-navbar',[_c('f7-link',{attrs:{"slot":"left","icon-only":true,"icon-f7":"arrow_left","href":"/patient-view/"},slot:"left"}),_vm._v(" "),_c('f7-nav-title',[_vm._v("Pay and Confirm")])],1),_vm._v(" "),_c('div',{staticClass:"h-100 overflow-y-auto"},[_c('f7-block-title',[_c('span',[_vm._v("Payment Account Options")])]),_vm._v(" "),_c('f7-list',_vm._l((_vm.PaymentThirdParties),function(PaymentThirdParty,index){return _c('f7-list-item',{key:index},[_c('div',{staticClass:"f-column"},[_c('span',{staticClass:"f-column"},[(
                PaymentThirdParty.AccountType &&
                PaymentThirdParty.AccountType != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Account Type")]),_vm._v(": "+_vm._s(_vm.fnGetAccountTypeName(PaymentThirdParty.AccountType)))]):_vm._e(),_vm._v(" "),(
                PaymentThirdParty.AccountHolderName &&
                PaymentThirdParty.AccountHolderName != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Account Holder")]),_vm._v(": "+_vm._s(PaymentThirdParty.AccountHolderName))]):_vm._e(),_vm._v(" "),(
                PaymentThirdParty.ProviderName &&
                PaymentThirdParty.ProviderName != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Provider")]),_vm._v(": "+_vm._s(PaymentThirdParty.ProviderName))]):_vm._e(),_vm._v(" "),(PaymentThirdParty.UPIId && PaymentThirdParty.UPIId != '')?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("UPI ID")]),_vm._v(": "+_vm._s(PaymentThirdParty.UPIId))]):_vm._e(),_vm._v(" "),(
                PaymentThirdParty.PhoneNumber &&
                PaymentThirdParty.PhoneNumber != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Phone Number")]),_vm._v(": "+_vm._s(PaymentThirdParty.PhoneNumber))]):_vm._e(),_vm._v(" "),(
                PaymentThirdParty.BankAccountNumber &&
                PaymentThirdParty.BankAccountNumber != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Bank Account No")]),_vm._v(": "+_vm._s(PaymentThirdParty.BankAccountNumber))]):_vm._e(),_vm._v(" "),(
                PaymentThirdParty.IFSCCode && PaymentThirdParty.IFSCCode != ''
              )?_c('span',{staticClass:"margin-right-small"},[_c('span',{staticClass:"text-secondary"},[_vm._v("IFSC Code")]),_vm._v(": "+_vm._s(PaymentThirdParty.IFSCCode))]):_vm._e()])])])}),1),_vm._v(" "),_c('f7-block-title',[_vm._v("Payment Confirmation Image")]),_vm._v(" "),_c('div',{staticClass:"f-column margin-small"},[(_vm.paymentImageUrl != '')?_c('img',{staticStyle:{"width":"100%"},attrs:{"crossorigin":"anonymous","src":_vm.paymentImageUrl}}):_c('span',{staticClass:"text-primary font-small"},[_vm._v("* Please upload confirmation receipt image once you have made payment\n        in any of the above doctor accounts. The doctor will be able to view\n        this receipt and confirm payment once image is uploaded.")])]),_vm._v(" "),_c('form',{staticClass:"margin-small",attrs:{"id":"create-payment-form"}},[_c('image-upload',{attrs:{"caller":'paymentConfirmation',"fileName":_vm.paymentImageUrl,"imageUrls":_vm.imageUrls,"fileFolder":_vm.uploadFolder},on:{"paymentConfirmationUrl":_vm.fnUpdatePaymentConfirmationUrl,"paymentConfirmationRemoved":_vm.fnPaymentConfirmationRemoved}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }