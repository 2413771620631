<template>
  <f7-page name="refund-policy">
    <f7-navbar>
      <f7-nav-left>
        <f7-button href="/" style="color:white" icon-f7="arrow_left"></f7-button>
      </f7-nav-left>
      <f7-nav-title>Refund Policy</f7-nav-title>
      
    </f7-navbar>

    <div class="h-100 overflow-y-auto">
        <div class="demo-layout mdl-layout mdl-layout--fixed-header mdl-js-layout mdl-color--grey-100">

        <div class="demo-ribbon"></div>
        <main class="demo-main mdl-layout__content">
            <div class="demo-container mdl-grid">
                <div class="mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"></div>
                <div  style="padding:18px" class="demo-content mdl-color--white mdl-shadow--4dp content mdl-color-text--grey-800 mdl-cell mdl-cell--8-col">

                    <h4>Refund and Cancellation Policy</h4>
                    <p>
                        Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will try to enhance or fix our services provided the reason is genuine after investigation. Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.

                        Our Policy for the cancellation and refund will be as follows:
                    </p>
                    <h5>Cancellation Policy</h5>
                    <p>
                        For Cancellations please mail to us as admin@brightfish.co.in.

                        Requests received later than 5 business days prior to the end of the current service period will be treated as cancellation of services for the next service period.
                    </p>
                    <h5>Refund Policy</h5>
                    <p>
                        We will try our best to create the suitable design concepts for our clients.

                        In case any client is not completely satisfied with our products we can provide a refund if we feel that the reason for refund is genuine after investigation.

                       
                    </p>


                </div>
            </div>
        </main>
    </div>

    </div>
  </f7-page>
</template>
<style>
</style>
<script>
export default {
  name: "refund-policy",
  components: {},
  mounted: function () {},
  data: function () {
    return {};

  },
  props: {},
  methods: {},
};
</script>