// export class Prescription {
//     PrescriptionId: string;
//     PrescriptionNumber: number;
//     Medications: string[] = [];
//     Date: Date;
//     PatientId: string;
//     HiddenNotes: string;
//     VisibleNotes: string;
//     Advices: string[];
//     FollowUpDate : Date;
//     FollowUpDays : number;
// }

//First Propert is Key
const props = [
    "PrescriptionId",
    "PatientId",
    "PatientName",
    "PatientAge",
    "PatientGender",
    "PatientAddress",
    "DoctorId",
    "DoctorName",
    "DoctorAddress",
    "HiddenNotes",
    "VisibleNotes",
    "Medicines",
    "Advices",
    "Status",
    "Date",
    "FollowUpDate"
]

const fnMap = function (id, data) {
    let output = {};
    output[props[0]] = id;
    props.forEach(prop => {
        if (data[prop]) {
            output[prop] = data[prop];
        }
    })
    return output;
}

export {
    fnMap
}