import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/app";
import "firebase/firestore";
const firebaseStore = require('./firebaseStore.js')
const utils = require("../shared/utils.js");
const mstMedicinesStore = require("./mstMedicineStore.js")
const doctorStore = require("./doctorStore.js")
const workSchedulesStore = require("./workSchedulesStore.js")

const paymentThirdPartiesStore = require("./paymentThirdPartiesStore.js")

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        currentUser: null,
        __LoggedInUserData: {},
        __PatientProfiles: [],
        userProfile: {},
        __CurrentPatient: {},
        __CurrentPrescription: {},
        __MstMedicines: [],
        __WorkSchedules: [],

        __PaymentThirdParties: [],
        __CopiedMedicalRecord: {},
        __CurrentMedicalRecord: {},
        __followUp: {}
    },
    actions: {
        fetchDoctorProfile({ commit, state }) {
            return new Promise((resolve, reject) => {

                return new Promise((resolveDoctor, rejectDoctor) => {

                    if (state.currentUser.UniqueName) {
                        doctorStore.fnGetDoctorByUniqueName(state.currentUser.UniqueName, function (item) {
                            if (item == null) {
                                rejectDoctor("Not Found");
                                reject("Not Found");
                            } else {
                                console.log("Printing doctor profile")
                                // item.uid = state.currentUser.uid;
                                item.uid = item.ConsultantId;
                                console.log(item);
                                commit('setCurrentUser', item);
                                commit('setUserProfile', item);
                                resolveDoctor();
                            }
                        }, function () {
                            rejectDoctor();
                            reject();
                        });
                    } else {
                        doctorStore.fnGetDoctor(state.currentUser.uid, function (item) {
                            console.log("Printing doctor profile")
                            item.uid = state.currentUser.uid;
                            console.log(item);
                            commit('setCurrentUser', item);
                            commit('setUserProfile', item);
                            resolveDoctor();
                        }, function () {
                            rejectDoctor();
                            reject();
                        });
                    }
                }).then(function (result) {
                    return new Promise((resolveWorkSchedule, rejectWorkSchedule) => {
                        workSchedulesStore.fnGetWorkSchedules(state.currentUser.uid, function (items) {
                            console.log("WorkSchedules")
                            console.log(items)
                            if (items && items.length > 0) {
                                commit('setWorkSchedules', items)
                            } else {
                                commit('setWorkSchedules', [])
                            }
                            resolveWorkSchedule();
                            // resolve();
                        }, function () { rejectWorkSchedule(); reject() })
                    });
                }).then(function (result) {
                    return new Promise((resolvePaymentThirdParty, rejectPaymentThirdParty) => {
                        paymentThirdPartiesStore.fnGetPaymentThirdParties(state.currentUser.uid, function (items) {

                            if (items && items.length > 0) {
                                commit('setPaymentThirdParties', items)
                            } else {
                                commit('setPaymentThirdParties', [])
                            }
                            resolvePaymentThirdParty();
                            resolve();
                        }, function () { rejectPaymentThirdParty(); reject() })
                    })

                })
            })
        },
        fnGetMasterMedicines({ commit, state }) {
            return new Promise((resolve, reject) => {
                mstMedicinesStore.fnGetMstMedicines(state.currentUser.uid, function (items) {
                    if (items && items.length > 0) {
                        commit('setMstMedicines', items);
                        resolve();
                    } else {
                        commit('setMstMedicines', [])
                    }
                }, function () { reject(); })
            })

        }
    },
    mutations: {
        setLoggedInUserData(state, val) {
            state.__LoggedInUserData = val
        },
        setCurrentUser(state, val) {
            state.currentUser = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
        },
        setPatient(state, val) {
            state.__CurrentPatient = val;
        },
        setPatientProfiles(state, val) {
            state.__PatientProfiles = val;
        },
        setPrescription(state, val) {
            state.__CurrentPrescription = val;
        },
        addMstMedicine(state, val) {
            if (state.__MstMedicines) {
                state.__MstMedicines = utils._array.union(state.__MstMedicines, val);
            } else {
                state.__MstMedicines = val;

            }
        },
        setMstMedicines(state, val) {
            state.__MstMedicines = val;
        },
        setDoctorSignature(state, val) {

        },
        setCopiedMedicalRecord(state, val) {
            state.__CopiedMedicalRecord = val;
        },
        setMedicalRecord(state, val) {
            state.__CurrentMedicalRecord = val;
        },
        setFollowUpState(state, val) {
            state.__followUp = val;
        },
        setPaymentThirdParties(state, val) {
            state.__PaymentThirdParties = val;
        },
        setWorkSchedules(state, val) {
            state.__WorkSchedules = val;
        },
        resetStore(state, val) {

            state.currentUser = null;
            state.__LoggedInUserData = {};
            state.__PatientProfiles = [];
            state.userProfile = {};
            state.__CurrentPatient = {};
            state.__CurrentPrescription = {};
            state.__MstMedicines = [];
            state.__WorkSchedules = [];

            state.__PaymentThirdParties = [];
            state.__CopiedMedicalRecord = {};
            state.__CurrentMedicalRecord = {};
            state.__followUp = {};

        }
        // resetCopiedPrescription(state, val) {
        //     state.__CopiedPrescription = {};
        // }
    }
})