<template>
  <f7-app :params="f7params">
    <f7-view
      main
      :ios-dynamic-navbar="false"
      url="/"
      :push-state="true"
      push-state-separator=""
    >
      <!-- Initial Page -->
      <!-- <f7-page>
        <account></account>
      </f7-page>-->
    </f7-view>
    <flash-message class="flashpool"></flash-message>
    <f7-sheet
      style="background-color: transparent"
      v-if="isMounted == true"
      class="disable-click"
      :class="[
        isCallMinimised == false ? 'call-full-height' : 'modal-in',
        isVideoCall == true && isCallMinimised == false ? 'modal-in' : '',
      ]"
      top
      :push="false"
      :backdrop="false"
      :opened="isSheetOpen == true"
      :closeByBackdropClick="false"
      :closeByOutsideClick="false"
      @sheet:opened="fnSheetOpened"
      @sheet:close="fnSheetClosing"
    >
      <f7-page-content
        style="background-color: transparent"
        class="safe-area-ios"
      >
        <video-audio-call
          v-if="isVideoCall == true"
          :_roomId="roomId"
          :callLogId="callLogId"
        ></video-audio-call>
      </f7-page-content>
    </f7-sheet>
    <!-- <div id="overlay"></div> -->
    <f7-sheet
      :opened="isPWASheetOpened"
      @sheet:closed="isPWASheetOpened = false"
    >
      <f7-navbar>
        <f7-nav-left>
          <img
            class="margin-left-small"
            style="height: 40px; width: 40px"
            src="static/icons/favicon.png"
          />

          <span>Scripty</span>
        </f7-nav-left>
        <!-- <f7-nav-title>SCRIPTY</f7-nav-title> -->
        <!-- <f7-nav-right>
        <f7-button href="/home/1/" style="color:white" icon-material="home"></f7-button>
      </f7-nav-right>-->
      </f7-navbar>
      <!-- Scrollable sheet content -->

      <f7-block style="min-height: 30rem">
        <f7-button
          raised
          class="background-primary text-white"
          @click="fnShowAppInstallPrompt()"
          >Install App</f7-button
        >
      </f7-block>
    </f7-sheet>
  </f7-app>
</template>
<style>
.call-full-height {
  height: 100%;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<script>
const config = {
  apiKey: "AIzaSyD5uCFHcFe_kjWVsMLfNpLanQL8yZGEMUs",
  authDomain: "scripty-fd554.firebaseapp.com",
  databaseURL: "https://scripty-fd554.firebaseio.com",
  projectId: "scripty-fd554",
  storageBucket: "scripty-fd554.appspot.com",
  messagingSenderId: "693305286005",
  appId: "1:693305286005:web:1ecf2da11028ab3eb6c73c",
  measurementId: "G-5RZS4YC03X",
};
// const config = {
//   apiKey: "AIzaSyD4Z6VZIZfwRFfzxT2bhUivk1ISVqpNkP8",
//   authDomain: "scripty-dev.firebaseapp.com",
//   databaseURL:
//     "https://scripty-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "scripty-dev",
//   storageBucket: "scripty-dev.appspot.com",
//   messagingSenderId: "380887785323",
//   appId: "1:380887785323:web:e8199dcb6e30e4d53a362e",
//   measurementId: "G-XZL7Q2GKRC",
// };
import { Device } from "framework7/framework7-lite.esm.bundle.js";
import cordovaApp from "../js/cordova-app.js";
import routes from "../js/routes.js";
import EventBus from "../shared/eventBus.js";
import Account from "../components/account";
import VideoAudioCall from "../components/video-audio-call";
import firebase from "firebase/app";


const localDBStore = require("../store/dbLocal.js");
import "firebase/firestore";
const errorHandler = require("../shared/error-handler.js");
export default {
  components: { Account, VideoAudioCall },
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: "com.brightfish.scripty", // App bundle ID
        name: "Scripty", // App name
        theme: "md", // Automatic theme detection
        // view: {
        //   pushState: true
        // },
        // App root data
        data: function () {
          return {};
        },

        // App routes
        routes: routes,

        // Register service worker
        serviceWorker: Device.cordova
          ? {}
          : {
              path: "/service-worker.js",
            },
        // Input settings
        // input: {
        //   scrollIntoViewOnFocus: Device.cordova && !Device.electron,
        //   scrollIntoViewCentered: Device.cordova && !Device.electron
        // },
        input: {
          scrollIntoViewOnFocus: false,
          scrollIntoViewCentered: false,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
      isMounted: false,
      isSheetOpen: false,
      isVideoCall: false,
      isVideoSmall: false,
      isCallMinimised: false,
      isPWASheetOpened: false,
      pwaDialog: null,
    };
  },
  mounted() {
    let that = this;
    this.$f7ready((f7) => {
      // that.fnShowPWAModal();

      window.audioCallFile = new Audio("/static/sounds/incoming-call.mp3");
      window.audioCallFile.loop = true;
      EventBus.$on("call-minimise-event", (value) => {
        console.log("Call minimised", value);
        that.isCallMinimised = value;
      });
      EventBus.$on("place-video-call", (room) => {
        console.log("Call Received Event", room);

        that.roomId = room.roomId;
        that.callLogId = room.callLogId;
        that.isCallMinimised = false;

        that.isSheetOpen = true;
      });
      EventBus.$on("disconnect-video-call", () => {
        that.roomId = "";
        that.callLogId = "";
        that.isVideoCall = false;
        that.isCallMinimised = false;

        that.isSheetOpen = false;
      });
      EventBus.$on("trigger-pwa-install",()=>{
        that.fnShowPWAModal();
      })
      f7App = this.$f7;
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
        console.log("f7 ready");

        //Init DB
        let localDB = localDBStore.fnInitDB();
        window.localDB = localDB;

        //Init Firebase
        console.log("Firebase start init");
        firebase.initializeApp(config);
        firebase.firestore().settings({ experimentalForceLongPolling: true });
        console.log("Firebase init");

        that.isMounted = true;
        //Prevent Back Button Press

        // firebase.setLogLevel("debug");

        // firebase.firestore.setLogLevel("debug");

        // document.addEventListener(
        //   "deviceready",
        //   function() {
        //     console.log("Device Ready");
        //     firebase.initializeApp(config);
        //     // Firestore.initialise(options).then(function(_db) {
        //     //   console.log("DB Initialised");
        //     //   window.firestoreDB = _db;
        //     // });
        //   },
        //   false
        // );
      } else {
        console.log("Non cordova");
        firebase.initializeApp(config);
        firebase.firestore().settings({ experimentalForceLongPolling: true });
        that.isMounted = true;

        // firebase.setLogLevel("debug");

        // firebase.firestore.setLogLevel("debug");
        // firebase.firestore().settings({ experimentalForceLongPolling: true });

        // firebase.initializeApp(config);
      }
      // Call F7 APIs here
    });
  },
  methods: {
    // showOverlay() {
    //   document.getElementById("overlay").style.display = "block";
    // },

    // hideOverlay() {
    //   document.getElementById("overlay").style.display = "none";
    // },
    fnSheetClosing() {
      this.isVideoCall = false;
    },
    fnSheetOpened() {
      this.isVideoCall = true;
    },
    async fnShowAppInstallPrompt() {
      // this.showOverlay();
      window.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await window.deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // if (outcome === "accepted") {
      //   this.pwaDialog = f7App.dialog.preloader("Installing app");
      // } else {
      //   console.log("User dismissed the A2HS prompt");
      // }
      this.isPWASheetOpened = false;
      // We've used the prompt, and can't use it again, throw it away
      window.deferredPrompt = null;
    },
    async fnShowPWAModal() {
      let that = this;
      window.addEventListener("appinstalled", () => {
        // Hide the app-provided install promotion
        // if (that.pwaDialog) {
        //   that.pwaDialog.close();
        //   errorHandler.fnRaiseSuccess(
        //     "App installed",
        //     "Go to home screen to open the app"
        //   );
        // }
        errorHandler.fnRaiseSuccess("Message", "App installed successfully");
        // Clear the deferredPrompt so it can be garbage collected
        window.deferredPrompt = null;
        // Optionally, send analytics event to indicate successful install
        console.log("PWA was installed");
      });
      let retryCount = 10;

      do {
        if (window.deferredPrompt) {
          this.isPWASheetOpened = true;

          retryCount = 0;
        } else {
          retryCount--;
        }
        await this.wait(2000);
      } while (retryCount > 0);
    },
    wait(ms) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log("Done waiting");
          resolve(ms);
        }, ms);
      });
    },
  },
};
</script>