<template>
  <f7-page name="doctor-profile" class="overflow-hidden-x">
    <f7-navbar title="Your Profile">
      <f7-link slot="left" :icon-only="true" icon-f7="arrow_left" href="/home/3/"></f7-link>

      <!-- <f7-link slot="right">Save</f7-link> -->
    </f7-navbar>
    <div class="h-100 overflow-y-auto">
      <f7-toolbar class="f-row f-vertical-center f-end">
        <div slot="after-inner" class="f-row f-end">
          <f7-button
            raised
            fill
            small
            color="white"
            class="margin-right-small"
            @click="IsEdit = true"
            v-if="IsEdit == false"
          >
            <span class="f-row f-vertical-center">
              <f7-icon size="18" class="margin-right-xsmall" material="edit"></f7-icon>Edit Profile
            </span>
          </f7-button>
          <f7-button
            raised
            fill
            small
            color="white"
            class="margin-right-small"
            @click="IsEdit = false"
            v-if="IsEdit == true"
          >
            <span class="f-row f-vertical-center">Cancel</span>
          </f7-button>
          <f7-button
            raised
            fill
            small
            color="white"
            class="margin-right-small"
            @click="fnUpdateDoctorProfile()"
            :class="fnValidateRegistrationForm()?'':'disabled'"
            v-if="IsEdit == true"
          >
            <span class="f-row f-vertical-center">Save</span>
          </f7-button>
        </div>
      </f7-toolbar>
      <f7-block-title>Basic Information</f7-block-title>

      <form id="doctor-profile-form" name="RegisterUser" autocomplete="off">
        <f7-list no-hairlines no-hairlines-between>
          <li
            class="item-content item-input item-input-outline"
            v-bind:class="{ disabled: !IsEdit }"
          >
            <div class="item-inner">
              <div class="item-title item-label">Full Name</div>
              <div class="item-input-wrap f-row f-vertical-bottom">
                <span class="margin-bottom-small margin-left-small">Dr.&nbsp;</span>
                <input
                  type="text"
                  name="Name"
                  :disabled="IsEdit == false"
                  required
                  validate
                  v-model="DoctorProfile.Name"
                  placeholder="Your Full Name"
                />
              </div>
            </div>
          </li>
          <!-- <f7-list-input
            name="Name"
            label="Name"
            type="text"
            placeholder="Your Full Name"
            floating-label
            :disabled="IsEdit == false"
            :outline="false"
            :value="DoctorProfile.Name"
            @input="DoctorProfile.Name = $event.target.value"
          >

          </f7-list-input>-->
          <f7-list-input
            name="Degree"
            label="Degree"
            type="text"
            placeholder="Your Degree"
            outline
            :disabled="IsEdit == false"
            required
            validate
            :value="DoctorProfile.Degree"
            @input="DoctorProfile.Degree = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            name="Speciality"
            label="Speciality"
            type="text"
            placeholder="Your Speciality"
            required
            validate
            :disabled="IsEdit == false"
            outline
            :value="DoctorProfile.Speciality"
            @input="DoctorProfile.Speciality = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            name="RegsistrationNumber"
            label="Registration Number"
            type="text"
            placeholder="Your Registration Number"
            required
            validate
            :disabled="IsEdit == false"
            outline
            :value="DoctorProfile.RegistrationNumber"
            @input="DoctorProfile.RegistrationNumber = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            name="Address"
            label="Address"
            type="text"
            placeholder="Your Work Address"
            required
            validate
            :disabled="IsEdit == false"
            outline
            :value="DoctorProfile.Address"
            @input="DoctorProfile.Address = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            name="Phone"
            label="Mobile Number (10 digit)"
            type="tel"
            placeholder="10 digit mobile number"
            required
            validate
            :disabled="IsEdit == false"
            outline
            pattern="^\d{10}$"
            :value="DoctorProfile.Phone"
            @input="DoctorProfile.Phone = $event.target.value"
          ></f7-list-input>
          <!-- <form id="image-form" action="#">
          <input id="mediaCapture" type="file" accept="image/*" />
          <button
            id="submitImage"
            title="Add an image"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--amber-400 mdl-color-text--white"
          >
            <i class="material-icons">image</i>
          </button>
          </form>Î-->
          <!-- <f7-list-input
          input-id="Email1"
          name="Email1"
          label="Username"
          type="text"
          placeholder="Your Email"
          floating-label
          required
          validate
          :value="DoctorProfile.Email"
          @input="DoctorProfile.Email = $event.target.value"
          autocomplete="false"
        ></f7-list-input>
        <f7-list-input
          name="Password1"
          label="Password"
          type="password"
          placeholder="Your password"
          floating-label
          required
          validate
          :value="DoctorProfile.Password"
          @input="DoctorProfile.Password = $event.target.value"
          ></f7-list-input>-->
        </f7-list>
      </form>
      <f7-block-title>Logo Image</f7-block-title>

      <div class="margin-small margin-bottom-large f-row f-vertical-center f-center">
        <!-- <div class="margin-bottom-medium" v-for="(LogoUrl, index) in LogoUrls" v-bind:key="index">
          <img crossorigin="anonymous" :src="LogoUrl" height="60" style="max-width:100px;" />
        </div>-->
        <div>
          <div v-if="LogoUrl && LogoUrl != ''" class="margin-bottom-medium">
            <img
              v-if="LogoUrl && LogoUrl != ''"
              :src="LogoUrl"
              height="80"
              style="max-width:200px;"
            />
          </div>
          <img
            class="margin-bottom-medium"
            v-if="LogoUrls.length == 0"
            height="120"
            style="max-width:200px"
            src="static/images/placeholder-h.png"
          />

          <firebase-file-upload
            @DoctorLogoUrl="fnUpdateDoctorLogoUrl"
            @DoctorLogoRemoved="fnDoctorLogoRemoved"
            v-bind:caller="'DoctorLogo'"
            v-bind:imageUrls="LogoUrls"
            v-bind:fileName="LogoFileName"
            v-bind:imagePathProp="LogoImagePath"
          ></firebase-file-upload>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");
import FirebaseFileUpload from "../shared/firebase-file-upload";
const doctorStore = require("../store/doctorStore.js");
const errorHandler = require("../shared/error-handler.js");
const doctorLogoStore = require("../store/doctorLogoStore.js");
const fileUtils = require("../shared/fileUtils.js");

export default {
  name: "doctor-profile",
  components: {
    FirebaseFileUpload,
  },
  created: function () {
    let that = this;
    this.DoctorProfile = this.userProfile;
    // console.log(this.DoctorProfile);
    // if (this.userProfile.SignatureUrl && this.userProfile.SignatureUrl != "") {
    //   this.SignatureUrls.push(this.userProfile.SignatureUrl);
    //   this.SignatureImagePath = this.userProfile.SignatureImagePath;
    //   // console.log(this.SignatureImagePath)
    // }
    // if (this.userProfile.LogoImageUrl && this.userProfile.LogoImageUrl != "") {
    //   this.LogoUrls.push(this.userProfile.LogoImageUrl);
    //   this.LogoImagePath = this.userProfile.LogoImageUrl;
    //   // console.log(this.SignatureImagePath)
    // }
    this.fnGetLogoImageUrl(function (imageUrl) {
      console.log(imageUrl);
      that.LogoUrl = imageUrl;
      that.LogoUrls.push(imageUrl);
    });
  },
  mounted: function () {
    this.elRegistrationForm = this.Dom7("#doctor-profile-form");
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  data: function () {
    return {
      elRegistrationForm: null,
      DoctorProfile: {},
      SignatureUrls: ([] = []),
      SignatureImagePath: "",
      LogoUrls: ([] = []),
      LogoImagePath: "DoctorLogos",
      IsEdit: false,
      LogoFileName: "",
      LogoUrl: "",
    };
  },
  methods: {
    fnDoctorLogoRemoved() {
      let that = this;
      doctorLogoStore.fnDeleteAllImages(
        this.DoctorProfile.ConsultantId,
        function () {
          that.LogoUrl = "";
          that.LogoUrls = [];
          that.LogoFileName = "";
        }
      );
    },
    fnValidateRegistrationForm() {
      console.log(this.elRegistrationForm);
      if (this.elRegistrationForm && this.elRegistrationForm[0]) {
        return this.elRegistrationForm[0].checkValidity();
      }
    },
    fnUpdateDoctorLogoUrl(data) {
      let that = this;
      setTimeout(function () {
        console.log("Trying to fetch file");
        that.fnGetLogoImageUrl(function (imageUrl) {
          that.LogoUrl = imageUrl;
          console.log(imageUrl);
          that.LogoUrls.push(imageUrl);
        });
      }, 2000);

      //console.log(url);
      // fireStore
      //   .doctors(firebase)
      //   .doc(this.currentUser.uid)
      //   .update({
      //     SignatureUrl: data.downloadUrl,
      //     SignatureImagePath: data.imagePath
      //   })
      //   .then(() => {
      //     this.$store.dispatch("fetchDoctorProfile");
      //   });
    },
    fnUpdateDoctorSignatureUrl(data) {
      //console.log(url);
      fireStore
        .doctors(firebase)
        .doc(this.currentUser.uid)
        .update({
          SignatureUrl: data.downloadUrl,
          SignatureImagePath: data.imagePath,
        })
        .then(() => {
          this.$store.dispatch("fetchDoctorProfile");
        });
    },
    fnUpdateDoctorProfile() {
      console.log(this.currentUser.uid);
      this.DoctorProfile.ConsultantId = this.currentUser.uid;
      let that = this;
      doctorStore.fnUpdateDoctor(this.DoctorProfile, function () {
        that.IsEdit = false;
        that.$store.dispatch("fetchDoctorProfile");
        // that.DoctorProfile = that.userProfile;

        errorHandler.fnRaiseSuccess();
      });
      // fireStore
      //   .doctors(firebase)
      //   .doc(this.currentUser.uid)
      //   .update({
      //     Name: this.DoctorProfile.Name,
      //     Degree: this.DoctorProfile.Degree,
      //     Speciality: this.DoctorProfile.Speciality,
      //     RegistrationNumber: this.DoctorProfile.RegistrationNumber,
      //     Address: this.DoctorProfile.Address,
      //     Phone: this.DoctorProfile.Phone
      //   })
      //   .then(() => {
      //     this.$store.dispatch("fetchDoctorProfile");
      //   });
    },
    fnGetLogoImageUrl(fnCallBack) {
      let imageUrl = "";
      let fileName = "";
      let that = this;
      doctorLogoStore.fnGetImage(this.DoctorProfile.ConsultantId, function (
        items
      ) {
        console.log("Fetched Items " + items.length);
        // console.log("Items " + items.length);
        if (items && items.length > 0) {
          console.log(items[0]);

          fileName = items[0].FileName;
          console.log(fileName);
          that.LogoFileName = fileName;
          if (items[0].DeviceUrl != "" && items[0].DeviceUrl != null) {
            fileUtils.fnGetImageUrl(fileName, "DoctorLogos", "App", fnCallBack);
            // fnCallBack(items[0].StorageLocationDevice + fileName);
          } else {
            fnCallBack(items[0].StorageLocationServer);
          }
        }
        // console.log("Fetched Items " + items.length);
        // // console.log("Items " + items.length);
        // if (items && items.length > 0) {
        //   console.log(items[0]);

        //   fileName = items[0].FileName;
        //   // console.log(fileName);
        //   that.LogoFileName = fileName;

        //   if (items[0].DeviceUrl != "" && items[0].DeviceUrl != null) {
        //     fileUtils.fnGetImageUrl(fileName, "DoctorLogos", "App", fnCallBack);
        //     // fnCallBack(items[0].StorageLocationDevice + fileName);
        //   } else {
        //     fnCallBack(items[0].StorageLocationServer);
        //   }
        // }
      });
    },
  },
};
</script>
