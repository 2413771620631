import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");
const patientNameStore = require("../store/patientNameStore.js");
const patientEmailIdStore = require("../store/patientEmailIdStore.js");
const patientPhoneStore = require("../store/patientPhoneStore.js");


const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "Patients";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const encryptedProps = props.filter(t => t.isEncrypted == true);
const syncService = require('../services/syncService.js')
const dbLocal = require('./dbLocal.js');

const fnInsertPatient = function (patient, fnSuccessCallback, fnFailureCallBack) {
    let _patient = {
        Name: patient.Name,
        Email: patient.Email,
        Phone: patient.Phone
    };
    patient.IsSynced = 0;
    // patient.IsDownloadSyncPendingDoctor = 1;
    // patient.IsDownloadSyncPendingReception = 1;
    patient.IsActive = 1;
    patient.IsNewPatient = 1;
    patient.ServerTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    // let serialNumber = "";
    // let fnSerialNoCallback = function (number) {
    //     console.log(number)
    //     serialNumber = number;
    // }

    // let fnSerialNumberSuccessCallback = function (serialNumber) {
    //     console.log(serialNumber);
    patient.PhoneHash = "NoHash"
    // console.log("Insert patient", patient);

    if (patient.Phone) {
        patient.PhoneHash = dbUtils.fnHash(patient.Phone);
    }
    // console.log("Insert patient", patient);

    patient.CreatedPlatform = "Web";

    patient.PatientUID = dbUtils.fnGenerateUniqueStringId();
    patient.CreatedDate = (new Date()).toUTCString();
    patient.CreatedDateMs = new Date(patient.CreatedDate).getTime();
    patient.LastUpdatedOn = new Date(patient.CreatedDate).getTime();


    dbUtils.fnTrimTextOnObject(patient, props);

    dbUtils.fnEncryptFromProps(patient, encryptedProps);

    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(patient, props) + ')';
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                patientNameStore.fnInsertPatientName({
                    Name: _patient.Name
                });
                patientEmailIdStore.fnInsertPatientEmailId({
                    Email: _patient.Email
                });
                patientPhoneStore.fnInsertPatientPhone({
                    Phone: _patient.Phone
                })
                console.log("Initiating Sync Service")
                syncService.fnInitiateSync(tableName, 'patients')

                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .patients(firebase)
            .doc(patient[primaryKeyName])
            .set(patient)
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }
    // }
    // fnSerialNumberSuccessCallback(dbUtils.generateUID());
    // dbLocal.fnGetNextSerialNumber(primaryKeyName, "Patients", "patients", "PatientUID", "CreatedDate", "desc", props, fnSerialNumberSuccessCallback);


}
const fnUpdatePatient = function (patient, fnSuccessCallback, fnFailureCallBack) {
    // patient.IsDownloadSyncPendingDoctor = 1;
    // patient.IsDownloadSyncPendingReception = 1;
    // console.log(patient);

    dbUtils.fnEncryptFromProps(patient, encryptedProps);

    // console.log(patient);
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, patient)
            + ' WHERE ' + primaryKeyName + " = '" + patient[primaryKeyName] + "'";
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        let updatablePatient = dbUtils.fnGetUpdatableObject(updatableProps, patient);
        updatablePatient.ServerTimestamp = firebase.firestore.FieldValue.serverTimestamp();
        fireStore
            .patients(firebase)
            .doc(patient[primaryKeyName])
            .update(updatablePatient)
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
                if (fnFailureCallBack) {
                    fnFailureCallBack();
                }
            });
    }

}

const fnGetPatients = function (doctorId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? ", [doctorId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props, encryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore
            .patients(firebase, doctorId)
            .get()
            .then(items => {
                // console.log("Patients Found");
                let patients = [];
                items.forEach(doc => {
                    if (doc) {
                        patients.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps));
                    }
                });
                if (fnSuccessCallback) {
                    fnSuccessCallback(patients);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}

const fnGetPatient = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? AND " + primaryKeyName + " = ?", [doctorId, patientId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props, encryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .patients(firebase)
            .doc(patientId)
            .get()
            .then(doc => {
                // console.log("Doctor Found");
                console.log(doc.data());
                console.log(encryptedProps)
                console.log(props)
                let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
                console.log("result");
                console.log(result);
                if (fnSuccessCallback) {
                    fnSuccessCallback(result);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}
const fnGetPatientWithSnapshot = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
    return fireStore
        .patients(firebase)
        .doc(patientId)
        .onSnapshot((doc) => {
            if (!doc.metadata.hasPendingWrites) {
                let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
                // console.log("result");
                // console.log(result);
                if (fnSuccessCallback) {
                    fnSuccessCallback(result);
                }
            }

        }, function (error) {
            console.log("Error getting documents: ", error);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        });
}


const fnGetPatientByFirebaseUID = function (doctorId, patientId, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? AND PatientFirebaseUID = ?", [doctorId, patientId], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props, encryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .patients(firebase, doctorId, patientId)
            .get()
            .then(items => {
                console.log("Patient Found");
                // console.log(doc.data());
                // console.log(encryptedProps)
                // console.log(props)
                // let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
                // console.log("result");
                // console.log(result);
                // if (fnSuccessCallback) {
                //     fnSuccessCallback(result);
                // }

                let results = [];
                items.forEach(doc => {
                    // console.log(doc.date())

                    if (doc) {
                        results.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps));
                    }
                });
                if (fnSuccessCallback && results.length > 0) {
                    console.log(results[0])
                    fnSuccessCallback(results[0]);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}

const fnGetPatientByPhone = function (doctorId, phone, fnSuccessCallback, fnFailureCallBack) {
    // console.log(phone);

    phone = dbUtils.fnHash(phone);
    // console.log(phone);
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {

            tx.executeSql("SELECT * FROM " + tableName + " WHERE DoctorId = ? AND Phone = ?", [doctorId, phone], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props, encryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStore
            .patients(firebase, doctorId, null, phone)
            .get()
            .then(items => {
                // console.log("Doctor Found");
                // console.log(doc.data());
                // console.log(encryptedProps)
                // console.log(props)
                // let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
                // console.log("result");
                // console.log(result);
                // if (fnSuccessCallback) {
                //     fnSuccessCallback(result);
                // }

                let results = [];
                items.forEach(doc => {
                    if (doc) {
                        results.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps));
                    }
                });
                if (fnSuccessCallback && results.length > 0) {
                    fnSuccessCallback(results);
                } else {
                    if (fnFailureCallBack) {
                        fnFailureCallBack(403);
                    }
                }


            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

}

const fnGetPatientsByPhone = function (doctorId, phone, fnSuccessCallback, fnFailureCallBack) {
    // console.log(phone);

    phone = dbUtils.fnHash(phone);
    // console.log(phone);

    fireStore
        .patients(firebase, doctorId, null, phone)
        .get()
        .then(items => {
            // console.log("Doctor Found");
            // console.log(doc.data());
            // console.log(encryptedProps)
            // console.log(props)
            // let result = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
            // console.log("result");
            // console.log(result);
            // if (fnSuccessCallback) {
            //     fnSuccessCallback(result);
            // }

            let results = [];
            items.forEach(doc => {
                if (doc) {
                    results.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps));
                }
            });
            if (fnSuccessCallback && results.length > 0) {
                fnSuccessCallback(results);
            } else {
                if (fnFailureCallBack) {
                    fnFailureCallBack(403);
                }
            }


        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
        });


}

export {
    fnInsertPatient,
    fnGetPatients,
    fnUpdatePatient,
    fnGetPatient,
    fnGetPatientByFirebaseUID,
    fnGetPatientByPhone,
    fnGetPatientWithSnapshot,
    fnGetPatientsByPhone
}