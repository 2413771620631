// Import Vue
import Vue from 'vue';
import VueFlashMessage from "vue-flash-message";
Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
    important: true,

  }
});
// import VueSignaturePad from 'vue-signature-pad';

// Vue.use(VueSignaturePad);
// Import Framework7
import Framework7, { Dom7 } from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import { store } from '../store/vuexStore.js'
// const fb = require('../store/firebaseStore.js')

// Import App Component
import App from '../components/app.vue';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

const localDBStore = require('../store/dbLocal.js');
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
// new Vue({
//   el: '#app',
//   store,
//   render: (h) => h(App),

//   // Register App Component
//   components: {
//     app: App
//   },
// });

// let app
// fb.auth.onAuthStateChanged(user => {
// if (!app) {
// var localDB = null;
// document.addEventListener('deviceready', function () {
//   console.log('device ready');
//   localDB = localDBStore.fnInitDB();
//   window.localDB = localDB;
// });
// import firebaseMessaging from './firebaseMessaging'

// Vue.prototype.$firebaseMessaging = firebaseMessaging;

let app = new Vue({
  el: '#app',
  store,
  render: h => h(App),
  // Register App Component
  components: {
    app: App
  },
})

// }
// })