<template>
  <div>
    <!-- <f7-link href="#" @click.native="selectFile" v-if="!uploadEnd && !uploading">Upload Signature</f7-link> -->

    <form ref="form">
      <input
        id="files"
        type="file"
        name="file"
        ref="uploadInput"
        accept="image/*"
        :multiple="false"
        @change="detectFiles($event)"
      />
    </form>
    <!-- <f7-progressbar
      v-if="fileName && fileName != '' && uploading && !uploadEnd"
      :progress="progressUpload"
    ></f7-progressbar> -->
    <!-- <img v-if="uploadEnd" :src="downloadURL" width="100%" /> -->
    <div v-if="fileName && fileName != ''">
      <!-- <f7-link href="#" class="ma-0" dark small color="error" @click="deleteImage()">Delete</f7-link> -->
      <f7-button fill dark small color="error" @click="deleteImage()">Delete</f7-button>
    </div>
    <!-- <f7-link v-else href="#" @click.native="selectFile">Upload Signature</f7-link> -->
    <f7-button fill color="green" v-else @click.native="selectFile">Upload Image File</f7-button>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");
const fileUtils = require("../shared/fileUtils.js");
const dbUtils = require("../store/dbUtils.js");
const doctorLogoStore = require("../store/doctorLogoStore.js");
import { mapState } from "vuex";

export default {
  data() {
    return {
      progressUpload: 0,
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      imagePath: ""
      // downloadURL: "",
    };
  },
  props: {
    caller: "",
    fileName: "",
    imageUrls: Array,
    imagePathProp: ""
  },
  computed: {
    ...mapState(["currentUser"])
  },
  mounted: function() {
    this.imagePath = this.imagePathProp;
    // console.log(this.imagePath);
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      });
    },
    timestamp() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "" +
        (today.getMonth() + 1) +
        "" +
        today.getDate();
      let time =
        today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      return date + "" + time;
    },
    upload(file) {
      let that = this;
      // this.fileName = file.name;
      let folder = this.imagePathProp;
      let _fileName = this.currentUser.uid + "-" + "Logo.png";
      // this.imagePath = this.path + "/" + this.timestamp() + "-" + this.fileName;
      // console.log(this.imagePath);
      this.uploading = true;
      fileUtils.fnSaveImage(
        file,
        null,
        folder,
        _fileName,
        function(deviceLocation, serverLocation) {
          console.log("File Save Success");
          console.log(_fileName);
          let logoImage = {
            DoctorLogoId: dbUtils.generateUID(),
            DoctorId: that.currentUser.uid,
            FileName: _fileName,
            DeviceUrl: deviceLocation + folder + "/"
          };
          // that.imageUrls.push(downloadURL);

          console.log("Storage Exact Path: " + logoImage.DeviceUrl);
          doctorLogoStore.fnInsert(logoImage, function() {
            that.$emit(that.caller + "Url", null);
          });
        },
        null,
        "App"
      );
      // this.uploadTask = fireStore
      //   .fireStorage(firebase)
      //   .ref(this.imagePath)
      //   .put(file);
    },
    deleteImage() {
      console.log(this.imagePathProp);
      console.log(this.fileName);
      let that = this;
      fileUtils.fnDeleteImage(
        this.fileName,
        this.imagePathProp,
        "App",
        function() {
          console.log("Image deleted successfully");
          that.uploading = false;
          that.uploadEnd = false;
          // that.imageUrls.splice(that.imageUrls.indexOf(that.imagePath), 1);
          that.$emit(that.caller + "Removed", null);
        }
      );
      // fireStore
      //   .fireStorage(firebase)
      //   .ref(this.imagePath)
      //   .delete()
      //   .then(() => {
      //     that.uploading = false;
      //     that.uploadEnd = false;
      //     that.imageUrls.splice(that.imageUrls.indexOf(that.imagePath), 1);
      //     this.$emit(this.caller + "Url", {
      //       downloadUrl: "",
      //       imagePath: ""
      //     });
      //   })
      //   .catch(error => {
      //     console.error(`file delete error occured: ${error}`);
      //   });
      this.$refs.form.reset();
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            // this.downloadURL = downloadURL;
            this.imageUrls.push(downloadURL);
            this.$emit(this.caller + "Url", {
              downloadUrl: downloadURL,
              imagePath: this.imagePath
            });
          });
        }
      );
    }
  }
};
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>