<template>
  <div>
    <f7-block-title
      class="f-row f-space-between f-vertical-center background-secondary"
    >
      <span>Chats</span>

      <f7-button
        raised
        small
        color="green"
        @click="fnOpenCreateNewChatModal()"
        class="background-white"
      >
        <span class="f-row f-vertical-center">
          <f7-icon
            size="14"
            class="margin-right-xsmall"
            material="chat"
          ></f7-icon
          >New Chat
        </span>
      </f7-button>
    </f7-block-title>
    <div
      v-if="Chats.length > 0"
      class="f-row w-100 margin-small overflow-x-auto overflow-hidden-y"
    >
      <div
        class="margin-right-small"
        v-for="(Chat, index) in Chats"
        :key="index"
      >
        <div
          style="height: 62px; width: 60px"
          @click="fnGoToChatMessages(Chat)"
        >
          <div
            class="f-column f-center"
            style="
              height: 60px;
              width: 60px;
              border: 1px solid gray;
              border-radius: 4px;
            "
          >
            <div class="w-100 f-row f-center">
              <f7-icon size="12" material="chat"></f7-icon>
            </div>
            <div style="margin-left: 2px">
              <div class="font-xsmall">{{ fnGetChatStatus(Chat.Status) }}</div>
              <div class="font-xsmall">
                {{ Chat.CreatedDateMs | formatDate }}
              </div>
              <div class="font-xsmall">
                {{ Chat.CreatedDateMs | formatTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <f7-list v-else no-hairlines>
      <f7-list-item>No chats found</f7-list-item>
    </f7-list>
    <!-- <f7-list no-hairlines v-if="Chats.length == 0">
      <f7-list-item><div>No chats found</div></f7-list-item>
    </f7-list>
    <div class="f-grow overflow-y-auto-no-height" v-else>
      <f7-list no-hairlines class="item-media-small">
        <f7-list-item
          v-for="(Chat, index) in Chats"
          v-bind:key="index"
          link="#"
          @click="fnGoToChatMessages(Chat)"
        >
          <div slot="media">
            <f7-icon
              size="18"
              class="text-primary"
              material="assignment"
            ></f7-icon>
          </div>
          <div>
            <div>
              <span>Chat Record {{ ChatsCount - index }}</span>
            </div>
            <div class="font-xsmall text-secondary">
              {{ Chat.CreatedOn | formatDate }}
            </div>
          </div>
        </f7-list-item>
      </f7-list>
    </div> -->
  </div>
</template>
<script>
const chatsStore = require("../store/chatsStore.js");
const errorHandler = require("../shared/error-handler.js");
const dbUtils = require("../store/dbUtils.js");
const utils = require("../shared/utils.js");
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "chats",

  data() {
    return {
      Chats: [],
      ChatsCount: 0,
    };
  },
  computed: {
    ...mapState(["__CurrentPatient", "currentUser"]),
  },
  mounted() {
    this.fnGetChats();
  },
  methods: {
    fnOpenCreateNewChatModal() {
      let that = this;
      errorHandler.fnRaiseConfirmDialogWithCallback(
        "Initiate New Chat?",
        "Do you want to initiate a new chat with the doctor?",
        function () {
          let dialog = f7App.dialog.preloader("Initiating new chat");
          that.fnCreateNewChat(dialog);
        }
      );
    },
     fnGetChatStatus(status) {
      switch (status) {
        case 1:
          return "On Hold";
        case 2:
          return "In Progress";
        case 3:
          return "Closed";
      }
    },
    fnCreateNewChat(dialog) {
      let that = this;
      let chat = {
        ChatId: dbUtils.generateUID(),
        DoctorId: this.currentUser.uid,
        PatientId: this.__CurrentPatient.PatientId,
        Status: 2,
      };
      chatsStore.fnInsertChat(
        chat,
        function () {
          dialog.close();
          that.fnGoToChatMessages(chat);
        },
        function () {
          dialog.close();

          errorHandler.fnRaiseError();
        }
      );
    },
    fnGoToChatMessages(chat) {
      this.$f7router.navigate("/chat-messages/", {
        props: { chatId: chat.ChatId },
      });
    },
    fnGetChats() {
      this.Chats = [];
      let that = this;
      chatsStore.fnGetPatientChats(
        this.currentUser.uid,
        this.__CurrentPatient.PatientId,
        function (items) {
          if (items && items.length > 0) {
            items.forEach((item) => {
              let replacedArray = utils.fnReplaceItemInArray(
                that.Chats,
                item,
                "ChatId"
              );

              let uniqueArray = utils.fnUniqueUnion(
                replacedArray,
                [item],
                "ChatId"
              );
              that.Chats = Array.from(uniqueArray);
            });
          }
          that.ChatsCount = that.Chats.length;
        },
        function () {
          errorHandler.fnRaiseError();
        }
      );
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD-MM-YY");
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("HH:mm");
    },
  },
};
</script>