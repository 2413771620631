import { _ } from 'core-js';

const _array = require('lodash/array');
// const _collection = require('lodash/collection');

const fnJoinArrayField = function (array, fieldName, separator) {
    let joinedString = "";
    array.forEach((item, index) => {
        if (index > 0) {
            joinedString = joinedString + separator + item[fieldName];
        } else {
            joinedString = item[fieldName];
        }
    });
    return joinedString;
}


const fnTrimText = function (text) {
    if (text && text != null && text != '') {
        return text.trim();
    }
    return text;
}
const fnBeautifyText = function (text, prefix, suffix) {
    if (text && text != null && text != '') {
        if (prefix) {
            text = prefix + text;
        }
        if (suffix) {
            text = text + suffix;
        }
        return text;
    }
    return "";
}

const fnConvertNumberToBool = function (number) {
    if (number === 1 || number === "1") {
        return true;
    }
    return false;
}

const fnReplaceItemInArray = function (array, item, key) {
    if (array) {
        let i = 0;
        for (i = 0; i < array.length; i++) {
            if (array[i][key] == item[key]) {
                array[i] = item;
            }
        }
    }
    return Array.from(array);
}
const fnUniqueUnion = function (array1, array2, matcher) {

    let concated = array1.concat(array2);
    return Array.from(_array.uniqBy(concated, matcher));

}
const fnSortArrayAscending = function (arrayToBeSorted, keyForSorting) {
    let mapped = arrayToBeSorted.map(function (el, i) {
        return { index: i, value: el[keyForSorting] };
    })

    // sorting the mapped array containing the reduced values
    mapped.sort(function (a, b) {
        if (!a.value) {
            return -1;
        }
        if (!b.value) {
            return 1;
        }
        if (typeof a.value === 'string' || a.value instanceof String) {
            if (a.value.toLowerCase() >= b.value.toLowerCase()) {
                return 1;
            } else {
                return -1;
            }

        } else {
            if (a.value >= b.value) {
                return 1;
            } else {
                return -1;
            }

        }

        // return 0;
    });

    // container for the resulting order
    let result = mapped.map(function (el) {
        return arrayToBeSorted[el.index];
    });
    return result;
}
const fnSortArrayDescending = function (arrayToBeSorted, keyForSorting) {
    let mapped = arrayToBeSorted.map(function (el, i) {
        return { index: i, value: el[keyForSorting] };
    })

    // sorting the mapped array containing the reduced values
    mapped.sort(function (a, b) {
        if (!a.value) {
            return 1;
        }
        if (!b.value) {
            return -1;
        }
        if (typeof a.value === 'string' || a.value instanceof String) {
            if (a.value.toLowerCase() >= b.value.toLowerCase()) {
                return -1;
            } else {
                return 1;

            }

        } else {
            if (a.value >= b.value) {
                return -1;
            } else {
                return 1;
            }

        }

        // return 0;
    });

    // container for the resulting order
    let result = mapped.map(function (el) {
        return arrayToBeSorted[el.index];
    });
    return result;
}
const fnRemoveDuplicates = function (array1, key) {
    if (key) {
        return _array.uniqBy(array1, key);
    }
    return _array.uniqBy(array1);
}
export {
    fnJoinArrayField,
    _array,
    fnTrimText,
    fnBeautifyText,
    fnConvertNumberToBool,
    fnReplaceItemInArray,
    fnUniqueUnion,
    fnSortArrayAscending,
    fnSortArrayDescending,
    fnRemoveDuplicates
    // _collection
}