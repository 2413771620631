


import NotFoundPage from '../components/404.vue';

import Home from '../components/home.vue';

import DoctorProfile from '../components/doctor-profile.vue';

import PatientRegister from '../components/patient-register.vue';
import PatientView from '../components/patient-view.vue';
import AppointmentRequest from '../components/appointment-request.vue';




import MedicalRecordPreviewHolder from '../components/medical-record-preview-holder.vue';


import PatientFile from '../components/patient-file.vue';
import PaymentConfirm from '../components/payment-confirm.vue';


import Chats from '../components/chats.vue';
import ChatMessages from '../components/chat-messages.vue';
import PaymentGateway from '../components/payment-gateway.vue';
import PrivacyPolicy from '../components/privacy-policy.vue';
import RefundPolicy from '../components/refund-policy.vue';
import TermsConditions from '../components/terms-conditions.vue';
import AboutUs from '../components/about-us.vue';
import ContactUs from '../components/contact-us.vue';
import SelectDoctor from '../components/select-doctor.vue';
// import Landing from '../components/landing.vue';


var routes = [

  {
    path: '/home/:routeTab/',
    component: Home,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/doctor-profile/',
    component: DoctorProfile,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
    // viewName: "home"
    // options: {
    //   history: false
    // }
  },

  {
    path: '/file/:did/:pid/',
    component: PatientFile,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,

      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/patient-create/:IsEdit?/:IsAnotherAccount?/',
    component: PatientRegister,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/patient-view/:viewTab?/',
    component: PatientView,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      props: {
        prevPage: "",
        patientId: "",
      },
      // transition: 'f7-cover',
    }

  },
  {
    path: '/appointment-request/',
    component: AppointmentRequest,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,


    }

  },
  {
    path: '/payment-confirm/:appointmentId/',
    component: PaymentConfirm,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,

    }

  },
 
 
  {
    path: '/medical-record-view/',
    component: MedicalRecordPreviewHolder,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/payment-gateway/',
    component: PaymentGateway,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/select-doctor/',
    component: SelectDoctor,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      props: {
        patientPhone: String,
      },
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/dr/:uniqueName/:pid/',
    component: PatientFile,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true
    }
  },
  {
    path: '/dr/:uniqueName/',
    component: PatientFile,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true
    }
  },
  {
    path: '/privacyPolicy',
    name:"privacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: '/refundPolicy',
    name:"refundPolicy",
    component: RefundPolicy
  },
  {
    path: '/termsConditions',
    name:"termsConditions",
    component: TermsConditions
  },
  {
    path: '/aboutUs',
    name:"aboutUs",
    component: AboutUs
  },
  {
    path: '/contactUs',
    name:"contactUs",
    component: ContactUs
  },
  {
    path: '/:uniqueName',
    component: PatientFile,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true
    }
  },
  {
    path: '/chats/',
    component: Chats,
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    }
  },
  {
    path: '/chat-messages/',
    component: ChatMessages,
    props: {
      chatId: null
    },
    options: {
      history: false,
      pushState: false,
      reloadAll: true,
      // transition: 'f7-cover',
      // animate: true

    },
    
  },
  
  {
    path:"/",
    name:"landing",
    component: PatientFile
  },
  

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
