

const fnGetBaseUrlForPublicUrl = function (message) {
    return "https://scripty.in/"
}



export {
    fnGetBaseUrlForPublicUrl
}