<template>
  <f7-page id="medical-record-preview" name="view-prescription">
    <f7-navbar title="Prescription">
      <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        href="/patient-view/3/"
      ></f7-link>
      <!-- <f7-link slot="right" @click="$refs.prescriptionActions.open()">Actions</f7-link> -->
    </f7-navbar>
    <div
      v-if="MedicalRecordPreview != null"
      id="prescription"
      class="h-100 overflow-hidden-y"
    >
      <medical-record-preview
        v-bind:MedicalRecord="MedicalRecordPreview"
        v-bind:IsCreatePreview="false"
        v-bind:IsCreateHome="false"
        v-bind:PreviewType="PreviewType"
      ></medical-record-preview>
    </div>
    <f7-toolbar position="bottom">
      <f7-button
        @click="$refs.filterActions.open()"
        raised
        fill
        small
        color="white"
        class="margin-left-small"
      >
        <span class="f-row f-vertical-center">Preview Type</span>
      </f7-button>
      <f7-button
        @click="$refs.prescriptionActions.open()"
        raised
        fill
        small
        color="white"
        class="margin-right-small"
      >
        <span class="f-row f-vertical-center">Actions</span>
      </f7-button>
    </f7-toolbar>
    <f7-actions
      @actions:closed="fnPrescriptionActionsClose()"
      ref="prescriptionActions"
    >
      <f7-actions-group>
        <f7-actions-label>Actions</f7-actions-label>
        <!-- <f7-actions-button @click="fnPrintMedicalRecord()"
          >Print</f7-actions-button
        >
        <f7-actions-button @click="fnEmailMedicalRecord()"
          >Share via Email</f7-actions-button
        >
        <f7-actions-button @click="fnSMSPrescription()"
          >Share via SMS</f7-actions-button
        >
        <f7-actions-button @click="fnCopyPrescriptionToNew()"
          >Copy to New Medical Record</f7-actions-button
        > -->
        <f7-actions-button @click="fnDownloadImage()"
          >Download as Image</f7-actions-button
        >
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="filterActions">
      <f7-actions-group>
        <f7-actions-label>Preview Type</f7-actions-label>

        <f7-actions-button @click="fnSetPreviewType(1)">
          <span>
            <f7-icon
              size="18"
              v-if="PreviewType == 1"
              class="margin-right-xsmall"
              material="done"
            ></f7-icon>
          </span>
          Entire Prescription
        </f7-actions-button>
        <f7-actions-button @click="fnSetPreviewType(2)">
          <span>
            <f7-icon
              size="18"
              v-if="PreviewType == 2"
              class="margin-right-xsmall"
              material="done"
            ></f7-icon> </span
          >Medicines
        </f7-actions-button>
        <f7-actions-button @click="fnSetPreviewType(3)">
          <span>
            <f7-icon
              size="18"
              v-if="PreviewType == 3"
              class="margin-right-xsmall"
              material="done"
            ></f7-icon> </span
          >Visit Notes
        </f7-actions-button>
        <f7-actions-button @click="fnSetPreviewType(4)">
          <span>
            <f7-icon
              size="18"
              v-if="PreviewType == 4"
              class="margin-right-xsmall"
              material="done"
            ></f7-icon> </span
          >Investigations
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>

<script>
import { mapState } from "vuex";
const PrescriptionModel = require("../models/Prescription.js");
import MedicalRecordPreview from "../components/medical-record-preview.vue";
import html2canvas from "html2canvas";
const medicalRecordImagesStore = require("../store/medicalRecordImagesStore.js");
const dbUtils = require("../store/dbUtils.js");
const utils = require("../shared/utils.js");
const fileUtils = require("../shared/fileUtils.js");
const errorHandler = require("../shared/error-handler.js");
const doctorLogoStore = require("../store/doctorLogoStore.js");
import { saveAs } from "file-saver";

import moment from "moment";

export default {
  name: "medical-record-preview-holder",
  components: { MedicalRecordPreview },
  data: function () {
    return {
      MedicalRecordPreview: null,
      HiddenNotesList: ([] = []),
      NotesList: ([] = []),
      MedicinesList: ([] = []),
      AdvicesList: ([] = []),
      IsCreatePreview: false,
      PrintCount: 0,
      CurrentPatient: null,
      PreviewType: 1,
      OutputFileName: "",
      medicalRecordImageUrl: "",
      IsCopyToNew: false,
      homeLink: "",
    };
  },
  props: {},

  computed: {
    ...mapState(["__CurrentMedicalRecord", "__CurrentPatient", "currentUser"]),
  },
  mounted: function () {
    this.fnResetStatusBarForiOS();
    // console.log("this.__CurrentMedicalRecord");
    // console.log(this.__CurrentMedicalRecord);
    this.MedicalRecordPreview = this.__CurrentMedicalRecord;
    this.homeLink =
      "/file/" +
      this.currentUser.uid +
      "/" +
      this.__CurrentPatient.PatientId +
      "/";
    this.CurrentPatient = this.__CurrentPatient;
    this.$store.commit("setCopiedMedicalRecord", null);
    this.fnSetPreviewType(1);
  },
  methods: {
    fnResetStatusBarForiOS() {
      if (window.isCordova == true && device && device.platform == "iOS") {
        StatusBar.hide();
        StatusBar.show();
      }
    },
    fnSetPreviewType(type) {
      this.PreviewType = type;
      if (this.PreviewType == "1") {
        this.OutputFileName =
          "MedicalRecord-Complete-" +
          this.MedicalRecordPreview.PatientUID +
          "-" +
          this.MedicalRecordPreview.MedicalRecordUID;
      } else if (this.PreviewType == "2") {
        this.OutputFileName =
          "MedicalRecord-Prescription-" +
          this.MedicalRecordPreview.PatientUID +
          "-" +
          this.MedicalRecordPreview.MedicalRecordUID;
      } else if (this.PreviewType == "3") {
        this.OutputFileName =
          "MedicalRecord-VisitNotes-" +
          this.MedicalRecordPreview.PatientUID +
          "-" +
          this.MedicalRecordPreview.MedicalRecordUID;
      } else if (this.PreviewType == "4") {
        this.OutputFileName =
          "MedicalRecord-VisitNotes-" +
          this.MedicalRecordPreview.PatientUID +
          "-" +
          this.MedicalRecordPreview.MedicalRecordUID;
      }
    },
    fnPrescriptionActionsClose() {
      console.log("Prescription Actions Closed");
      if (this.IsCopyToNew == true) {
        this.fnNavigateToNewPrescription();
      }
    },
    fnCopyPrescriptionToNew() {
      this.IsCopyToNew = true;
      this.$refs.prescriptionActions.close();
    },
    fnNavigateToNewPrescription() {
      this.$store.commit("setCopiedMedicalRecord", this.MedicalRecordPreview);
      this.$f7router.navigate("/medical-record-create/1/");
    },
    fnBuildTextPrescription() {
      let smsPrescriptionText = "";
      let patientInfo =
        "Patient Name: " + this.MedicalRecordPreview.PatientName;
      if (
        this.MedicalRecordPreview.PatientAge &&
        this.MedicalRecordPreview.PatientAge != ""
      ) {
        patientInfo +=
          "\n" + "Age: " + this.MedicalRecordPreview.PatientAge + "yrs";
      }
      patientInfo +=
        "\n" + "Gender: " + this.MedicalRecordPreview.PatientGender;
      patientInfo +=
        "\n" + "Address: " + this.MedicalRecordPreview.PatientAddress;
      patientInfo +=
        "\n" + "Date: " + this.formatDate(this.MedicalRecordPreview.Date);
      let MedicinesList = [],
        AdvicesList = [],
        InvestigationsList = [],
        ClinicalNotesList = [],
        DiagnosisList = [];
      let Medicines = "",
        Advices = "",
        ClinicalNotes = "",
        Diagnosis = "",
        Investigations = "",
        FollowUpDate = "",
        PhysicalExam = "";

      PhysicalExam = utils.fnBeautifyText(
        this.MedicalRecordPreview.Height,
        "\nHt: ",
        " cm"
      );
      PhysicalExam =
        PhysicalExam +
        utils.fnBeautifyText(this.MedicalRecordPreview.Weight, "\nWt: ", " kg");
      PhysicalExam =
        PhysicalExam +
        utils.fnBeautifyText(
          this.MedicalRecordPreview.PulseRate,
          "\nPulse: ",
          " bpm"
        );
      PhysicalExam =
        PhysicalExam +
        utils.fnBeautifyText(
          this.MedicalRecordPreview.Temperature,
          "\nTemp: ",
          " F"
        );

      if (this.MedicalRecordPreview.Medicines) {
        MedicinesList = JSON.parse(this.MedicalRecordPreview.Medicines);
        let strMedicine = "";
        MedicinesList.forEach((medicine) => {
          strMedicine = utils.fnBeautifyText(medicine.Brand);
          if (
            medicine.Brand &&
            medicine.Brand != null &&
            medicine.Brand != ""
          ) {
            strMedicine =
              strMedicine + utils.fnBeautifyText(medicine.Generic, " ");
          } else {
            strMedicine = strMedicine + utils.fnBeautifyText(medicine.Generic);
          }
          strMedicine = strMedicine + utils.fnBeautifyText(medicine.Route, " ");
          strMedicine =
            strMedicine + utils.fnBeautifyText(medicine.Frequency, " ");
          strMedicine =
            strMedicine + utils.fnBeautifyText(medicine.Duration, " ");
          strMedicine =
            strMedicine + utils.fnBeautifyText(medicine.Quantity, " Qty: ");
          if (medicine.Refill && medicine.Refill != "") {
            strMedicine =
              strMedicine + utils.fnBeautifyText(medicine.Refill, " ");
          }
          Medicines = Medicines + strMedicine + " \n";
        });
        // Medicines = MedicinesList.join("\n");
      }
      if (this.MedicalRecordPreview.VisibleNotes) {
        ClinicalNotesList = this.MedicalRecordPreview.VisibleNotes.split(";");
        ClinicalNotes = ClinicalNotesList.join("\n");
      }
      if (this.MedicalRecordPreview.Diagnosis) {
        DiagnosisList = this.MedicalRecordPreview.Diagnosis.split(";");
        Diagnosis = DiagnosisList.join("\n");
      }
      if (this.MedicalRecordPreview.Advices) {
        AdvicesList = this.MedicalRecordPreview.Advices.split(";");
        Advices = AdvicesList.join("\n");
      }
      if (this.MedicalRecordPreview.Investigations) {
        InvestigationsList = this.MedicalRecordPreview.Investigations.split(
          ";"
        );
        Investigations = InvestigationsList.join("\n");
      }
      if (
        this.MedicalRecordPreview.FollowUpDate &&
        this.MedicalRecordPreview.FollowUpDate != null &&
        this.MedicalRecordPreview.FollowUpDate != ""
      ) {
        FollowUpDate =
          "Follow Up Date: " +
          this.formatDate(this.MedicalRecordPreview.FollowUpDate);
      }
      let doctorInfo = "Doctor Name: " + this.MedicalRecordPreview.DoctorName;
      doctorInfo +=
        "\nReg No.: " +
        (this.MedicalRecordPreview.DoctorRegistrationNumber
          ? this.MedicalRecordPreview.DoctorRegistrationNumber
          : "");

      if (this.PreviewType == 1) {
        smsPrescriptionText =
          patientInfo +
          utils.fnBeautifyText(PhysicalExam, "\n\n") +
          utils.fnBeautifyText(ClinicalNotes, "\n\n") +
          utils.fnBeautifyText(Medicines, "\n\n") +
          utils.fnBeautifyText(Advices, "\n\n") +
          utils.fnBeautifyText(Investigations, "\n\n") +
          utils.fnBeautifyText(FollowUpDate, "\n\n") +
          "\n\n" +
          doctorInfo;
      } else if (this.PreviewType == 2) {
        smsPrescriptionText =
          patientInfo +
          utils.fnBeautifyText(Medicines, "\n\n") +
          "\n\n" +
          doctorInfo;
      } else if (this.PreviewType == 3) {
        smsPrescriptionText =
          patientInfo +
          utils.fnBeautifyText(PhysicalExam, "\n\n") +
          utils.fnBeautifyText(ClinicalNotes, "\n\n") +
          utils.fnBeautifyText(Advices, "\n\n") +
          utils.fnBeautifyText(Investigations, "\n\n") +
          utils.fnBeautifyText(FollowUpDate, "\n\n") +
          "\n\n" +
          doctorInfo;
      }

      return smsPrescriptionText;
    },
    fnSMSPrescription() {
      let that = this;
      // console.log(this.MedicalRecordPreview);
      // console.log(this.fnBuildTextPrescription());
      window.plugins.socialsharing.shareViaSMS(
        this.fnBuildTextPrescription(),
        this.CurrentPatient.Phone,
        function (msg) {
          that.fnResetStatusBarForiOS();

          // console.log("ok: " + msg);
        },
        function (msg) {
          that.fnResetStatusBarForiOS();

          // console.log("error: " + msg);
        }
      );
    },

    fnEmailMedicalRecord() {
      let that = this;
      let filename = "";
      let folder = "";
      let fnEmail = function (folder, file) {
        let imageUrl = folder + file;
        console.log(imageUrl);
        // if (window.isCordova == true) {
        //   imageUrl = window.Ionic.WebView.convertFileSrc(imageUrl);
        // }
        // console.log(imageUrl);
        // console.log(imageUrl);
        // imageUrl = window.WkWebView.convertFilePath(imageUrl);
        // console.log(imageUrl);
        window.plugins.socialsharing.shareViaEmail(
          "Dear " +
            that.MedicalRecordPreview.PatientName +
            ",<br/>Please find attached your medical record.<br/>Well wishes.", // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client

          "Medical Record from Dr. " + that.MedicalRecordPreview.DoctorName,

          ["" + that.CurrentPatient.Email], // TO: must be null or an array
          null, // CC: must be null or an array
          null, // BCC: must be null or an array
          "" + imageUrl + "", // FILES: can be null, a string, or an array
          function (msg) {
            console.log("ok: " + msg);
          }, // called when sharing worked, but also when the user cancelled sharing via email. On iOS, the callbacks' boolean result parameter is true when sharing worked, false if cancelled. On Android, this parameter is always true so it can't be used). See section "Notes about the successCallback" below.
          function (msg) {
            alert("error: " + msg);
          } // called when sh*t hits the fan
        );

        // console.log("Image URL : " + folder + file);
        // let imageUrl = folder + file;
        // console.log(imageUrl);
        // imageUrl = window.WkWebView.convertFilePath(imageUrl);
        // console.log(imageUrl);
        // fileUtils.fnGetBase64Img(folder, file, function(imgBase64) {
        //   // console.log("Base 64 conversion");
        //   // console.log(imgBase64);
        //   // imageUrl = imgBase64;
        //   // that.medicalRecordImageUrl = imageUrl;

        // });
        // cordova.plugins.email.open(
        //   {
        //     // from: String, // sending email account (iOS only)
        //     to: ["" + that.CurrentPatient.Email], // email addresses for TO field
        //     cc: [], // email addresses for CC field
        //     bcc: [], // email addresses for BCC field
        //     attachments: [imageUrl], // file paths or base64 data streams
        //     subject:
        //       "Medical Record from " + that.MedicalRecordPreview.DoctorName, // subject of the email
        //     body:
        //       "Dear " +
        //       that.MedicalRecordPreview.PatientName +
        //       ",<br/>Please find attached your medical record.<br/>Well wishes.",

        //     isHtml: true // indicats if the body is HTML or plain text (primarily iOS)
        //   },
        //   function(msg) {
        //     console.log("ok: " + msg);
        //   }
        // );
        // fileUtils.fnGetBase64Img(folder, file, function(_imageUrl) {
        //   console.log("Base 64 image success");
        //   imageUrl = _imageUrl;
        //   // imageUrl = "df:" + file + ";" + imageUrl;
        //   imageUrl = imageUrl.replace(
        //     "data:image/png;base64,",
        //     "base64:" + file + "//"
        //   );
        //   console.log(imageUrl);

        // });

        // window.plugins.socialsharing.shareViaEmail(
        //   "Dear " +
        //     that.MedicalRecordPreview.PatientName +
        //     ",<br/>Please find attached your medical record.<br/>Well wishes.", // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client

        //   "Medical Record from " + that.MedicalRecordPreview.DoctorName,

        //   ["" + that.CurrentPatient.Email], // TO: must be null or an array
        //   null, // CC: must be null or an array
        //   null, // BCC: must be null or an array
        //   "" + imageUrl + "", // FILES: can be null, a string, or an array
        //   function(msg) {
        //     console.log("ok: " + msg);
        //   }, // called when sharing worked, but also when the user cancelled sharing via email. On iOS, the callbacks' boolean result parameter is true when sharing worked, false if cancelled. On Android, this parameter is always true so it can't be used). See section "Notes about the successCallback" below.
        //   function(msg) {
        //     alert("error: " + msg);
        //   } // called when sh*t hits the fan
        // );
        // });
      };
      medicalRecordImagesStore.fnGetImage(
        this.MedicalRecordPreview.MedicalRecordId,
        function (items) {
          if (items && items.length > 0) {
            console.log("Items found");

            items.forEach((item) => {
              console.log(item);
              if (item.Name.includes(that.OutputFileName)) {
                folder = item.StorageLocation;
                filename = item.Name;
              }
            });
            if (folder != "" && filename != "") {
              console.log(folder);
              console.log(filename);
              // imageUrl = items[0].StorageLocation + items[0].Name;
              // console.log("Email: " + imageUrl);
              fnEmail(folder, filename);
            } else {
              that.fnSaveMedicalRecordImage(fnEmail);
            }
          } else {
            that.fnSaveMedicalRecordImage(fnEmail);
          }
        }
      );
    },
    fnGetPrescriptionBlobObject(fnSuccessCallBack) {
      console.log("Getting Prescription Blob");
      // let pdf = new jspdf("p", "pt", "letter");
      let that = this;
      // let logoImg = document.getElementById("logo-image");
      // logoImg.crossOrigin = "anonymous";
      let source = document.getElementById("prescription-pdf");
      // console.log(source.innerHTML);
      html2canvas(source, { allowTaint: false, useCORS: true }).then(
        function (canvas) {
          that.medicalRecordImageUrl = "blob";

          // that.Dom7("#medicalrecordimage")[0].appendChild(canvas);
          // console.log(that.Dom7("#medicalrecordimage")[0].innerHTML);

          if (fnSuccessCallBack) {
            try {
              // fnSuccessCallBack(canvas.toDataURL("image/png"));
              canvas.toBlob((blob) => {
                // console.log(blob);

                // logoImg.removeAttribute("crossorigin");
                fnSuccessCallBack(blob);
              });
            } catch (err) {
              console.log(err);
            }
          }
        },
        function (err) {
          console.log(err);
        }
      );
    },

    fnPrintMedicalRecord() {
      this.PrintCount++;
      let presElement = this.Dom7("#prescription");
      let styles = this.fnGetPrescriptionStyles();
      let presHTML = presElement[0].innerHTML;
      // console.log(presHTML);
      let that = this;
      presHTML = styles + " <body>" + presHTML + "</body>";
      cordova.plugins.printer.print(
        presHTML,
        {
          name: this.OutputFileName + ".pdf",
        },
        function (res) {
          console.log(res ? "Done" : "Canceled");
        }
      );
    },
    fnDownloadImage() {
      this.fnSaveMedicalRecordImage();
    },
    fnSaveMedicalRecordImage(fnSuccessCallback) {
      let that = this;
      let dialog = f7App.dialog.preloader("Downloading file");
      that.fnGetPrescriptionBlobObject(function (blob) {
        let folder = "ScriptyMedicalRecords";
        let filename = that.OutputFileName + ".png";
        saveAs(blob, filename);
        dialog.close();
        // let a = document.createElement("a");
        // a.style = "display: none";
        // // console.log(dataUrl);
        // // let url = window.URL.createObjectURL(blob);
        // a.href = dataUrl;
        // a.download = filename;
        // a.target = "_blank"
        // document.body.appendChild(a);

        // a.click();
        // window.URL.revokeObjectURL(url);

        // fileUtils.fnSaveImage(blob, null, folder, filename, function (
        //   location
        // ) {
        //   let medicalRecordImage = {
        //     MedicalRecordImageId: dbUtils.generateUID(),
        //     MedicalRecordId: that.MedicalRecordPreview.MedicalRecordId,
        //     Name: filename,
        //     StorageLocation: location + folder + "/",
        //     PlatformType: device.platform,
        //   };
        //   medicalRecordImagesStore.fnInsert(medicalRecordImage, function () {
        //     console.log("Image inserted successfully");
        //     if (fnSuccessCallback) {
        //       fnSuccessCallback(
        //         medicalRecordImage.StorageLocation,
        //         medicalRecordImage.Name
        //       );
        //     }
        //   });
        // });
      });
    },

    fnGetPrescriptionStyles() {
      return `<style type='text/css'>
      body{
        font-family: Arial, Helvetica, sans-serif;
        line-height:1.5;
      }
      .text-align-center {
        text-align: center;
      }
      .col-left {
        min-width: 115px;
        max-width: 115px;
      }
      .margin-a4-print {
        margin: 15px 45px;
      }
      :root {
        --f7-grid-gap: 16px;
        --f7-grid-row-gap: 0px;
        --f7-grid-resize-handler-bg-color: rgba(0, 0, 0, 0.35);
      }
      .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        --f7-cols-per-row: 1;
      }
      .row .col-25 {
        --f7-cols-per-row: 4;
      }
      .row .col-40 {
        --f7-cols-per-row: 2.5;
      }
      .row .col-10 {
        --f7-cols-per-row: 10;
      }
      .row .col-35 {
        --f7-cols-per-row: 2.85714286;
      }
      .row .col-65 {
        --f7-cols-per-row: 1.53846154;
      }
      .row > [class*="col-"], .row > .col {
        box-sizing: border-box;
        width: calc((100% - var(--f7-grid-gap) * (var(--f7-cols-per-row) - 1)) / var(--f7-cols-per-row));
      }

      .text-secondary{
        color:rgba(0, 0, 0, 0.54);
      }
      .text-align-right{
        text-align:right;
      }
            /*Margins*/
      .margin-right-small {
        margin-right: 10px;
      }
      .margin-right-large {
        margin-right: 16px;
      }
      .margin-bottom-medium {
        margin-bottom: 14px;
      }
      .margin-bottom-large {
        margin-bottom: 16px;
      }
      .margin-small {
        margin: 10px;
      }
      .margin-y-small {
        margin: 10px 0px;
      }
      .margin-x-small {
        margin: 0px 10px;
      }
      .margin-none {
        margin: 0;
      }
      .margin-top-large {
        margin-top: 16px;
      }
      .margin-top-small {
        margin-top: 12px;
      }

      /*Font sizes*/
      .font-bold {
        font-weight: 500;
      }
      .font-xsmall {
        font-size: 10px;
      }
      .font-small {
        font-size: 12px;
      }
      .font-medium {
        font-size: 14px;
      }
      .font-large {
        font-size: 18px;
      }
      /*Lists*/
      .list-style-none {
        list-style-type: none;
      }
      .list-style-circle {
        list-style-type: circle;
      }
      .ul-compact {
        margin: 4px 0;
        padding-left: 26px;
      }
      /*Colors*/
      .light-color {
        color: var(--f7-block-header-text-color);
      }

      /*Normal Alignments*/
      .float-right {
        float: right;
      }
      .text-right {
        text-align: right;
      }
      /* Others*/

      .title-button-right {
        float: right;
      }
      .title-button-right i {
        font-size: 22px;
      }
      .icon-small {
        font-size: 16px;
      }

      .hr-line-small {
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .stretch-outside-x {
        margin-right: -20px;
        margin-left: -20px;
      }

      .overflow-hidden-x {
        overflow-x: hidden;
      }
      .f-row {
        display: flex;
      }
      .f-column {
        display: flex;
        flex-direction: column;
      }
      .f-center {
        justify-content: center;
      }
      .f-end {
        justify-content: flex-end;
      }
      .f-wrap {
        flex-wrap: wrap;
      }
      .f-space-between {
        justify-content: space-between;
      }
      .f-vertical-center {
        align-items: center;
      }
      .f-vertical-stretch {
        align-items: stretch;
      }

      .f-vertical-bottom {
        align-items: flex-end;
      }
      .f-vertical-baseline {
        align-items: baseline;
      }
      .f-spread {
        flex: 1;
      }
      .f-vertical-stretch {
        align-items: stretch;
      }</style>
      `;
    },
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>
