<template>
  <f7-page login-screen>
    <div class="h-100 overflow-hidden-y">
      <div class="h-100 f-column f-center" id="login">
        <div>
          <f7-login-screen-title>
            <img
              height="150"
              width="150"
              style="border-radius: 50%"
              src="static/logo/logo_bg_dark.png"
            />
          </f7-login-screen-title>
          <div
            v-if="ExistingUserName != ''"
            class="text-align-center font-large"
          >
            Welcome
            <br />
            Dr.&nbsp;{{ ExistingUserName }}
          </div>

          <f7-list no-hairlines no-hairlines-between>
            <f7-list-input
              v-if="ExistingUserName == ''"
              outline
              label="Email"
              type="email"
              placeholder="Your email"
              required
              validate
              :value="loginForm.Email"
              @input="loginForm.Email = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              outline
              label="Password"
              type="password"
              placeholder="Your password"
              required
              validate
              :value="loginForm.Password"
              @input="loginForm.Password = $event.target.value"
            ></f7-list-input>
          </f7-list>
          <div class="f-column f-center">
            <f7-button
              class="margin-x-large"
              @click="login"
              fill
              color="white"
              round
              >Log In</f7-button
            >
            <f7-button
              v-if="ExistingUserName == ''"
              class="margin-top-xsmall margin-x-large"
              href="/doctor-register/"
              fill
              round
              >Create Account</f7-button
            >

            <f7-link
              class="margin-top-small font-small margin-x-large text-light-red"
              round
              @click="fnForgotPassword()"
              >FORGOT PASSWORD</f7-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="h-100 overflow-hidden-y" v-show="showLoginForm == false">
      <f7-navbar>
        <f7-link slot="left" :icon-only="true" icon-f7="arrow_left" @click="toggleForm"></f7-link>
        <f7-nav-title>Doctor Account Form</f7-nav-title>
      </f7-navbar>
      
    </div>-->
  </f7-page>

  <!-- <f7-page no-swipeback login-screen >
      
  </f7-page>-->
  <!-- <f7-page>
      <div id="firebaseui-auth-container"></div>
  </f7-page>-->
</template>
<style>
/* .login-screen-page .page-content {
  background-color: var(--b7-background-color-tertiary);
} */
/* .button {
  color: var(--b7-text-color-primary);
} */
.login-screen-content .button-fill {
  background-color: var(--b7-background-color-primary);
}
</style>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
const fireStore = require("../store/firebaseStore.js");
const doctorStore = require("../store/doctorStore.js");
const patientStore = require("../store/patientStore.js");

const errorHandler = require("../shared/error-handler.js");
// var firebaseui = require("firebaseui");
export default {
  name: "account",
  props: {
    did: "",
  },
  created: function () {},
  mounted: function () {
    // firebase.auth().settings.appVerificationDisabledForTesting = true;
    // let tt = firebase.auth();
    // tt.settings({
    //   appVerificationDisabledForTesting: true,
    // });
    // this.currentRoute = this.$f7router.currentRoute.path;
    let that = this;
    if (this.did) {
      this.$store.commit("setCurrentUser", { uid: this.did });
      this.$store.dispatch("fetchDoctorProfile");
    }
    // this.elRegistrationForm = this.Dom7("#register-user");

    // that.fnInitLoginUI();
  },
  data: function () {
    return {
      loginForm: {
        Email: "",
        Password: "",
      },
      currentRoute: "",
      elRegistrationForm: null,
      isPasswordValid: false,
      ExistingUser: {},
      ExistingUserName: "",
      signupForm: {
        Name: "",
        Degree: "",
        Speciality: "",
        RegistrationNumber: "",
        Address: "",
        Email: "",
        Password: "",
        Phone: "",
        ConfirmPassword: "",
      },
      showLoginForm: true,
    };
  },
  methods: {
    fnForgotPassword() {
      errorHandler.fnRaiseSuccess(
        "Coming Up",
        "This feature is coming up in next release. Thank you for your patience :)"
      );
    },

    fnInitLoginUI() {
      var uiConfig = {
        signInSuccessUrl: "/home/",
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          firebase.auth.GithubAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
        ],
        // // tosUrl and privacyPolicyUrl accept either url string or a callback
        // // function.
        // // Terms of service url/callback.
        // tosUrl: "<your-tos-url>",
        // // Privacy policy url/callback.
        // privacyPolicyUrl: function() {
        //   window.location.assign("<your-privacy-policy-url>");
        // }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      // The start method will wait until the DOM is loaded.
      ui.start("#firebaseui-auth-container", uiConfig);
    },
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    fnGetPatient() {
      let that = this;
      console.log("Get Patient");
      patientStore.fnGetPatient(this.did, this.pid, function (data) {
        console.log("Patient");
        console.log(data);
        that.PatientForm = data;
      });
    },
    login() {
      let dialog = f7App.dialog.preloader("Logging In");
      console.log(this.loginForm);
      fireStore
        .auth(firebase)
        .signInWithEmailAndPassword(
          this.loginForm.Email,
          this.loginForm.Password
        )
        .then((user) => {
          dialog.close();
          console.log(user.user);
          this.$store.commit("setCurrentUser", user.user);
          this.$store.dispatch("fetchDoctorProfile");
          this.$f7router.navigate("/home/1/");
        })
        .catch((err) => {
          dialog.close();

          console.log(err);
          if (
            err &&
            (err.code == "auth/wrong-password" ||
              err.code == "auth/user-not-found")
          ) {
            errorHandler.fnRaiseError("", "Invalid username or password");
          } else {
            errorHandler.fnRaiseError("", "");
          }
        });
    },
  },
};
</script>
