<template>
  <f7-page name="patient-file">
    <f7-navbar v-if="currentTabIndex != 3">
      <f7-nav-left>
        <img
          class="margin-left-small"
          style="height: 40px; width: 40px"
          src="static/icons/favicon.png"
        />

        <span>SCRIPTY</span>
      </f7-nav-left>
      <!-- <f7-nav-title>SCRIPTY</f7-nav-title> -->
      <!-- <f7-nav-right>
        <f7-button href="/home/1/" style="color:white" icon-material="home"></f7-button>
      </f7-nav-right>-->
    </f7-navbar>
    <f7-navbar v-if="currentTabIndex == 3">
      <!-- <f7-link
        slot="left"
        :icon-only="true"
        icon-f7="arrow_left"
        href=""
        @click="currentTabIndex = 1"
      ></f7-link> -->
      <f7-nav-title>Create Patient Profile</f7-nav-title>
      <f7-nav-right>
        <f7-button @click="fnLogout()" style="color: white">Logout</f7-button>
      </f7-nav-right>
    </f7-navbar>
    <div
      class="h-100 overflow-y-auto"
      v-show="
        (uniqueName &&
          isDoctorLoaded == true &&
          isRecaptchaContainerLoaded == true) ||
        !uniqueName
      "
    >
      <div
        style="padding: 20px 0; background-color: rgba(170, 215, 205, 0.4)"
        class="margin-top-small margin-x-small"
        v-if="currentTabIndex != 3 && uniqueName"
      >
        <div v-if="LogoUrl && LogoUrl != ''" class="f-row f-center">
          <div style="max-width: 120px">
            <img
              crossorigin="anonymous"
              :src="LogoUrl"
              style="max-width: 120px"
            />
          </div>
        </div>
        <div style="margin-top: 6px" class="f-column f-column-center">
          <span style="font-size: large">{{ doctor.ClinicName }}</span>
          <span class="text-secondary">{{ doctor.ClinicTagline1 }}</span>
          <span class="text-secondary">{{ doctor.ClinicTagline2 }}</span>
        </div>
        <div class="f-row" style="margin-top: 20px">
          <div class="f-column f-column-center f-spread">
            <div style="text-align: center" class="font-small">
              <span class="text-secondary">Dr.&nbsp;{{ doctor.Name }},</span>

              <span class="text-secondary">{{ doctor.Degree }},</span>

              <span class="text-secondary">{{ doctor.Speciality }}</span>
            </div>
            <div class="f-row f-center">
              <span class="font-xsmall text-secondary">{{
                doctor.RegistrationNumber
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style="margin-top: 36px"
        class="margin-y-small"
        v-if="currentTabIndex != 3"
      >
        <!-- <div class="text-secondary text-small margin-left-small underline">
          Existing User?
        </div> -->

        <!-- <div class="card">
          <div class="card-content"> -->
        <!-- <p style="margin-top: 0" class="segmented segmented-strong w-100"> -->
        <div class="f7-form-margin-x">
          <div class="f-column w-100 f-center f-vertical-center">
            <div style="text-align: center" class="font-medium">
              Welcome Patient
            </div>

            <span class="text-secondary font-xsmall"
              >Login with phone number to access your patient file</span
            >
            <!-- <f7-button
              v-if="isPatientLink == false"
              @click="currentTabIndex = 1"
              class="font-small"
              fill
              small
              v-bind:class="[
                currentTabIndex == 1 ? 'button-active-tertiary' : 'button-gray',
              ]"
            >
              Login with Email
            </f7-button> -->
            <!-- <span>OR</span> -->
            <!-- <f7-button
              v-if="isPatientLink == true"
              @click="currentTabIndex = 2"
              fill
              class="font-small"
              small
              v-bind:class="[
                currentTabIndex == 2 ? 'button-active-tertiary' : 'button-gray',
              ]"
            >
              Login with Phone
            </f7-button> -->
            <!-- <f7-button
              @click="currentTabIndex = 2"
              fill
              class="font-small"
              small
              v-bind:class="[
                currentTabIndex == 2 ? 'button-active-tertiary' : 'button-gray',
              ]"
            >
              Login with Phone
            </f7-button> -->
          </div>
        </div>
        <!-- <span class="segmented-highlight"></span> -->
        <!-- </p> -->

        <form
          v-show="currentTabIndex == 1"
          id="login-patient-email"
          autocomplete="off"
          class="margin-bottom-small"
          onsubmit="event.preventDefault();"
        >
          <f7-list no-hairlines no-hairlines-between>
            <f7-list-input
              outline
              label="Email"
              type="text"
              placeholder="Email"
              required
              :value="signInForm.Email"
              @input="signInForm.Email = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              outline
              label="Password"
              type="password"
              placeholder="Password"
              :value="signInForm.Password"
              @input="signInForm.Password = $event.target.value"
            ></f7-list-input>
          </f7-list>
          <div class="f-column f-center">
            <f7-button
              :class="fnValidateLoginFormEmail() == true ? '' : 'disabled'"
              class="margin-x-large background-tertiary text-primary"
              @click="fnLoginWithEmail"
              fill
              round
              >Login</f7-button
            >
            <f7-button
              class="
                margin-x-large margin-top-xsmall
                background-tertiary
                text-primary
              "
              @click="currentTabIndex = 3"
              v-if="isPatientLink == false"
              fill
              round
              >Create Account</f7-button
            >
          </div>
          <div class="f-row w-100 f-center" v-if="isPatientLink == false">
            <f7-link
              class="font-small margin-top-xsmall margin-x-large text-light-red"
              round
              @click="fnForgotPassword()"
              >FORGOT PASSWORD</f7-link
            >
          </div>
        </form>
        <form
          v-show="currentTabIndex == 2"
          id="login-patient-phone"
          autocomplete="off"
          class="margin-bottom-small"
          onsubmit="event.preventDefault();"
        >
          <f7-list no-hairlines no-hairlines-between>
            <!-- <f7-list-input
              outline
              label="Phone (10 digit)"
              type="text"
              placeholder="10 digit phone number"
              pattern="^\d{10}$"
              validate
              required
              :disabled="isOTPSent == true"
              :value="signInForm.Phone"
              @input="signInForm.Phone = $event.target.value"
            >
           
            </f7-list-input> -->
            <li class="item-content item-input item-input-outline">
              <div class="item-inner">
                <div class="item-title item-label">Mobile (10 digit)</div>
                <div class="item-input-wrap f-row f-vertical-bottom">
                  <span class="margin-left-small margin-bottom-small"
                    >+91&nbsp;</span
                  >
                  <input
                    type="text"
                    required
                    validate
                    name="PhoneNumber"
                    placeholder="10 digit phone number"
                    pattern="^\d{10}$"
                    :disabled="isPatientLink == true || isOTPSent == true"
                    :value="signInForm.Phone"
                    @input="signInForm.Phone = $event.target.value"
                  />
                </div>
              </div>
            </li>
            <!-- <f7-list-input
              outline
              label="Phone (10 digit)"
              type="text"
              placeholder="10 digit phone number"
              pattern="^\d{10}$"
              validate
              required
              :disabled="isPatientLink == true || isOTPSent == true"
              :value="signInForm.Phone"
              @input="signInForm.Phone = $event.target.value"
            ></f7-list-input> -->
            <f7-list-input
              v-if="isOTPSent == true"
              outline
              label="One Time Password (OTP)"
              type="text"
              placeholder="Enter OTP sent on phone"
              required
              validate
              :value="signInForm.OTP"
              @input="signInForm.OTP = $event.target.value"
            ></f7-list-input>
            <!-- <div class="f7-form-padding-x margin-top-small">
              <div
                class="f-row w-100 f-center"
                v-show="isOTPSent == false"
                id="recaptcha-container"
              ></div>
            </div> -->
          </f7-list>
          <div class="f-column f-center">
            <f7-button
              id="sign-in-button"
              v-show="isOTPSent == false && isSendingOTP == false"
              class="
                margin-x-large margin-y-large
                background-tertiary
                text-primary
              "
              fill
              round
              :class="[
                fnValidateLoginFormPhone() == true ? '' : 'disabled',
                isSendingOTP == true ? 'disabled' : '',
              ]"
              @click="fnSendVerificationCodeViaSMS"
            >
              Get OTP
              <!-- <span class="f-row f-center f-vertical-center">
                <span>Get OTP</span>
                <span v-show="isSendingOTP == true"
                  >&nbsp;<span
                    style="width: 24px; height: 24px"
                    class="preloader color-blue"
                  ></span
                ></span>
              </span> -->
            </f7-button>
            <f7-button
              id="sign-in-button"
              v-show="isOTPSent == false && isSendingOTP == true"
              class="
                margin-x-large margin-y-large
                background-tertiary
                text-primary
              "
              fill
              round
              :class="[
                fnValidateLoginFormPhone() == true ? '' : 'disabled',
                isSendingOTP == true ? 'disabled' : '',
              ]"
              @click="fnSendVerificationCodeViaSMS"
            >
              <span class="f-row f-center f-vertical-center">
                <span>Get OTP</span>
                <span v-show="isSendingOTP == true"
                  >&nbsp;<span
                    style="width: 24px; height: 24px"
                    class="preloader color-blue"
                  ></span
                ></span>
              </span>
            </f7-button>
            <!-- <div id="sign-in-button"></div> -->
            <f7-button
              v-if="isOTPSent == true"
              class="
                margin-x-large margin-y-large
                background-primary
                text-white
              "
              @click="fnLoginWithOTP"
              fill
              :class="
                isRecaptchaResolved == true &&
                fnValidateLoginFormPhone() == true &&
                signInForm.OTP
                  ? ''
                  : 'disabled'
              "
              round
              >Login</f7-button
            >
          </div>
        </form>
        <!-- </div>
        </div> -->
        <!-- <div class="f-row w-100 margin-left-small">
          <div class="margin-bottom-large margin-top-large">
            <span class="text-secondary underline">New User?</span>
            <f7-link
              class="margin-x-large"
              @click="currentTabIndex = 3"
              fill
              round
              color="green"
              href=""
              >CREATE ACCOUNT</f7-link
            >
          </div>
        </div> -->
        <div
          v-if="
            uniqueName &&
            (doctor.Address ||
              doctor.ClinicPhone ||
              doctor.ClinicEmail ||
              doctor.ClinicWebsite)
          "
        >
          <div class="hr-line-small margin-y-small stretch-outside-x"></div>
          <div class="f-row margin-small">
            <div class="f-spread f-column padding-x-small padding-bottom-small">
              <span v-if="doctor.ClinicPhone" class="font-xsmall text-secondary"
                >P: {{ doctor.ClinicPhone }}</span
              >
              <span v-if="doctor.ClinicEmail" class="font-xsmall text-secondary"
                >E: {{ doctor.ClinicEmail }}</span
              >
              <span
                v-if="doctor.ClinicWebsite"
                class="font-xsmall text-secondary"
                >W: {{ doctor.ClinicWebsite }}</span
              >
              <span v-if="doctor.Address" class="font-xsmall text-secondary">{{
                doctor.Address
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="currentTabIndex != 3" class="f-spread"></div> -->

      <div v-if="currentTabIndex == 3">
        <div class="f-row f-center font-small">
          <span class="text-secondary margin-top-medium"
            >Logged in number: +91-{{ AccountPhoneNumber }}</span
          >
        </div>
        <div class="f-row f-center font-small">
          <span class="text-secondary margin-top-medium"
            >Please create a patient profile to continue</span
          >
        </div>
        <form id="register-patient-email">
          <f7-list no-hairlines>
            <f7-list-input
              label="Full Name *"
              type="text"
              required
              outline
              validate
              :value="PatientForm.Name"
              @input="PatientForm.Name = $event.target.value"
            ></f7-list-input>

            <f7-list-input
              :value="PatientForm.Gender"
              required
              validate
              outline
              @input="PatientForm.Gender = $event.target.value"
              label="Gender *"
              type="select"
            >
              <option value></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </f7-list-input>

            <f7-list-input
              label="Address *"
              type="text"
              required
              outline
              validate
              :value="PatientForm.Address"
              @input="PatientForm.Address = $event.target.value"
            ></f7-list-input>

            <li class="item-content date-input margin-bottom-large">
              <div class="item-inner">
                <div
                  class="w-100"
                  :class="PatientForm.DateOfBirth ? '' : 'item-input-invalid'"
                >
                  <label class="f7-form-label item-label"
                    >Date of birth *&nbsp;</label
                  >
                  <div class="item-input-wrap item-input-outline">
                    <datepicker
                      :initialView="'year'"
                      v-model="PatientForm.DateOfBirth"
                    ></datepicker>
                    <div v-if="PatientForm.DateOfBirth" class="item-input-info">
                      {{ PatientAge }}
                    </div>
                    <div class="item-input-error-message">
                      Please fill in this field.
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- <f7-list-input
              label="Ext. Sys Code (Optional)"
              type="text"
              required
              outline
              validate
              :value="PatientForm.ExtSystemCode"
              @input="PatientForm.ExtSystemCode = $event.target.value"
            ></f7-list-input> -->
            <!-- <f7-list-input
              type="text"
              placeholder="Age"
              outline
              disabled
              :value="PatientAge"
              @input="PatientAge = $event.target.value"
            ></f7-list-input> -->
            <!-- <f7-list-input
              label="Phone (10 digit)"
              type="text"
              outline
              validate
              required
              pattern="^\d{10}$"
              :value="PatientForm.Phone"
              @input="PatientForm.Phone = $event.target.value"
            ></f7-list-input> -->
            <!-- <f7-list-input
              label="Email (Optional)"
              type="email"
              validate
              outline
              :value="PatientForm.Email"
              @input="PatientForm.Email = $event.target.value"
            ></f7-list-input> -->
            <!-- <f7-list-input
              outline
              label="Password"
              type="password"
              placeholder="Password"
              required
              validate
              :value="PatientForm.Password"
              @input="PatientForm.Password = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              outline
              label="Confirm Password"
              type="password"
              placeholder="Confirm password"
              required
              error-message="Password and confirm password do not match"
              :error-message-force="!isPasswordValid"
              autocomplete="off"
              :value="PatientForm.ConfirmPassword"
              @input="fnValidatePassword($event.target.value)"
            ></f7-list-input> -->
          </f7-list>
          <div class="margin-top-small f-cloumn f-center">
            <div class="margin-bottom-large">
              <f7-button
                class="margin-x-large background-tertiary text-primary"
                :class="fnValidateRegistrationFormEmail() ? '' : 'disabled'"
                @click="signup"
                fill
                round
                >Proceed</f7-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div style="font-size:small" class="toolbar tabbar tabbar-scrollable toolbar-bottom">
      <div class="toolbar-inner">
        <a href="#tab-1" class="tab-link tab-link-active">Tab 1</a>
        <a href="#tab-2" class="tab-link">Tab 2</a>
        <a href="#tab-3" class="tab-link">Tab 3</a>
        <a href="#tab-3" class="tab-link">Tab 3</a>
        <a href="#tab-3" class="tab-link">Tab 3</a>
        <a href="#tab-3" class="tab-link">Tab 3</a>
        <a href="#tab-3" class="tab-link">Tab 3</a>

        <a href="#tab-12" class="tab-link">Tab 12</a>
      </div>
    </div> -->
    <f7-toolbar
      v-if="!uniqueName"
      tabbar
      scrollable
      style="font-size: x-small"
      :position="'bottom'"
    >
      <f7-link :href="'/aboutUs'">About Us</f7-link>
      <f7-link :href="'/contactUs'">Contact Us</f7-link>
      <f7-link :href="'/privacyPolicy'">Privacy Policy</f7-link>
      <f7-link :href="'/refundPolicy'">Refund Policy</f7-link>
      <f7-link :href="'/termsConditions'">Terms & Conditions</f7-link>
    </f7-toolbar>
  </f7-page>
</template>
<style>
.vdp-datepicker input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 0px;
  padding-left: var(--f7-input-outline-padding-horizontal) !important;
  padding-right: var(--f7-input-outline-padding-horizontal) !important;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1); */
  width: 100%;
}
.date-input .item-inner:after {
  background-color: transparent !important;
}
.item-input-invalid .vdp-datepicker input {
  border: 2px solid red !important;
}
/* .grecaptcha-badge {
  display: none !important;
} */
</style>
<script>
const fireStore = require("../store/firebaseStore.js");
const dateUtils = require("../shared/dateUtils.js");
import firebase from "firebase/app";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import EventBus from "../shared/eventBus.js";

import "firebase/firestore";

import { mapState } from "vuex";
const patientStore = require("../store/patientStore.js");
const doctorLogoStore = require("../store/doctorLogoStore.js");
const dbUtils = require("../store/dbUtils.js");
const utils = require("../shared/utils.js");
const crypto = require("../shared/crypto.js");
const fileUtils = require("../shared/fileUtils.js");
const errorHandler = require("../shared/error-handler.js");
const chatsStore = require("../store/chatsStore.js");

// const fireStore = require("../store/firebaseStore.js");
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
  name: "patient-file",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      PatientForm: {
        Name: "",
        Age: "",
        Gender: "",
        Address: "",
        Email: "",
        Phone: "",
        DoctorId: "",
        Password: "",
        ConfirmPassword: "",
        DateOfBirth: "",
      },
      PatientAge: "",
      isPatientAccountCreated: false,
      elLoginFormPhone: null,
      elRegistrationFormEmail: null,
      elLoginFormEmail: null,
      isPasswordValid: false,
      isRecaptchaResolved: false,
      isOTPSent: false,
      signInForm: {
        OTP: "",
        Email: "",
        Password: "",
        Phone: "",
        ConfirmPassword: "",
      },
      isDoctorLink: false,
      isPatientLink: false,
      currentTabIndex: 2,
      isDoctorLoaded: false,
      doctor: {},
      LogoUrl: "",
      did: "",
      countryCode: "",
      PatientPhone: "",
      isRecaptchaContainerLoaded: false,
      firebaseUser: null,
      isSendingOTP: false,
      AccountPhoneNumber: "",
      isFirebaseUserLogin: false,
      // isPWASheetOpened: false,
      // pwaDialog: null,
    };
  },
  props: {
    uniqueName: null,
    pid: null,
  },
  computed: {
    ...mapState(["currentUser"]),
    patientDateOfBirth: function () {
      return this.PatientForm.DateOfBirth;
    },
  },
  watch: {
    currentTabIndex: function (newValue, oldValue) {
      if (newValue == 2) {
        // if (!window.recaptchaVerifier) {
        // }
      }
      if (newValue == 3) {
        let that = this;
        this.$nextTick(function () {
          that.elRegistrationFormEmail = that.Dom7("#register-patient-email");
        });
        // if (!window.recaptchaVerifier) {
        // }
      }
    },
    patientDateOfBirth: function (value) {
      if (value) {
        if (typeof value == "string" && !isNaN(value)) {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(
            new Date(Number(value))
          );
        } else {
          this.PatientAge = dateUtils.fnGetAgeFromDateOfBirth(value);
        }
      }
    },
    isDoctorLoaded: function (newValue) {
      if (newValue == true) {
        EventBus.$emit("trigger-pwa-install");
        // this.fnShowPWAModal();
      }
    },
  },

  mounted: function () {
    let that = this;
    // console.log(
    //   "ENcoded number",
    //   crypto.fnEncode("2222222222")
    // );
    // console.log(
    //   "Hash number",
    //   crypto.fnHash(
    //     "2222222222"
    //   )
    // );
    this.$store.commit("resetStore", null);
    // let date = "2021-02-02";
    // let time = "09:45";
    // console.log("Date IST", new Date(date + "T" + time));
    // console.log("Moment UTC", moment.utc(new moment(date + "T" + time)));
    // console.log("Date UTC", new Date(moment.utc(new moment(date + "T" + time))));
    // console.log("Date UTC Ms", new Date(moment.utc(new moment(date + "T" + time))).getTime());
    // new Date(moment.utc(new Date(dateYYYYMMDD + "T" + timeHHmm))).getTime();
    this.countryCode = "+91";
    if (this.uniqueName && this.pid) {
      this.signInForm.Phone = crypto.fnBase64Decode(this.pid);
      this.currentTabIndex = 2;
      this.isPatientLink = true;
    } else if (this.did) {
      this.isDoctorLink = true;
    }

    firebase.auth().useDeviceLanguage();
    // this.$nextTick(function () {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: function (response) {
          that.isRecaptchaResolved = true;
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": function () {
          if (that) {
            that.isRecaptchaResolved = false;
          }
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      }
    );
    that.isRecaptchaContainerLoaded = true;
    // window.recaptchaVerifier.render().then(function (widgetId) {
    //   that.isRecaptchaContainerLoaded = true;
    //   window.recaptchaWidgetId = widgetId;
    // });
    this.elLoginFormEmail = this.Dom7("#login-patient-email");

    // });
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "sign-in-button",
    //   {
    //     size: "invisible",
    //     callback: function (response) {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       // onSignInSubmit();
    //     },
    //   }
    // );
    // this.$f7ready(f7 => {
    this.elLoginFormPhone = this.Dom7("#login-patient-phone");
    if (this.uniqueName) {
      this.$store.commit("setCurrentUser", { UniqueName: this.uniqueName });
      let dialog = f7App.dialog.preloader("Fetching Doctor Profile");

      this.$store.dispatch("fetchDoctorProfile").then(
        function () {
          that.did = that.currentUser.uid;

          dialog.close();
          console.log(that.currentUser);
          that.doctor = that.currentUser;
          that.fnGetLogoImageUrl(function (imageUrl) {
            console.log("Applying logo url");
            console.log(imageUrl);
            that.LogoUrl = imageUrl;
          });
          that.isDoctorLoaded = true;
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              that.isFirebaseUserLogin = true;
              that.fnTriggerPostLogin(user, user.phoneNumber);
            }

            // console.log(user);
          });
        },
        function (error) {
          console.log("error");
          if (error == "Not Found") {
            errorHandler.fnRaiseError(
              "Doctor not found",
              "Please check the doctor link"
            );
          }
          dialog.close();
        }
      );
    }
    // if (this.isPatientLink == true) {
    //   this.fnGetPatient();
    // }
    // if (this.isDoctorLink == true) {
    // }
    // this.fnValidatePatientForm();
    // });
  },
  methods: {
    fnValidateRegistrationFormEmail() {
      if (this.elRegistrationFormEmail && this.elRegistrationFormEmail[0]) {
        // console.log(this.elRegistrationFormEmail[0].checkValidity());
        return (
          this.elRegistrationFormEmail[0].checkValidity() &&
          this.PatientForm.DateOfBirth
        );
      }
    },
    fnValidateLoginFormEmail() {
      if (this.elLoginFormEmail && this.elLoginFormEmail[0]) {
        // console.log(this.elRegistrationFormEmail[0].checkValidity());
        return this.elLoginFormEmail[0].checkValidity();
      }
    },
    fnValidateLoginFormPhone() {
      if (this.elLoginFormPhone && this.elLoginFormPhone[0]) {
        // console.log(this.elRegistrationFormEmail[0].checkValidity());
        return this.elLoginFormPhone[0].checkValidity();
      }
    },
    fnLoginWithEmail() {
      let dialog = f7App.dialog.preloader("Logging In");
      let that = this;
      console.log(this.signInForm);
      fireStore
        .auth(firebase)
        .signInWithEmailAndPassword(
          this.signInForm.Email,
          this.signInForm.Password
        )
        .then((user) => {
          dialog.close();
          that.fnGetPatient(user.user.uid);
          // console.log(user.user);
          // this.$store.commit("setCurrentUser", user.user);
          // this.$store.dispatch("fetchDoctorProfile").then(
          //   function () {
          //     that.$f7router.navigate("/home/1/");
          //   },
          //   function (error) {
          //     console.log(error);
          //   }
          // );
          // this.$f7router.navigate("/doctor-profile-complete/");
        })
        .catch((err) => {
          dialog.close();

          console.log(err);
          if (
            err &&
            (err.code == "auth/wrong-password" ||
              err.code == "auth/user-not-found")
          ) {
            errorHandler.fnRaiseError("", "Invalid username or password");
          } else {
            errorHandler.fnRaiseError("", err.message);
          }
        });
    },
    fnValidateRegistrationForm() {
      // if (this.elRegistrationForm && this.elRegistrationForm[0]) {
      //   // console.log(this.elRegistrationForm[0].checkValidity())
      //   // console.log(this.isPasswordValid)
      //   return (
      //     this.elRegistrationForm[0].checkValidity() && this.isPasswordValid
      //   );
      // }
    },
    fnValidatePassword(input) {
      this.PatientForm.ConfirmPassword = input;
      this.fnCheckConfirmPassword();
    },
    fnCheckConfirmPassword() {
      if (this.PatientForm.Password == this.PatientForm.ConfirmPassword) {
        this.isPasswordValid = true;
      } else {
        this.isPasswordValid = false;
      }
    },
    fnTriggerPostLogin(user, phone) {
      console.log("Trigger post");
      this.firebaseUser = user;
      this.AccountPhoneNumber = phone.replace("+91", "");
      this.fnGetPatientByPhone(phone.replace("+91", ""));
    },
    fnLoginWithOTP() {
      let that = this;
      let dialog = f7App.dialog.preloader("Logging In");

      // console.log(this.signInForm.OTP);
      window.confirmationResult
        .confirm(this.signInForm.OTP)
        .then(function (user) {
          // User signed in successfully.
          // var user = result.user;
          dialog.close();
          that.fnTriggerPostLogin(user.user, that.signInForm.Phone);

          // that.$f7router.navigate("/patient-view/");

          // ...
        })
        .catch(function (error) {
          dialog.close();

          console.log("Login OTP error");
          errorHandler.fnRaiseError("", error.message);
          // User couldn't sign in (bad verification code?)
          // ...
        });
    },
    fnSendVerificationCodeViaSMS() {
      this.isSendingOTP = true;
      let appVerifier = window.recaptchaVerifier;
      // console.log(appVerifier);
      // console.log(this.signInForm.Phone);
      let that = this;
      firebase
        .auth()
        .signInWithPhoneNumber(
          this.countryCode + this.signInForm.Phone,
          appVerifier
        )
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          that.isSendingOTP = false;

          that.isOTPSent = true;
        })
        .catch(function (err) {
          that.isOTPSent = false;
          errorHandler.fnRaiseError("", err.message);
          // Error; SMS not sent
          // ...
        });
    },
    signup() {
      this.fnCreatePatient();

      // let that = this;
      // let dialog = f7App.dialog.preloader("Creating Account");

      // fireStore
      //   .auth(firebase)
      //   .createUserWithEmailAndPassword(
      //     this.PatientForm.Email,
      //     this.PatientForm.Password
      //   )
      //   .then((user) => {
      //     // that.$store.commit("setCurrentUser", user.user);
      //     that.PatientForm.PatientFirebaseUID = user.user.uid;
      //     // let patientFormCopy = Object.assign({}, that.PatientForm);
      //     dialog.close();

      //     that.fnCreatePatient();
      //   })
      //   .catch((err) => {
      //     dialog.close();
      //     console.log(err);
      //     errorHandler.fnRaiseError("", err.message);
      //   });
    },

    fnValidatePatientForm() {
      console.log("Validate patient form");
      if (this.elPatientForm && this.elPatientForm[0]) {
        console.log(this.elPatientForm[0].checkValidity());
        return this.elPatientForm[0].checkValidity();
      }
    },
    fnGetPatient(pid) {
      let that = this;
      console.log("Get Patient");
      patientStore.fnGetPatientByFirebaseUID(this.did, pid, function (data) {
        console.log("Patient");
        console.log(data);
        that.isPatientAccountCreated = utils.fnConvertNumberToBool(
          data.IsAccountCreated
        );

        that.PatientForm = data;
        that.$store.commit("setPatient", that.PatientForm);

        // that.signInForm.Email = data.Email;
        // that.signInForm.Phone = data.Phone;
        that.$f7router.navigate("/patient-view/");
      });
    },
    fnGetPatientByPhone(phone) {
      let that = this;

      console.log("Get Patient");
      if (this.did) {
        let dialog = f7App.dialog.preloader("Fetching profiles");
        patientStore.fnGetPatientByPhone(
          this.did,
          phone,
          function (data) {
            dialog.close();
            console.log("Patients");
            console.log(data);
            that.$store.commit("setPatientProfiles", data);

            that.$store.commit("setLoggedInUserData", data[0]);
            // that.signInForm.Email = data.Email;
            // that.signInForm.Phone = data.Phone;
            that.$f7router.navigate("/patient-view/");
          },
          function (err) {
            dialog.close();

            if (err == 403) {
              //Patient is not registered for the current doctor
              //Open patient registration form
              that.currentTabIndex = 3;
              that.signInForm.Phone = phone;
            } else {
              errorHandler.fnRaiseError();
              console.log(err);
            }
          }
        );
      } else {
        this.$f7router.navigate("/select-doctor/", {
          props: {
            patientPhone: phone,
          },
        });
      }
    },
    fnCreatePatient() {
      let that = this;

      this.PatientForm.DoctorId = this.currentUser.uid;
      this.PatientForm.PatientId = dbUtils.generateUID();
      this.PatientForm.PatientFirebaseUID = this.firebaseUser.uid;
      this.PatientForm.Phone = that.signInForm.Phone.replace("+91", "");
      this.PatientForm.IsAccountCreated = 1;

      let patientFormCopy = Object.assign({}, this.PatientForm);
      patientFormCopy.DateOfBirth = dateUtils
        .fnGetUTCTimeFromDateObjectRemoveTime(this.PatientForm.DateOfBirth)
        .toString();

      // console.log(patientFormCopy);
      patientStore.fnInsertPatient(
        patientFormCopy,
        function () {
          // dialog.close();
          that.PatientForm.PatientId = patientFormCopy.PatientId;
          that.PatientForm.PatientUID = patientFormCopy.PatientUID;

          errorHandler.fnRaiseSuccessWithCallback("", "", function () {
            // that.$store.commit("setPatient", that.PatientForm);
            that.$store.commit("setPatientProfiles", [that.PatientForm]);

            that.$store.commit("setLoggedInUserData", that.PatientForm);
            that.$f7router.navigate("/patient-view/");
          });
          // that.fnCreateNewChat(null, function () {
          //   errorHandler.fnRaiseSuccessWithCallback("", "", function () {
          //     // that.$store.commit("setPatient", that.PatientForm);
          //     that.$store.commit("setPatientProfiles", [that.PatientForm]);

          //     that.$store.commit("setLoggedInUserData", that.PatientForm);
          //     that.$f7router.navigate("/patient-view/");
          //   });
          // });
          // errorHandler.fnRaiseSuccessWithCallback("", "", function () {
          //   that.$store.commit("setPatient", that.PatientForm);
          //   that.$f7router.navigate("/patient-view/");
          // });

          // that.$f7router.navigate("/home/1/");
        },
        function (err) {
          errorHandler.fnRaiseError();
          console.log(err);
        }
      );
      // fireStore
      //   .patients(firebase)
      //   .doc(fireStore.generateUID())
      //   .set(this.PatientForm)
      //   .then(() => {
      //     this.$f7router.navigate("/home/");
      //     // console.log("Patient Registration Successful");
      //   })
      //   .catch(err => {
      //     console.log("patient collection error");
      //     console.log(err);
      //   });
    },

    fnUpdatePatient() {
      let that = this;
      let patientFormCopy = Object.assign({}, this.PatientForm);

      patientStore.fnUpdatePatient(
        patientFormCopy,
        function () {
          errorHandler.fnRaiseSuccessWithCallback("", "", function () {
            that.$store.commit("setPatient", that.PatientForm);

            that.$f7router.navigate("/patient-view/");
          });
        },
        function () {}
      );
    },
    // fnCreateNewChat(dialog, fnSuccess) {
    //   let that = this;
    //   let chat = {
    //     ChatId: dbUtils.generateUID(),
    //     DoctorId: this.currentUser.uid,
    //     PatientId: this.PatientForm.PatientId,
    //     Status: 2,
    //   };
    //   chatsStore.fnInsertChat(
    //     chat,
    //     function () {
    //       if (dialog) {
    //         dialog.close();
    //       }
    //       if (fnSuccess) {
    //         fnSuccess();
    //       }
    //       // that.fnGoToChatMessages(chat);
    //     },
    //     function () {
    //       if (dialog) {
    //         dialog.close();
    //       }

    //       errorHandler.fnRaiseError();
    //     }
    //   );
    // },
    fnGetLogoImageUrl(fnCallBack) {
      let imageUrl = "";
      let fileName = "";

      doctorLogoStore.fnGetImage(this.doctor.ConsultantId, function (items) {
        console.log("Fetched Items " + items.length);
        // console.log("Items " + items.length);
        if (items && items.length > 0) {
          console.log(items[0]);

          fileName = items[0].FileName;
          console.log(fileName);
          if (window.isCordova == true) {
            if (items[0].DeviceUrl != "" && items[0].DeviceUrl != null) {
              fileUtils.fnGetImageUrl(
                fileName,
                "DoctorLogos",
                "App",
                fnCallBack
              );
              // fnCallBack(items[0].StorageLocationDevice + fileName);
            }
          } else {
            fileUtils.fnGetImageUrl(fileName, "DoctorLogos", null, fnCallBack);
            // fnCallBack(items[0].ServerLocation);
          }
        }
      });
    },

    fnLogout() {
      let that = this;
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            that.$f7router.navigate(that.$f7router.currentRoute.url, {
              ignoreCache: true,
              reloadCurrent: true,
            });
            // that.$f7router.navigate("/" + that.currentUser.UniqueName + "/");
          },
          function () {
            that.$f7router.navigate(that.$f7router.currentRoute.url, {
              ignoreCache: true,
              reloadCurrent: true,
            });
          }
        );

      // doctorStore.fnRemoveDoctorDeviceLogin(function () {
      // that.fnGoToView("/");
      // });
    },
  },
};
</script>
