const dBSchema = require('./dbSchema.js');
const dbUtils = require('./dbUtils.js')
import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");

const fnCreateTables = function (db, tables) {
    db.transaction(function (tx) {
        tables.forEach(table => {
            fnCreateTable(table.name, table.props, tx);
        });
    }, function (error) {
        console.log('Transaction ERROR: ' + error.message);
    }, function () {
        console.log('Populated database OK');
    });
}

const fnCreateTable = function (tableName, props, tx = null) {
    tx.executeSql('CREATE TABLE IF NOT EXISTS ' + tableName + ' (' + dbUtils.fnBuildColumnListWithTypeFromProps(props) + ')');
}

const fnInitDB = function () {
    console.log("Init DB");
    // let localDB = window.sqlitePlugin.openDatabase({
    //     name: 'scripty.db',
    //     location: 'default',
    //     iosDatabaseLocation: 'default'
    // });
    let localDB = window.sqlitePlugin.openDatabase({
        name: 'scripty.db',
        iosDatabaseLocation: 'default'
    });
    console.log("creating tables")
    console.log(localDB)
    fnCreateTables(localDB, dBSchema.tables);
    return localDB;
}

const fnGetNextSerialNumber = function (primaryKeyName, tableName, firebaseCollectionName, serialNumberColumnName, orderByColumn, order, props, fnSuccessCallback) {
    let outputNumber = -1;
    let outputSerialNumber = "";
    let date = "";
    let month = "";
    let year = "";
    let _date = new Date();
    date = ("0" + _date.getDate()).slice(-2);
    month = ("0" + (_date.getMonth() + 1)).slice(-2);
    year = _date.getUTCFullYear();
    if (tableName == "Doctors") {

        outputSerialNumber = "DN" + date + month + year;
    }
    else if (tableName == "Patients") {

        outputSerialNumber = "PN" + date + month + year;

    }
    else if (tableName == "Prescriptions") {

        outputSerialNumber = "RX" + date + month + year;
    }
    console.log(outputSerialNumber)

    if (window.isCordova == true) {
        let sql = "SELECT " + serialNumberColumnName + " FROM " + tableName
            + "ORDER BY " + orderByColumn + " " + order + " LIMIT 1";
        let objectArray = [];
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                objectArray = dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props);
                if (objectArray && objectArray.length > 0) {
                    let objectItem = objectArray[0][serialNumberColumnName];
                    console.log(objectItem);
                    console.log(objectArray);
                    outputNumber = parseInt(objectItem.substring(9, objectItem.length - 9), 10);
                    outputNumber = outputNumber++;
                    console.log(outputNumber);
                    outputSerialNumber = outputSerialNumber + outputNumber;
                }
                else {
                    outputSerialNumber = outputSerialNumber + "1";
                }
                if (fnSuccessCallback) {
                    fnSuccessCallback(outputSerialNumber);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        fireStore[firebaseCollectionName](firebase)
            .orderBy(orderByColumn, order).limit(1)
            .get()
            .then(items => {
                // console.log("Patients Found");
                let objectItems = [];
                items.forEach(doc => {
                    if (doc) {
                        objectItems.push(dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props));
                    }
                });
                if (objectItems.length > 0) {
                    console.log(objectItems[0]);
                    let objectItem = objectItems[0][serialNumberColumnName];
                    outputNumber = parseInt(objectItem.substring(9), 10);
                    console.log(outputNumber);

                    outputNumber = outputNumber + 1;
                    console.log(outputNumber);
                    outputSerialNumber = outputSerialNumber + outputNumber;

                }
                else {
                    outputSerialNumber = outputSerialNumber + "1";
                }
                console.log(outputSerialNumber);
                if (fnSuccessCallback) {
                    fnSuccessCallback(outputSerialNumber);
                }
            })
            .catch(err => {
                console.log("patient collection error");
                console.log(err);
            });
    }
}


export {
    fnInitDB,
    fnGetNextSerialNumber
}