import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");

const syncService = require('../services/syncService.js')
const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')


const fnInsertItem = function (item, tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isSync = true, isEncryption = false, isServerInsert = true) {
    let props = dbSchema.tables.find(t => t.name == tableName).props;
    let primaryKeyName = props.find(t => t.primaryKey == true).name;

    dbUtils.fnTrimTextOnObject(item, props);
    dbUtils.fnSetGlobalFields(item);
    let encryptedProps = null;
    if (isEncryption == true) {
        encryptedProps = props.filter(t => t.isEncrypted == true);
        dbUtils.fnEncryptFromProps(item, encryptedProps);

    }
    if (window.isCordova == true) {
        let sql = 'INSERT OR REPLACE INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
        console.log(sql);

        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                console.log('First insert block');

                if (isSync == true) {
                    syncService.fnInitiateSync(tableName, null, null, fireStoreCollectionObject);
                }
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            }, function (tx, error) {
                console.log('SQL ERROR: ' + error.message);
                if (fnFailureCallBack) {
                    fnFailureCallBack(error);
                }
            })

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        })

    } else {
        // console.log("Item", item)
        // console.log(fnSuccessCallback)
        // console.log(isServerInsert)
        if (isServerInsert == true) {
            fireStoreCollectionObject
                .doc(item[primaryKeyName])
                .set(dbUtils.fnBuildObjectForFirestore(item[primaryKeyName], item, primaryKeyName, props, encryptedProps)).then(function () {
                    if (fnSuccessCallback) {
                        console.log("Success")
                        fnSuccessCallback();
                    }
                })
        }
    }

}

const fnInsertMultipleItems = function (items, tableName, fireStoreCollectionObject, fireStoreCollectionKey, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isSync = true, isEncryption = false, isPassCollectionKeyToFireStore = false) {
    let props = dbSchema.tables.find(t => t.name == tableName).props;
    let primaryKeyName = props.find(t => t.primaryKey == true).name;

    if (window.isCordova == true) {
        let sql = 'INSERT OR REPLACE INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') ';

        items.forEach((item, index) => {
            dbUtils.fnTrimTextOnObject(item, props);
            dbUtils.fnSetGlobalFields(item);
            if (isEncryption == true) {
                let encryptedProps = props.filter(t => t.isEncrypted == true);
                dbUtils.fnEncryptFromProps(item, encryptedProps);

            }
            if (index == 0) {
                sql = sql + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';

            } else {
                sql = sql + ',(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
            }
        })

        // console.log(sql);

        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (isSync == true) {
                    syncService.fnInitiateSync(tableName, fireStoreCollectionName, null, null, isPassCollectionKeyToFireStore, fireStoreCollectionKey);
                }
                if (fnSuccessCallback) {
                    // console.log("Success")
                    fnSuccessCallback(resultSet);
                }
            }, function (tx, error) {
                console.log('SQL ERROR: ' + error.message);
                if (fnFailureCallBack) {
                    fnFailureCallBack(error);
                }
            })

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        })

    } else {

        let batch = fireStore.fnGetBatch(firebase);
        let newItem = {};
        items.forEach(item => {
            dbUtils.fnTrimTextOnObject(item, props);
            dbUtils.fnSetGlobalFields(item);
            if (isEncryption == true) {
                let encryptedProps = props.filter(t => t.isEncrypted == true);
                dbUtils.fnEncryptFromProps(item, encryptedProps);

            }
            if (fireStoreCollectionObject) {
                newItem = fireStoreCollectionObject
                    .doc(item[primaryKeyName]);
                batch.set(newItem, item);
            } else {
                newItem = fireStore[fireStoreCollectionName](firebase, item[fireStoreCollectionKey])
                    .doc(item[primaryKeyName]);
                batch.set(newItem, item);
            }

        });

        batch.commit().then(function () {
            if (fnSuccessCallback) {
                console.log("Success")
                fnSuccessCallback();
            }
            // console.log("that.masterMeds");
            //console.log(that.masterMeds);
            // this.MstMedicines.push(medicine.model);


        });
        // that.$f7router.navigate("/patient-view/");


    }

}

const fnInsertItemFromServerIntoLocalDB = function (item, tableName, fnSuccessCallback, fnFailureCallBack, isEncryption = false) {
    let props = dbSchema.tables.find(t => t.name == tableName).props;
    let primaryKeyName = props.find(t => t.primaryKey == true).name;

    dbUtils.fnTrimTextOnObject(item, props);
    dbUtils.fnSetGlobalFields(item);
    if (isEncryption == true) {
        let encryptedProps = props.filter(t => t.isEncrypted == true);
        dbUtils.fnEncryptFromProps(item, encryptedProps);

    }
    if (window.isCordova == true) {
        let sql = 'INSERT INTO ' + tableName + ' (' + dbUtils.fnBuildCommaSeparatedListFromProps(props) + ') '
            + 'VALUES(' + dbUtils.fnBuildValuesStringFromProps(item, props) + ')';
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {

                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }

}
const fnGetItems = function (tableName, sql, params, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption = false) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, params, function (tx, resultSet) {
                if (resultSet.rows.length > 0) {
                    if (fnSuccessCallback) {
                        let props = dbSchema.tables.find(t => t.name == tableName).props;

                        if (isEncryption == true) {

                            let encryptedProps = props.filter(t => t.isEncrypted == true);
                            dbUtils.fnEncryptFromProps(item, encryptedProps);

                        }
                        console.log("Got Items Device" + tableName, dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props))
                        fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                    }
                } else {
                    console.log("Get Items Server" + tableName)

                    fnUpdateItemsFromServer(tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption);
                }

            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        // console.log(doctorId)
        fnUpdateItemsFromServer(tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption);

    }

}
const fnGetItemsFromDevice = function (tableName, sql, params, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption = false) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {
            // console.log("Querying Device app state")
            tx.executeSql(sql, params, function (tx, resultSet) {
                if (resultSet.rows.length > 0) {
                    if (fnSuccessCallback) {
                        let props = dbSchema.tables.find(t => t.name == tableName).props;

                        if (isEncryption == true) {

                            let encryptedProps = props.filter(t => t.isEncrypted == true);
                            dbUtils.fnEncryptFromProps(item, encryptedProps);

                        }
                        fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props));
                    }
                } else {
                    fnSuccessCallback([]);
                }

            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }

}
const fnUpdateItemsFromServer = function (tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption = false) {
    fireStoreCollectionObject.get().then(function (items) {
        let _items = [];
        let _item = null;
        // console.log("Getting items from server")
        // console.log("Getting items from server", tableName)
        let props = dbSchema.tables.find(t => t.name == tableName).props;
        // console.log("props", props)
        let primaryKeyName = props.find(t => t.primaryKey == true).name;

        items.forEach(function (doc) {
            if (isEncryption == true) {
                _item = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
            } else {
                _item = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);
            }
            fnInsertItemFromServerIntoLocalDB(_item, tableName, null, null, isEncryption);
            _items.push(doc.data());
        });
        if (fnSuccessCallback) {
            // console.log(_items);
            fnSuccessCallback(_items);
        }

    }, function (error) {
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    }).catch(function (error) {
        console.log("Error getting documents: ", error);
    });
}
const fnGetItemsFromServer = function (tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isEncryption = false) {
    fireStoreCollectionObject.get().then(function (items) {
        let _items = [];
        let _item = null;
        // console.log("Getting items from server")
        // console.log("Getting items from server", tableName)
        let props = dbSchema.tables.find(t => t.name == tableName).props;
        // console.log("props", props)
        let primaryKeyName = props.find(t => t.primaryKey == true).name;

        items.forEach(function (doc) {
            if (isEncryption == true) {
                _item = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props, encryptedProps);
            } else {
                _item = dbUtils.fnBuildObjectFromFirestore(doc.id, doc.data(), primaryKeyName, props);
            }
            // fnInsertItemFromServerIntoLocalDB(_item, tableName, null, null, isEncryption);
            _items.push(doc.data());
        });
        if (fnSuccessCallback) {
            // console.log(_items);
            fnSuccessCallback(_items);
        }

    }, function (error) {
        if (fnFailureCallBack) {
            fnFailureCallBack(error);
        }
    }).catch(function (error) {
        console.log("Error getting documents: ", error);
    });
}
const fnUpdateItem = function (item, tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack, isSync = true, isDeviceOnly = false) {
    if (isSync == true) {
        item.IsUpdateSyncPending = 1;
    }
    let props = dbSchema.tables.find(t => t.name == tableName).props;
    let primaryKeyName = props.find(t => t.primaryKey == true).name;
    let updatableProps = props.filter(t => t.isUpdatable == true);
    if (window.isCordova == true) {
        let sql = 'UPDATE ' + tableName + ' SET ' + dbUtils.fnBuildUpdateColumnsList(updatableProps, item)
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (isSync == true) {
                    if (fireStoreCollectionObject) {
                        syncService.fnInitiateUpdateSync(tableName, fireStoreCollectionName, null, fireStoreCollectionObject);

                    } else {
                        syncService.fnInitiateUpdateSync(tableName, fireStoreCollectionName);
                    }
                }
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {
        if (isDeviceOnly == false) {
            console.log("Updating Item", item);
            item.IsUpdateSyncPending = 0;
            fireStoreCollectionObject
                .doc(item[primaryKeyName])
                .update(dbUtils.fnGetUpdatableObject(updatableProps, item))
                .then(() => {
                    if (fnSuccessCallback) {
                        fnSuccessCallback();
                    }
                })
                .catch(err => {
                    console.log("collection error");
                    console.log(err);
                });
        } else {
            fnSuccessCallback();
        }
    }

}

const fnDeleteItem = function (item, tableName, fireStoreCollectionObject, fireStoreCollectionName, fnSuccessCallback, fnFailureCallBack) {
    let props = dbSchema.tables.find(t => t.name == tableName).props;
    let primaryKeyName = props.find(t => t.primaryKey == true).name;
    if (window.isCordova == true) {
        let sql = 'DELETE FROM ' + tableName
            + ' WHERE ' + primaryKeyName + " = '" + item[primaryKeyName] + "'";
        // console.log(sql);
        window.localDB.transaction(function (tx) {
            tx.executeSql(sql, [], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(resultSet);
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    } else {

        fireStoreCollectionObject
            .doc(item[primaryKeyName])
            .delete()
            .then(() => {
                if (fnSuccessCallback) {
                    fnSuccessCallback();
                }
            })
            .catch(err => {
                console.log("collection error");
                console.log(err);
            });
    }

}

export {
    fnInsertItem,
    fnInsertMultipleItems,
    fnGetItems,
    fnUpdateItem,
    fnDeleteItem,
    fnUpdateItemsFromServer,
    fnGetItemsFromServer,
    fnGetItemsFromDevice
}