<template>
  <div class="h-100 overflow-y-auto">
    <f7-block-title>
      <span>Payment Account Options</span>
    </f7-block-title>
    <f7-list>
      <f7-list-item v-if="PaymentThirdParties.length == 0"
        >No payment accounts added by doctor</f7-list-item
      >
      <f7-list-item
        v-for="(PaymentThirdParty, index) in PaymentThirdParties"
        :key="index"
      >
        <div class="f-column">
          <span class="f-column">
            <span
              v-if="
                PaymentThirdParty.AccountType &&
                PaymentThirdParty.AccountType != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">Account Type</span>:&nbsp;{{
                fnGetAccountTypeName(PaymentThirdParty.AccountType)
              }}</span
            >
            <span
              v-if="
                PaymentThirdParty.AccountHolderName &&
                PaymentThirdParty.AccountHolderName != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">Account Holder</span>:&nbsp;{{
                PaymentThirdParty.AccountHolderName
              }}</span
            >
            <span
              v-if="
                PaymentThirdParty.ProviderName &&
                PaymentThirdParty.ProviderName != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">Provider</span>:&nbsp;{{
                PaymentThirdParty.ProviderName
              }}</span
            >
            <span
              v-if="PaymentThirdParty.UPIId && PaymentThirdParty.UPIId != ''"
              class="margin-right-small"
              ><span class="text-secondary">UPI ID</span>:&nbsp;{{
                PaymentThirdParty.UPIId
              }}</span
            >
            <span
              v-if="
                PaymentThirdParty.PhoneNumber &&
                PaymentThirdParty.PhoneNumber != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">Phone Number</span>:&nbsp;{{
                PaymentThirdParty.PhoneNumber
              }}</span
            >
            <span
              v-if="
                PaymentThirdParty.BankAccountNumber &&
                PaymentThirdParty.BankAccountNumber != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">Bank Account No</span>:&nbsp;{{
                PaymentThirdParty.BankAccountNumber
              }}</span
            >
            <span
              v-if="
                PaymentThirdParty.IFSCCode && PaymentThirdParty.IFSCCode != ''
              "
              class="margin-right-small"
              ><span class="text-secondary">IFSC Code</span>:&nbsp;{{
                PaymentThirdParty.IFSCCode
              }}</span
            >
          </span>
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
const fireStore = require("../store/firebaseStore.js");
import firebase from "firebase/app";
import "firebase/firestore";
import { mapState } from "vuex";
import ImageUpload from "../shared/image-upload.vue";
const paymentConfirmationImagesStore = require("../store/paymentConfirmationImagesStore.js");
const appointmentsStore = require("../store/appointmentsStore.js");

const dbUtils = require("../store/dbUtils.js");
const errorHandler = require("../shared/error-handler.js");

export default {
  name: "doctor-payment-accounts",
  data: function () {
    return {
      imageUrls: [],
      paymentImageUrl: "",
      uploadFolder: "PaymentConfirmationImages",
      accountTypes: [
        {
          Name: "Bank Account",
          Value: 1,
        },
        {
          Name: "UPI",
          Value: 2,
        },
        {
          Name: "Phone Number",
          Value: 3,
        },
      ],
      PaymentThirdParties: [],
    };
  },
  components: {
    ImageUpload,
  },
  props: {
    appointmentId: null,
  },
  computed: {
    ...mapState(["currentUser", "__CurrentPatient", "__PaymentThirdParties"]),
  },
  created: function () {
    this.PaymentThirdParties = this.__PaymentThirdParties;
  },
  mounted: function () {
    let that = this;

    // console.log(this.IsEdit);
  },
  methods: {
    fnGetAccountTypeName(accountType) {
      return this.accountTypes.find((a) => a.Value == accountType).Name;
    },
  },
};
</script>
