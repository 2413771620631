const crypto = require('../shared/crypto.js')
const dateUtils = require('../shared/dateUtils.js')
import firebase from "firebase/app";

// import moment from "moment";

const fnBuildColumnListWithTypeFromProps = function (props) {
    let columns = "";
    props.forEach((column, index) => {
        if (index != 0) {
            columns = columns + ", " + column.name + " " + column.type;
            if (column.primaryKey) {
                columns = columns + " PRIMARY KEY";
            }
        } else {
            columns = column.name + " " + column.type;
            if (column.primaryKey) {
                columns = columns + " PRIMARY KEY";
            }
        }

    });
    // console.log(columns)
    return columns;
}

const fnBuildCommaSeparatedListFromProps = function (props) {
    let columns = "";
    props.forEach((column, index) => {
        if (index != 0) {
            columns = columns + ", " + column.name;
        } else {
            columns = column.name;
        }
    });
    return columns;
}
const fnGetUpdatableObject = function (props, data) {
    let obj = {};
    props.forEach((column, index) => {
        if (data.hasOwnProperty(column.name) && data[column.name] !== undefined) {

            obj[column.name] = data[column.name];
        }
    });
    return obj;

}
const fnBuildUpdateColumnsList = function (props, data) {
    let columns = "";
    let _props = [];
    props.forEach((column, index) => {
        if (data.hasOwnProperty(column.name) && data[column.name] !== undefined) {
            _props.push(column);
        }
    });
    _props.forEach((column, index) => {

        if (index != 0) {
            columns = columns + ", " + column.name + " = '" + data[column.name] + "'";
        } else {

            columns = column.name + " = '" + data[column.name] + "'";
        }

    });
    return columns;
}

const fnEncryptFromProps = function (obj, props) {
    props.forEach((prop, index) => {
        if (obj.hasOwnProperty(prop.name) && obj[prop.name] !== undefined) {
            obj[prop.name] = crypto.fnEncode(obj[prop.name]);
        }
    });

    return obj;
}
const fnDecryptFromProps = function (obj, props) {
    props.forEach((prop, index) => {
        obj[prop] = crypto.fnDecode(obj[prop]);
    });
    return obj;
}
const fnEncrypt = function (item) {

    return crypto.fnEncode(item);
}
const fnDecrypt = function (item) {

    return crypto.fnDecode(item);
}
const fnBuildValuesStringFromProps = function (obj, props) {
    let columns = "";
    props.forEach((prop, index) => {
        if (index != 0) {
            if (prop.type != 'NUMBER') {
                if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                    columns = columns + ", null";
                }
                else {
                    columns = columns + ", '" + obj[prop.name] + "'";

                }
                // columns = columns + ", '" + obj[prop.name] + "'";
            } else {
                if (prop.type == 'NUMBER') {


                    if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                        columns = columns + ", null";
                    }
                    else {
                        columns = columns + ", " + obj[prop.name];

                    }
                } else {
                    if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                        columns = columns + ", null";
                    }
                    else {
                        columns = columns + ", " + obj[prop.name];

                    }
                    // columns = columns + ", " + obj[prop.name];
                }
            }
        } else {
            if (prop.type != 'NUMBER') {
                if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                    columns = "null";
                }
                else {
                    columns = "'" + obj[prop.name] + "'";

                }
                // columns = "'" + obj[prop.name] + "'";
            }
            else {
                if (prop.type == 'NUMBER') {
                    if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                        columns = "null";
                    }
                    else {
                        columns = obj[prop.name];

                    }
                } else {
                    if (obj[prop.name] === undefined || obj[prop.name] == null || obj[prop.name] === "") {
                        columns = "null";
                    }
                    else {
                        columns = obj[prop.name];
                    }
                    // columns = obj[prop.name];
                }
                // columns = obj[prop.name];
            }
        }
    });
    return columns;
}
const fnTrimTextOnObject = function (obj, props) {
    props.forEach(prop => {
        if (prop.type == 'TEXT' && obj[prop.name] != null) {

            obj[prop.name] = obj[prop.name].toString().trim();
            //Clean up special characters
            // obj[prop.name] = obj[prop.name].replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, ' ');
        }
    })
    return obj;
}
const fnBuildObjectArrayFromRows = function (rows, props, encryptedProps) {
    let obj = {};
    let objArray = [];
    // console.log(JSON.stringify(rows));
    if (rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
            props.forEach(prop => {

                obj[prop.name] = rows.item(i)[prop.name];
                if (encryptedProps && encryptedProps.length > 0) {
                    encryptedProps.forEach(encProp => {
                        if (encProp.name == prop.name) {

                            obj[prop.name] = crypto.fnDecode(obj[prop.name]);
                        }
                    })
                }
            });
            objArray.push(obj);
            obj = {};
        }
    }

    return objArray;
}

const fnBuildObjectFromFirestore = function (id, data, primaryKey, props, encryptedProps) {
    let obj = {};
    data[primaryKey] = id;
    props.forEach(prop => {
        // console.log(prop.name)
        // console.log(data[prop.name])
        if (data[prop.name] !== undefined && data[prop.name] != null && data[prop.name] !== "") {
            obj[prop.name] = data[prop.name];

            if (encryptedProps && encryptedProps.length > 0) {
                // console.log("Starting crypto");

                encryptedProps.forEach(encProp => {

                    if (encProp.name == prop.name) {
                        // console.log("Starting decryption");

                        // console.log(obj[prop.name]);

                        obj[prop.name] = crypto.fnDecode(obj[prop.name].toString());
                        // console.log(obj[prop.name]);

                    }
                })
            }
            if (prop.isJSONArray == true) {
                if (obj[prop.name]) {
                    obj[prop.name] = obj[prop.name].replace(/{'/g, '{"');
                    obj[prop.name] = obj[prop.name].replace(/'}/g, '"}');
                    obj[prop.name] = obj[prop.name].replace(/',/g, '",');
                    obj[prop.name] = obj[prop.name].replace(/':'/g, '":"');

                    obj[prop.name] = obj[prop.name].replace(/':/g, '":');
                    obj[prop.name] = obj[prop.name].replace(/,'/g, ',"');
                    obj[prop.name] = obj[prop.name].replace(/','/g, '","');

                    obj[prop.name] = obj[prop.name].replace(/\['/g, '["');
                    obj[prop.name] = obj[prop.name].replace(/\']/g, '"]');

                }
            }
        } else {

            obj[prop.name] = null;

        }
    })
    return obj;
}

const generateUID = function () {
    var uuidValue = "", k, randomValue;
    for (k = 0; k < 32; k++) {
        randomValue = Math.random() * 16 | 0;

        if (k == 8 || k == 12 || k == 16 || k == 20) {
            uuidValue += "-"
        }
        uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
    }
    return uuidValue;
}
const fnGenerateUniqueStringId = function () {
    return (
        Date.now().toString(36) +
        Math.random()
            .toString(36)
            .substr(2, 5)
    ).toUpperCase();
}
const fnBuildObjectForFirestore = function (id, data, primaryKey, props, encryptedProps) {
    let obj = {};
    data[primaryKey] = id;
    props.forEach(prop => {
        // if (prop.isJSONArray) {
        //     if (!(data[prop.name] === undefined || data[prop.name] == null || data[prop.name] === "" || typeof data[prop.name] === 'object')) {
        //         data[prop.name] = data[prop.name].replace(/"/g, "'");
        //     }
        // }
        // console.log(prop.name)
        // console.log(data[prop.name])
        if (data[prop.name] !== undefined && data[prop.name] != null && data[prop.name] !== "") {
            obj[prop.name] = data[prop.name];

            if (encryptedProps && encryptedProps.length > 0) {
                // console.log("Starting crypto");

                encryptedProps.forEach(encProp => {

                    if (encProp.name == prop.name) {
                        // console.log("Starting decryption");

                        // console.log(obj[prop.name]);

                        obj[prop.name] = crypto.fnDecode(obj[prop.name].toString());
                        // console.log(obj[prop.name]);

                    }
                })
            }
            // obj[prop.name] = encodeURI(obj[prop.name]);
        } else {

            obj[prop.name] = null;

        }
    })
    return obj;
}
const fnHash = function (message) {
    return crypto.fnHash(message);
}
const fnSetGlobalFields = function (item) {
    if (!item.ServerTimestamp) {
        item.ServerTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    }
    item.IsActive = 1;
    if (item.IsSynced != 1) {
        item.IsSynced = 0;

    }

    item.IsUpdateSyncPending = 0;
    if (!item.CreatedDate) {
        let date = new Date();
        item.CreatedDate = (new Date()).toUTCString();
        item.CreatedDateMs = dateUtils.fnGetUTCTimeFromDateObject(date);

        // item.CreatedDateMs = new Date(item.CreatedDate).getTime();
    }

}

const fnCreateObject = function (props) {
    let obj = {};
    props.forEach(prop => {
        if (prop.type == 'TEXT') {

            obj[prop.name] = "";

        } else if (prop.type == "NUMBER") {
            obj[prop.name] = null;
        }
    })
    return obj;
}
export {
    fnBuildColumnListWithTypeFromProps,
    fnBuildCommaSeparatedListFromProps,
    fnBuildValuesStringFromProps,
    fnBuildObjectArrayFromRows,
    generateUID,
    fnBuildObjectFromFirestore,
    fnBuildObjectForFirestore,
    fnBuildUpdateColumnsList,
    fnGetUpdatableObject,
    fnGenerateUniqueStringId,
    fnEncryptFromProps,
    fnDecryptFromProps,
    fnTrimTextOnObject,
    fnEncrypt,
    fnDecrypt,
    fnHash,
    fnSetGlobalFields,
    fnCreateObject
}