<template>
  <div class="h-100 overflow-hidden">
    <f7-block-title>Select Dates</f7-block-title>

    <div class="f-row f-vertical-center margin-small">
      <div class="f-spread margin-right-large">
        <div class="f-row margin-bottom-small">
          <label style="min-width:80px">Start Date:</label>

          <datepicker
            calendar-class="offset-calendar"
            placeholder
            :disabled-dates="disabledDates"
            v-model="startDate"
          ></datepicker>
        </div>
        <div class="f-row">
          <label style="min-width:80px">End Date:</label>
          <datepicker
            placeholder
            calendar-class="offset-calendar"
            :disabled-dates="disabledDates"
            v-model="endDate"
          ></datepicker>
        </div>
      </div>

      <f7-button raised fill color="green" @click="fnGetPatientFollowUps">Go</f7-button>
    </div>
    <f7-block-title>Follow-ups</f7-block-title>
    <div class="overflow-y-auto">
      <f7-list>
        <f7-list-item v-if="followUps.length==0">No follow-ups found for the selected dates</f7-list-item>
        <f7-list-item
          v-for="followUp in followUps"
          :key="followUp.MedicalRecordId"
          link="#"
          @click="fnGoToPatientView(followUp.PatientId)"
        >
          <div slot="media" class="avatar-circle">
            <span class="initials">{{fnGetFirstLetter(followUp.PatientName)}}</span>
          </div>
          <div class="f-row f-space-between w-100">
            <span>{{followUp.PatientName}}</span>
            <span class="text-secondary">{{fnFormatFollowUpDate(followUp.FollowUpDate)}}</span>
          </div>
        </f7-list-item>
      </f7-list>
    </div>
  </div>
</template>
<style>
.vdp-datepicker {
  width: 100%;
}
.vdp-datepicker input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.offset-calendar {
  position: relative;
  right: 0;
  left: -50px;
}
</style>
<script>
const followUpsStore = require("../store/followUpsStore.js");
const errorHandler = require("../shared/error-handler.js");
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "follow-ups",
  components: {
    Datepicker,
  },
  mounted: function () {
    let yesterday = new Date().setDate(new Date().getDate() - 1);
    this.disabledDates.to = new Date(yesterday);
    // console.log("yesterday");
    // console.log(yesterday);
    // console.log(this.endDate);
    // console.log(this.__followUp);
    if (this.__followUp && this.__followUp.startDate) {
      // console.log("Previous FOlowup");
      this.startDate = new Date(this.__followUp.startDate);
      this.endDate = new Date(this.__followUp.endDate);
      // console.log(this.startDate);
      // console.log(this.endDate);
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
    }
    this.fnGetPatientFollowUps();
  },
  data: function () {
    return {
      followUps: [],
      disabledDates: {
        // to: new Date(),
      },
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapState(["currentUser", "__followUp"]),
  },
  props: {},
  methods: {
    fnGetFirstLetter(text) {
      if (text && text.length > 0) {
        return text[0];
      }
      return "";
    },
    fnFormatFollowUpDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    fnGetPatientFollowUps() {
      let that = this;

      let startDate = moment(this.startDate).format("YYYY-MM-DD");
      let endDate = moment(this.endDate).format("YYYY-MM-DD");
      console.log(startDate);
      console.log(endDate);
      followUpsStore.fnGetPatientFollowUps(
        this.currentUser.uid,
        startDate,
        endDate,
        function (records) {
          console.log("Success");
          console.log(records);
          that.followUps = records;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    fnGoToPatientView(patientId) {
      // console.log(patientId);
      this.$store.commit("setFollowUpState", {
        startDate: this.startDate,
        endDate: this.endDate,
      });

      // this.$store.commit("setPatient", patient);
      this.$f7router.navigate("/patient-view/", {
        props: {
          patientId: patientId,
          prevPage: "FollowUp",
        },
      });
    },
  },
};
</script>