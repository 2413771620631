<template>
  <div class="f-column f-grow">
    <!-- <f7-page name="prescriptions"> -->
    <!-- <f7-toolbar class="f-row f-vertical-center f-end">
      <div slot="after-inner">
        <f7-button
          raised
          fill
          small
          color="white"
          class="margin-right-small"
          @click="fnGoToPrescriptionCreate()"
        >
          <span class="f-row f-vertical-center">
            <f7-icon size="18" class="margin-right-xsmall" material="note_add"></f7-icon>New Prescription
          </span>
        </f7-button>
      </div>
    </f7-toolbar>-->

    <f7-block-title class="background-secondary block-title-small">
      <span>Prescriptions</span>
    </f7-block-title>
    <f7-list no-hairlines v-if="MedicalRecords.length == 0">
      <f7-list-item>No prescriptions found.</f7-list-item>
    </f7-list>
    <div class="f-column f-grow overflow-y-auto" v-else>
      <!-- <div class="timeline">
        <div
          class="timeline-item"
          v-for="(MedicalRecord, index) in MedicalRecords"
          v-bind:key="index"
        >
          <div class="timeline-item-date text-secondary">
            <small>{{MedicalRecord.Date | formatDate}}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content w-100">
            <div
              class="timeline-item-inner history-item"
              @click="fnGoToMedicalRecordView(MedicalRecord)"
            >
              <div class="f-row f-space-between f-vertical-center">
                <div>
                  <f7-icon size="18" class="margin-right-xsmall" material="assignment"></f7-icon>
                  <span class="font-small">Medical Record {{MedicalRecordsCount - index}}</span>
                </div>
                <f7-icon size="18" class="margin-right-xsmall" material="chevron_right"></f7-icon>
              </div>
             
            </div>
          </div>
        </div>
      </div> -->
      <f7-list no-hairlines class="item-media-small">
        <f7-list-item
          v-for="(MedicalRecord, index) in MedicalRecords"
          v-bind:key="index"
          link="#"
          @click="fnGoToMedicalRecordView(MedicalRecord)"
        >
          <div slot="media">
            <f7-icon
              size="18"
              class="text-primary"
              material="assignment"
            ></f7-icon>
          </div>
          <div>
            <div>
              <span>Prescription {{ MedicalRecordsCount - index }}</span>
            </div>
            <div class="font-xsmall text-secondary">
              {{ MedicalRecord.Date | formatDate }}
            </div>
          </div>
          <!-- <div>
            <make-payment
              :amountToBePaid="'20'"
              :itemId="MedicalRecord.MedicalRecordId"
              :itemType="'medicalrecords'"
              :callbackUrl="'/patient-view/3/'"
             
            ></make-payment>
          </div> -->
        </f7-list-item>
      </f7-list>
    </div>

    <!-- <f7-list>
      <f7-list-item
        v-for="(Prescription, index) in Prescriptions"
        v-bind:key="Prescription.PrescriptionId"
        :title="'Prescription '+ (PrescriptionsCount - index)"
        link="#"
        @click="fnGoToPrescriptionView(Prescription)"
      >
        <span class="font-xsmall">{{Prescription.Date | formatDate}}</span>
      </f7-list-item>
    </f7-list>-->
  </div>
</template>


<script>
import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("../store/firebaseStore.js");
// const Prescription = require("../models/Prescription.js");
const medicalRecordStore = require("../store/medicalRecordStore.js");
const utils = require("../shared/utils.js");
// import MakePayment from "./make-payment.vue";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "medical-record-list",
  created: function () {
    this.Patient = this.__CurrentPatient;
  },
  components: {
    // MakePayment,
  },
  mounted: function () {
    this.fnGetMedicalRecords();

    this.$store.commit("setCopiedMedicalRecord", null);
  },
  data: function () {
    return {
      MedicalRecords: ([] = []),
      MedicalRecordsCount: 0,
      Patient: {},
    };
  },
  methods: {
    fnGetMedicalRecords() {
      this.MedicalRecords = [];
      let that = this;
      medicalRecordStore.fnGetMedicalRecords(
        this.__CurrentPatient.PatientId,
        function (item) {
          // if (items && items.length > 0) {
          //   items.forEach((item) => {
          //     that.MedicalRecords.push(item);
          //   });
          // }
          // console.log("Medical Record From Server Insert", item);
          let replacedArray = utils.fnReplaceItemInArray(
            that.MedicalRecords,
            item,
            "MedicalRecordId"
          );
          // console.log("Appointments After Replace", replacedArray);

          let uniqueArray = utils.fnUniqueUnion(
            replacedArray,
            [item],
            "MedicalRecordId"
          );
          // console.log("Appointments After Unique", uniqueArray);
          // console.log(uniqueArray);
          uniqueArray = utils.fnSortArrayDescending(uniqueArray, "DateMs");
          // console.log(uniqueArray);

          that.MedicalRecords = Array.from(uniqueArray);

          // that.todaysAppointments = utils.fnUniqueUnion(
          //   that.todaysAppointments,
          //   [item],
          //   "AppointmentId"
          // );
          // console.log("Appointments Post Process", that.todaysAppointments);
          that.MedicalRecordsCount = that.MedicalRecords.length;

          that.$forceUpdate();

          // console.log(that.MedicalRecords);
        },
        function () {}
      );
      // fireStore
      //   .prescriptions(firebase, this.__CurrentPatient.PatientId)
      //   .orderBy("Date", "desc")
      //   .get()
      //   .then(items => {
      //     items.forEach(doc => {
      //       if (doc) {
      //         that.Prescriptions.push(Prescription.fnMap(doc.id, doc.data()));
      //       }
      //     });
      //     that.PrescriptionsCount = that.Prescriptions.length;
      //   })
      //   .catch(function(error) {
      //     console.log("Error getting documents: ", error);
      //   });
    },
    fnGoToMedicalRecordView(medicalRecord) {
      this.$store.commit("setMedicalRecord", medicalRecord);
      this.$f7router.navigate("/medical-record-view/");
    },
    fnGoToMedicalRecordCreate() {
      let newId = fireStore.generateUID();
      this.$f7router.navigate("/medical-record-create/" + newId + "/");
    },
  },
  computed: {
    ...mapState(["__CurrentPatient"]),
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val;
      if (val.toDate) {
        date = val.toDate();
      } else {
        date = new Date(date);
      }
      return moment(date).format("DD MMM YYYY");
    },
  },
};
</script>

