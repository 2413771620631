import firebase from "firebase/app";
import "firebase/firestore";
const fireStore = require("./firebaseStore.js");



const dbSchema = require('./dbSchema.js')
const dbUtils = require('./dbUtils.js')
const tableName = "MedicalRecords";
const props = dbSchema.tables.find(t => t.name == tableName).props;
const primaryKeyName = props.find(t => t.primaryKey == true).name;
const updatableProps = props.filter(t => t.isUpdatable == true);
const encryptedProps = props.filter(t => t.isEncrypted == true);
const syncService = require('../services/syncService.js')
const dbLocal = require('./dbLocal.js');


const fnGetPatientFollowUps = function (doctorId, startDate, endDate, fnSuccessCallback, fnFailureCallBack) {
    if (window.isCordova == true) {
        window.localDB.transaction(function (tx) {


            let sql = "SELECT * FROM " + tableName
                + " WHERE DoctorId = ? AND FollowUpDate != '' AND FollowUpDate IS NOT NULL"
                + " AND date(FollowUpDate) >= date(?) AND date(FollowUpDate) <= date(?)"
                + " ORDER BY FollowUpDate";

            // let sql = "SELECT date(FollowUpDate) as 'FollowUpDate' FROM " + tableName
            //     + " WHERE DoctorId = ? AND FollowUpDate != '' AND FollowUpDate IS NOT NULL";



            // + " AND CONVERT('datetime',FollowUpDate) >= CONVERT('datetime',?) AND CONVERT('datetime',FollowUpDate) <= CONVERT('datetime',?)";
            tx.executeSql(sql, [doctorId, startDate, endDate], function (tx, resultSet) {
                if (fnSuccessCallback) {
                    fnSuccessCallback(dbUtils.fnBuildObjectArrayFromRows(resultSet.rows, props, encryptedProps));
                }
            });

        }, function (error) {
            console.log('Transaction ERROR: ' + error.message);
            if (fnFailureCallBack) {
                fnFailureCallBack(error);
            }
        }, function () {

        });
    }
}

export {

    fnGetPatientFollowUps,

}