<template>
  <f7-page name="select-doctor">
    <f7-navbar>
      <f7-nav-title>Select doctor profile</f7-nav-title>
      <!-- <f7-nav-right>
        <f7-button href="/home/1/" style="color:white" icon-material="home"></f7-button>
      </f7-nav-right>-->
    </f7-navbar>

    <div class="h-100 overflow-y-auto">
      <f7-list no-hairlines>
        <f7-list-item
          v-for="DoctorProfile in DoctorProfiles"
          :key="DoctorProfile.PatientId"
          link="#"
          @click="fnSelectDoctorProfile(DoctorProfile)"
        >
          <div slot="media" class="avatar-circle">
            <span class="initials">{{
              fnGetFirstLetter(DoctorProfile.Name)
            }}</span>
          </div>
          <div class="w-100 f-row f-vertical-center f-space-between">
            <div>
              <div>{{ DoctorProfile.Name }}</div>
              <!-- <div class="font-xsmall text-secondary">
               
              </div>
              <div class="font-xsmall text-secondary">
                {{ PatientProfile.Phone }}
              </div> -->
            </div>
          </div>
        </f7-list-item>
      </f7-list>
    </div>
  </f7-page>
</template>
<style>
</style>
<script>
import { mapState } from "vuex";
const doctorStore = require("../store/doctorStore.js");
const errorHandler = require("../shared/error-handler.js");

export default {
  name: "select-doctor",
  components: {},
  computed: {
    // ...mapState(["__CurrentPatient"]),
  },
  mounted: function () {
    this.fnFetchDoctors();
  },
  data: function () {
    return {
      DoctorProfiles: [],
    };
  },
  props: {
    patientPhone: String,
  },
  methods: {
    fnSelectDoctorProfile(doctorProfile) {
      window.location.href = window.location.href + doctorProfile.UniqueName;
      // this.$f7router.navigate("/" + doctorProfile.UniqueName);
    },
    fnFetchDoctors() {
      let that = this;
      let dialog = f7App.dialog.preloader("Fetching doctors");
      doctorStore.fnGetDoctorsForPatient(
        this.patientPhone,
        function (doctors) {
          that.DoctorProfiles = doctors;
          dialog.close();
        },
        function (err) {
          dialog.close();
          errorHandler.fnRaiseError();
        }
      );
    },
    fnGetFirstLetter(text) {
      if (text && text.length > 0) {
        return text[0];
      }
      return "";
    },
  },
};
</script>